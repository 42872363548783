<script setup>
import { CfCheckbox } from "@/components/Form"
import { ref, watch } from "vue"
const Props = defineProps({ field: Object })
const Emits = defineEmits(['answer'])
const answer = ref([])

watch(answer, (newVal) => {
  console.log(newVal);
  Emits('answer', answer.value)
})
</script>

<template>
  <section class="cell checkbox-choice">
    <cf-checkbox
      v-for="option in Props.field.options"
      :key="option.value"
      v-model="answer"
      :value="option.value"
    >{{ option.text }}</cf-checkbox>
  </section>
</template>
