import moment from 'moment'
import { getCookieByKey, getIP, fetchApi, sha256 } from './index'

export function genEventUid() {
  const uid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {var r = Math.random()*16|0,v=c=='x'?r:r&0x3|0x8;return v.toString(16);})
  return `cf-capi-${uid}`
}

const STATIC_CAPI_ID = genEventUid()
export async function pixelEvent(event_name, data={}, options={}, deduplication=false) {
  const FLOAT_CAPI_ID = genEventUid()
  if (process.env.VUE_APP_GTAP_SEND_PERMISSION !== '1') return console.log('no permission to send pixel event')
  const { user, custom_data={} } = data
  const { ip } = await getIP()
  let user_data = {
    fbp: getCookieByKey('_fbp'),
    client_ip_address: ip,
    client_user_agent: navigator.userAgent
  }
  if (user) user_data = { ...user_data, em: [sha256(user.user_email)], ph: [sha256(user.user_phone)] }
  const Event = {
    data: [{
      event_id: `${deduplication ? STATIC_CAPI_ID : FLOAT_CAPI_ID}-${event_name}`,
      event_name,
      event_time: moment().unix(),
      action_source: 'website',
      ...options,
      user_data,
      custom_data
    }]
  }

  const res = await fetchApi('/api/capi/', { data: JSON.stringify(Event) })
}


