<script setup>
import { ref, markRaw, onMounted, reactive, watch, inject, computed } from 'vue'
import { useProductStore } from "../../../store/product";



const ProductStore = useProductStore()
const $Props = defineProps({ type: String, couponGiveawayItems: Array, customProdcutIds: Array })
const $Emits = defineEmits(['choose'])

const Dict = {
  PRODUCT: ProductStore.productAndSet.PRODUCT,
  SET: ProductStore.productAndSet.SET,
  ALL: ProductStore.productList,
  CUSTOM: $Props.customProdcutIds?.map(id => ({ ...ProductStore.productDict[id] })).filter(p => p.product_id)
}
console.log(Dict[$Props.type]);
const columnType = computed(() => {
  if ($Props.type === 'GIVEAWAY') return 'giveaway'
  return 'product'
})

const handleClick = item => {
  console.log(columnType.value);
  console.log(`${columnType.value}_uid`);
  $Emits('choose',{ [`${columnType.value}_uid`]: item[`${columnType.value}_uid`], product_quantity: 1 })
}
</script>

<template>
  <li
    v-for="product in Dict[type]"
    :key="`coupon-product-${product[columnType+'_id']}`"
    @click="handleClick(product)"
    :class="{ choosed: couponGiveawayItems.map(e => e[`${columnType}_uid`]).includes(product[`${columnType}_uid`]) }"
  >
    <div class="product-image-wrapper">
      <img :src="`img/${columnType}/${product.product_uid}/avatar.webp`" :alt="product[`${columnType}_name_zh`]" />
    </div>
    <div class="product-info">
      <h2>{{ product[`${columnType}_name_zh`] }}</h2>
    </div>
  </li>
</template>
