<script setup>
import { ref, provide, reactive, computed, watch, inject, onMounted } from 'vue'
import Icon from "@/components/Icon"

const TYPE_ICON = {
  success: 'check2-circle',
  warning: 'exclamation-octagon-fill',
  error: 'x-circle-fill',
  info: 'info-circle-fill',
}

const messageConfig = reactive({
  text: '',
  show: false,
  type: null,
  timeout: 3000,
})

const classes = computed(() => ({
  'k-message': true,
  'show': messageConfig.show,
}))
const spanClasses = computed(() => ({
  [`msg--${messageConfig.type}`]: messageConfig.type
}))

watch(messageConfig, (newValue, oldValue) => {
  console.log(newValue.show);
  setTimeout(() => {
    messageConfig.show = false
    console.log(messageConfig);
  }, messageConfig.timeout);
})

const $message = inject('$message')
onMounted(() => {
  Object.keys(TYPE_ICON).forEach(type => {
    $message[type] = (text, timeout) => {
      messageConfig.show = true
      messageConfig.type = type
      messageConfig.text = text
      messageConfig.timeout = timeout || 3000
    }
  })
})
</script>

<template>
  <div :class="classes">
    <transition name="msg-show">
      <span :class="spanClasses">
        <icon :icon="TYPE_ICON[messageConfig.type]" />
        <p>{{ messageConfig.text }}</p>
      </span>
    </transition>
  </div>
</template>
