<script setup>
import { CfSelect } from "@/components/Form"
import { ref, watch } from "vue"
const Props = defineProps({ field: Object })
const Emits = defineEmits(['answer'])
const answer = ref('')

watch(answer, (newVal) => {
  console.log(newVal);
  Emits('answer', answer.value)
})
</script>

<template>
  <section class="cell select">
    <cf-select :options="Props.field.options" v-model="answer"></cf-select>
  </section>
</template>
