import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyAAiep6IlxQWUifeXyVzxZ5ry0K4T7LB6k",
  authDomain: "chris-farrell.firebaseapp.com",
  projectId: "chris-farrell",
  storageBucket: "chris-farrell.appspot.com",
  messagingSenderId: "1071726976519",
  appId: "1:1071726976519:web:7994c56ecc98d9e5f254a2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
