<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { usePostStore } from '../store/post'
const PostStore = usePostStore()

onMounted(() => {
  if (!PostStore.postList.length) PostStore.queryPost()
})

const router = useRouter()
</script>

<template>
  <div class="view-post-explore">
    <header></header>
    <section v-if="PostStore.postList.length">
      <ul>
        <li v-for="post in PostStore.postList" :key="post.post_id">
          <img :src="post.post_bannerPath" :alt="post.post_banner" />
          <div>
            <h1>{{ post.post_title }}</h1>
            <p>{{ post.post_create_at.format('YYYY-MM-DD') }}</p>
            <span v-html="post.post_summary.replace(/\n/g, '<br>').replace(/\s/g, '&nbsp&nbsp')"></span>
            <button @click="router.push(`/blog/${post.post_title_en}`)">繼續閱讀</button>
          </div>
        </li>
      </ul>
    </section>
  </div>
</template>
