export const genProductEmailHTML = ({ product_name_zh, product_name_en, product_img, product_price, product_quantity }) => `
<tr><td height='15' colspan='6' style='border-top: 1px solid #ddd;'></td></tr>
<tr>
	<td width='80' style='padding:0 0 0 2px;text-align: center;'>
    <img style='vertical-align:top;height:80px' src='${product_img}' alt='${product_name_zh}'>
  </td>
	<td width='15'></td>
	<td width='150' style='line-height:20px;text-align:left'>${product_name_en}<br>${product_name_zh}</td>
	<td width='94' style='font-size:12px;line-height:1.5'>NT$${product_price}</td>
	<td width='74' style='font-size:12px'>${product_quantity}</td>
	<td width='94' style='font-size:12px;font-weight:700;line-height:1.5;text-align: right;'>
    <div>NT$<span>${product_price * product_quantity}</span></div>
  </td>
</tr>
<tr><td height='15' colspan='6'></td></tr>
`

export const genGiveawayEmailHTML = ({coupon_name, product_name_zh, product_name_en, product_images}) => `
  <tr><td height='15' colspan='6' style='border-top: 1px solid #ddd;'></td></tr>
  <tr>
		<td width='80' style='padding:0 0 0 2px;text-align: center;'>
      <img style='vertical-align:top;height:80px' src='https://www.chris-farrell.com.tw/img/product/${product_images[0]}' alt='${product_name_zh}'>
    </td>
		<td width='15'></td>
		<td width='305' style='line-height:20px;text-align:left'>${product_name_en} ${product_name_zh}<br>
      <div style="color: #777;">優惠券：${coupon_name}</div>
    </td>
		<td width='100' style='font-size:12px;font-weight:700;line-height:1.5;text-align: center;'>
      <div>1</div>
    </td>
	</tr>
	<tr><td height='15' colspan='6'></td></tr>
`

export const genProductTableVariables = (cartItems) => {
  const sectionHTML = cartItems.reduce((acc, item) => {
    return acc + genProductEmailHTML({...item})
  }, "")
  return sectionHTML
}

export const genGiveawayTableVariables = (giveawayItems) => {
  if (giveawayItems.length) {
    const renderGiveaways = giveawayItems.reduce((acc, item) => {
      return acc + genGiveawayEmailHTML({...item})
    }, '')

    return `
      <tr><td colspan='3' height='50'></td></tr>  
      <tr>
        <td width='50'></td>
        <td width='500'>
          <table cellpadding='0' cellspacing='0' width='500' style='width:100%;margin:0;padding:0;text-align:left;font-size:13px;color:#101010;border-bottom:1px solid #ddd'> 
            <tbody>
              <tr><td width='500' colspan='6' style='text-align:left;font-size:20px'>贈品</td></tr>
              <tr><td width='500' height='20' colspan='6'></td></tr>
              <tr>
                <th width='400' height='34' colspan='3' style='width:245px;border-top: 4px solid #f1f1f1;border-bottom: 1px solid#f1f1f1;'>贈品品項</th>
                <th width='100' height='34' style='border-top: 4px solid #f1f1f1;text-align: center;'>數量</th>
              </tr>
              ${renderGiveaways}
            </tbody>
          </table>
        </td>
        <td width='50'></td>
      </tr> 
    `
  }

  return ''
  
}

export const checkEmailTemplateSchema = () => {

}
