<script setup>
import { CfInput } from '@/components/Form'
import { inject, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useLoginStore } from '../store/login'

const email = ref('')
const LoginStore = useLoginStore()
const $message = inject('$message')
const router = useRouter()

const handleSubmit = async () => {
  const emailRegex = /\w+@[\w\W]+\.\w+/g
  if (!email.value) return $message.warning('請輸入欲重設密碼的信箱 Email')
  if (!emailRegex.test(email.value)) return $message.warning('Email 格式有誤')
  const result = await LoginStore.changePassword(email.value)
  if (result.state) {
    $message.success('已將重設密碼連結發送至信箱')
    router.push('/login')
  } else {
    if (result.error.code === 'auth/user-not-found') $message.error('此信箱並未在 Chris Farrell Taiwan 註冊過')
  }
}
</script>

<template>
  <div class="view-reset-password-container">
    <h1>忘記密碼？</h1>
    <span>新朋友若忘記已在 Chris Farrell Taiwan 註冊過的密碼，請在下方輸入您當時註冊的帳號，我們會將密碼設定連結寄到此 Email 信箱</span>

    <cf-input placeholder="電子郵件信箱" type="email" v-model="email"></cf-input>
    <button @click="handleSubmit">送出</button>
  </div>
</template>
