<script>
export default { name: 'Help' }
</script>
<script setup>
import { ref, inject, onMounted, watch } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { CfSelect } from '@/components/Form'

const ScreenState = inject('ScreenProvider')
const Route = useRoute()
const Router = useRouter()
const RouteChildren = Route.matched[0].children

onMounted(() => window.scrollTo({ top: 0 }))
watch(Route, () => window.scrollTo({ top: 0 }))

const currentRoute = ref('/help/cs-landing')
const optionsExpread = ref(false)
const routerSelector = [...RouteChildren.map(route => ({ text: route.name_zh, value: `/help/${route.path}` }))]
watch(currentRoute, route => {
  if (ScreenState.mode === "MOBILE") Router.push(route)
})
</script>

<template>
  <div class="view-help">
    <aside>
      <h1>協助</h1>
      <ul>
        <li v-for="route in RouteChildren" :key="route.path">
          <router-link :to="`/help/${route.path}`">{{ route.name_zh }}</router-link>
        </li>
        <li>
          <router-link to="/search-order" target="_blank">訂單查詢</router-link>
        </li>
        <li>
          <router-link to="/product-instruction" target="_blank">產品說明</router-link>
        </li>
      </ul>
    </aside>
    <main>
      <router-view />
      <footer>
        
      </footer>
    </main>
  </div>
</template>
