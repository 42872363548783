<script setup>
import { ref, computed, inject, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useProductStore } from "../store/product";
import Icon from '@/components/Icon'
import SKIN_TYPE_CONFIG from '@/utils/pageConfig/skinType.json'
import { usePaymentStore } from "../store/payment";
import { useLoginStore } from "../store/login";
import { VGtag } from '@/directives'

const $message = inject('$message')
const Route = useRoute()
const Router = useRouter()
const SkinTypeConfig = ref('')
const FocusSkinType = ref('')
const requireAssets = src => require(`@/assets/img/skinType/${FocusSkinType.value}/${src}`)

const ScreenState = inject('ScreenProvider')
onMounted(() => window.scrollTo(0, 0))
  FocusSkinType.value = Route.params.type
  SkinTypeConfig.value = SKIN_TYPE_CONFIG[FocusSkinType.value]
  console.log(SkinTypeConfig.value);
const scrollToExplore = () => window.scrollTo({ top: ScreenState.mode === "COMPUTER" ? window.innerHeight - 100 : window.innerHeight + 20, behavior: 'smooth' })

watch(Route, newVal => {
  FocusSkinType.value = newVal.params.type
  SkinTypeConfig.value = SKIN_TYPE_CONFIG[FocusSkinType.value]
  window.scrollTo(0, 0)
})

const LoginStore = useLoginStore()
const ProductStore = useProductStore()
const PaymentStore = usePaymentStore()
const addIntoCart = product_id => {
  const productIndex = PaymentStore.cartItems.map(e => e.product_id).indexOf(product_id)
  if (productIndex === -1 || (PaymentStore.cartItems[productIndex] && PaymentStore.cartItems[productIndex].product_price !== 'MEMBER')) {
    PaymentStore.addProductIntoCart({
      product_id: product_id,
      product_quantity: 1,
      product_price: 'MEMBER'
    })
  }
  else PaymentStore.editProductQuantity(product_id, 1)
  $message.success('已加入購物車')
}

const focusEva = ref(0)
const chooseEva = index => focusEva.value = index
</script>

<template>
  <div class="view-skin-type" v-if="SkinTypeConfig">
    <header class="hero">
      <div class="content-wrapper">
        <div class="container">
          <h1>{{ SkinTypeConfig.title }}</h1>
          <h2>{{ SkinTypeConfig.title_en }}</h2>
          <button @click="scrollToExplore">
            <p>如何保養</p>
            <span>
              <icon icon="arrow-down-short" />
            </span>
          </button>
        </div>
      </div>
      <div class="img-wrapper">
        <img :src="requireAssets(SkinTypeConfig.banner)" :alt="`Chris Farrell ${SkinTypeConfig.title}`">
      </div>
    </header>
    <section class="usual-skin">
      <h1>常見{{ SkinTypeConfig.title }}</h1>
      <div class="container">
        <div class="left-c">
          <div class="skin">
            <span><img :src="requireAssets(SkinTypeConfig.usualSkin[0].img)" :alt="`Chris Farrell 常見${SkinTypeConfig.title}`"></span>
            <p>{{ SkinTypeConfig.usualSkin[0].name }}</p>
          </div>
          <div class="skin">
            <span><img :src="requireAssets(SkinTypeConfig.usualSkin[1].img)" :alt="`Chris Farrell 常見${SkinTypeConfig.title}`"></span>
            <p>{{ SkinTypeConfig.usualSkin[1].name }}</p>
          </div>
        </div>
        <img class="model" :src="require(`@/assets/img/skinType/model.webp`)">
        <div class="right-c">
          <div class="skin">
            <span><img :src="requireAssets(SkinTypeConfig.usualSkin[2].img)" :alt="`Chris Farrell 常見${SkinTypeConfig.title}`"></span>
            <p>{{ SkinTypeConfig.usualSkin[2].name }}</p>
          </div>
          <div class="skin" v-if="SkinTypeConfig.usualSkin[3]">
            <span><img :src="requireAssets(SkinTypeConfig.usualSkin[3].img)" :alt="`Chris Farrell 常見${SkinTypeConfig.title}`"></span>
            <p>{{ SkinTypeConfig.usualSkin[3].name }}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="product-sujestment">
      <h1>推薦保養順序</h1>
      <ul v-if="Object.keys(ProductStore.productDict).length">
        <li
          class="product"
          v-for="product, index in SkinTypeConfig.sujectProducts" :key="`skin_type_product_${product.productId}`"
          v-gtag:click.view_product="{
            location_url: Route.fullPath,
            product_uid: ProductStore.productDict[product.productId].product_uid
          }"
          @click="Router.push(`/product/${ProductStore.productDict[product.productId].product_name_en.replace(/\s/g, '-').toLowerCase()}`)"
        >
          <h3>{{ index+1 }}.{{ product.step }}</h3>
          <router-link
            class="img-wrapper"
            :to="`/product/${ProductStore.productDict[product.productId].product_name_en.replace(/\s/g, '-').toLowerCase()}`"
          >
            <img
              :src="`/img/product/${ProductStore.productDict[product.productId].product_uid}/avatar.webp`"
              :alt="`${ProductStore.productDict[product.productId].product_name_en} ${ProductStore.productDict[product.productId].product_name_zh}`"
            >
            <div class="mask">查看詳情</div>
            <span 
              v-if="product.must !== null" 
              :class="{ badge: true, must: product.must }"
            >
              {{ product.must ? '必須' : '非必須' }}
            </span>
          </router-link>
          <p class="product-subtitle" v-if="ProductStore.productDict[product.productId].product_sub_title">
            {{ ProductStore.productDict[product.productId].product_sub_title }}
          </p>
          <h2>
            {{ ProductStore.productDict[product.productId].product_name_zh }} {{ ProductStore.productDict[product.productId].product_size[0] }}ml
          </h2>
          <span class="product-price">
            <p :class="{ 'original-price': true }">
              NT${{ ProductStore.productDict[product.productId].product_price.ORIGIN }}
            </p>
            <p>NT${{ ProductStore.productDict[product.productId].product_price.MEMBER }}</p>
          </span>
          <button
            :disabled="ProductStore.productDict[product.productId].product_is_launched !== '1'"
            v-gtag:click.add_to_cart="{
              currency: 'TWD',
                value: parseInt(ProductStore.productDict[product.productId].product_price.MEMBER),
                items: [{
                  item_id: ProductStore.productDict[product.productId].product_capi_uid,
                  item_name: ProductStore.productDict[product.productId].product_name_zh,
                  item_category: ProductStore.productDict[product.productId].product_type.toLowerCase(),
                  price: parseInt(ProductStore.productDict[product.productId].product_price.MEMBER),
                  quantity: 1
                }]
            }" 
            @click.stop="ProductStore.productDict[product.productId].product_is_launched === '1' ? addIntoCart(ProductStore.productDict[product.productId].product_id) : void 8"
          >{{ ProductStore.productDict[product.productId].product_is_launched === '1' ? '加入購物車' : '商品缺貨中' }}</button>
        </li>
      </ul>
    </section>
    <section class="problems">
      <header>
        <h1 v-if="!SkinTypeConfig.problemTitle">{{ SkinTypeConfig.title }}延伸問題</h1>
        <h1 v-else>{{ SkinTypeConfig.problemTitle }}</h1>
      </header>
      <main v-if="SkinTypeConfig.problems">
        <div class="problem" v-for="problem, index in SkinTypeConfig.problems" :key="`skin_type_problem_${index}`">
          <div class="img-wrapper">
            <img :src="requireAssets(problem.img)" :alt="`Chris Farrell ${SkinTypeConfig.title}`">
          </div>
          <div class="content-wrapper">
            <h2>{{ problem.title }}</h2>
            <span>{{ problem.content }}</span>
          </div>
        </div>
      </main>
      <main v-else class="evaluate">
        <div class="img-wrapper">
          <img :src="requireAssets(SkinTypeConfig.evaluate.items[focusEva].img)" :alt="`Chris Farrell ${SkinTypeConfig.title}`">
        </div>
        <div class="content-wrapper">
          <h2>{{ SkinTypeConfig.evaluate.title }}</h2>
          <ul>
            <li
              v-for="item, index in SkinTypeConfig.evaluate.items"
              :class="{ active: index === focusEva, canHover: item.img }"
              :key="`skin_type_eva_${index}`"
              @mouseenter="item.img ? chooseEva(index) : void 8"
            >
              {{index+1}}.{{item.name}}
            </li>
            <li>...</li>
          </ul>
          <span>{{ SkinTypeConfig.evaluate.content }}</span>
        </div>
      </main>
    </section>
    <section class="fix">
      <header>
        <h1 v-if="!SkinTypeConfig.fix.title">如何解決{{ SkinTypeConfig.title }}問題</h1>
        <h1 v-else>{{ SkinTypeConfig.fix.title }}</h1>
        <p>{{ SkinTypeConfig.fix.subtitle }}</p>
        <div class="img-wrapper">
          <img :src="requireAssets(SkinTypeConfig.fix.banner)" :alt="`Chris Farrell ${SkinTypeConfig.title}`">
        </div>
      </header>
      <main>
        <div class="step" v-for="step, index in SkinTypeConfig.fix.steps" :key="`key_type_step_${index}`">
          <h2>{{ index+1 }}</h2>
          <div>
            <h3>{{ step.title }}</h3>
            <span>{{ step.content }}</span>
          </div>
        </div>
      </main>
    </section>
    <section class="product-sujestment">
      <h1>推薦保養順序</h1>
      <ul v-if="Object.keys(ProductStore.productDict).length">
        <li
          class="product"
          v-for="product, index in SkinTypeConfig.sujectProducts"
          :key="`skin_type_product_${product.productId}`"
          v-gtag:click.view_product="{
            location_url: Route.fullPath,
            product_uid: ProductStore.productDict[product.productId].product_uid
          }"
          @click="Router.push(`/product/${ProductStore.productDict[product.productId].product_name_en.replace(/\s/g, '-').toLowerCase()}`)"
        >
          <h3>{{ index+1 }}.{{ product.step }}</h3>
          <router-link
            class="img-wrapper"
            :to="`/product/${ProductStore.productDict[product.productId].product_name_en.replace(/\s/g, '-').toLowerCase()}`"
          >
            <img
              :src="`/img/product/${ProductStore.productDict[product.productId].product_uid}/avatar.webp`"
              :alt="`${ProductStore.productDict[product.productId].product_name_en} ${ProductStore.productDict[product.productId].product_name_zh}`"
            >
            <div class="mask">查看詳情</div>
            <span 
              v-if="product.must !== null" 
              :class="{ badge: true, must: product.must }"
            >
              {{ product.must ? '必須' : '非必須' }}
            </span>
          </router-link>
          <p class="product-subtitle" v-if="ProductStore.productDict[product.productId].product_sub_title">
            {{ ProductStore.productDict[product.productId].product_sub_title }}
          </p>
          <h2>
            {{ ProductStore.productDict[product.productId].product_name_zh }} {{ ProductStore.productDict[product.productId].product_size[0] }}ml
          </h2>
            <span>
              <p :class="{ 'original-price': true }">
                NT${{ ProductStore.productDict[product.productId].product_price.ORIGIN }}
              </p>
              <p>NT${{ ProductStore.productDict[product.productId].product_price.MEMBER }}</p>
            </span>
            <button
              v-gtag:click.add_to_cart="{
                currency: 'TWD',
                value: parseInt(ProductStore.productDict[product.productId].product_price.MEMBER),
                items: [{
                  item_id: ProductStore.productDict[product.productId].product_capi_uid,
                  item_name: ProductStore.productDict[product.productId].product_name_zh,
                  item_category: ProductStore.productDict[product.productId].product_type.toLowerCase(),
                  price: parseInt(ProductStore.productDict[product.productId].product_price.MEMBER),
                  quantity: 1
                }]
              }" 
              @click.stop="addIntoCart(ProductStore.productDict[product.productId].product_id)"
            >加入購物車</button>
        </li>
      </ul>
    </section>
    <section class="guarntee">
      <h1>四大保證</h1>
      <ul>
        <li>
          <div><img :src="require('@/assets/img/we_care_1.svg')" alt="Chris Farrell 德國空運進口"></div><p>德國空運進口</p></li>
        <li>
          <div><img :src="require('@/assets/img/we_care_2.svg')" alt="Chris Farrell 不含色素＆礦物油"></div><p>不含色素＆礦物油</p></li>
        <li>
          <div><img :src="require('@/assets/img/guarntee.svg')" alt="Chris Farrell 試用品退費"></div><p>試用品退費</p></li>
        <li>
          <div><img :src="require('@/assets/img/gmp_iso_icon.svg')" alt="Chris Farrell 歐盟GMPISO"></div><p>歐盟GMPISO</p></li>
      </ul>
    </section>
  </div>
</template>
