<script setup>
import { CfInput } from "@/components/Form"
import { ref, watch } from "vue"
const Props = defineProps({ field: Object })
const Emits = defineEmits(['answer'])
const answer = ref('')

watch(answer, (newVal) => {
  console.log(newVal);
  Emits('answer', answer.value)
})
</script>

<template>
  <section class="cell one-line-input">
    <cf-input v-model="answer" hideLabel></cf-input>
  </section>
</template>
