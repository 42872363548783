<script setup>
import { computed } from 'vue'

const Props = defineProps({ modelValue: String, value: String, label: String, disabled: Boolean })
const Emits = defineEmits(['input', 'update:modelValue'])
const classes = computed(() => ({
  'cf-radio': true,
  'disabled': Props.disabled,
  'active': Props.value === Props.modelValue
}))
</script>

<template>
  <label :class="classes">
    <input
      type="radio"
      :checked="Props.value===Props.modelValue"
      :disabled="Props.disabled"
      :value="Props.value"
      @input="Emits('update:modelValue', $event.target.value)"
    >
    <span>{{ Props.label }}</span>
  </label>
</template>
