<script setup>
import TattooCareImg from '@/assets/img/TattooCareBG.webp'
import FibraGelImg from '@/assets/img/FibraGel2.png'
import { getElementPositionY } from '@/utils'
import { VScrollFadein } from '@/directives'
import CareFlowItem from './CareFlowItem.vue'
import Icon from '../../components/Icon.vue'
import { onBeforeUnmount, onMounted, ref, inject } from 'vue'
import { useRouter } from 'vue-router'
import 'swiper/components/navigation/navigation.scss'
import { Swiper, SwiperSlide } from 'swiper/vue'
const router = useRouter()
const ScreenState = inject('ScreenProvider')

const mainSectionREF = ref(null)
const showScrollNav = ref(false)
const scrollToMainSection = () => window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })
const scrollToSection = section => window.scrollTo({ top: section.yStart, behavior: 'smooth' })

//scroll navbar focus
const navBarSections = ref([])
const navBarFocus = ref('')
const VScrollAt = {
  mounted(el, binding) {
    const positionY = getElementPositionY(el)
    navBarSections.value.push({ name: binding.value, yStart: positionY, yEnd: positionY + el.scrollHeight })
  }
}

let func = () => {
  const windowScrollY = document.documentElement.scrollTop
  const windowHeight = window.innerHeight
  // console.log(document.documentElement.scrollTop);

  //control whether scroll navbar show
  if (windowScrollY >= window.innerHeight * 0.75) showScrollNav.value = true
  else showScrollNav.value = false

  //control focus navbar section
  for (const section of navBarSections.value) {
    if (windowScrollY >= section.yStart - windowHeight / 2 && windowScrollY < section.yEnd - windowHeight / 2) {
      navBarFocus.value = section.name
      break
    } else continue
  }
}
onMounted(() => {
  window.scrollTo(0, 0)
  document.addEventListener('scroll', func)
})
onBeforeUnmount(() => document.removeEventListener('scroll', func))


//Tattoo Care Flow Focus
const TATTOO_FILE_ITEM = [
  { file: 'TattooCareFlow1', desc: '用肥皂將雙手洗淨 (包含指甲縫等細節處)' },
  { file: 'TattooCareFlow2', desc: '使用溫度剛好的水搭配成份單純的肥皂、沐浴乳 ( 不含香精、酒精 ) 在起泡後輕輕搓洗傷口' },
  { file: 'TattooCareFlow3', desc: '洗淨後使用紙巾 ( 不掉屑、一次性使用 ) 輕按至傷口全乾 ' },
  { file: 'TattooCareFlow4', desc: '完成清潔後，讓傷口通風靜置10-15分鐘，接著就可以塗上薄薄一層的刺青保養品，千萬不要塗太厚，這樣才能保持傷口通風' }
]
const focusTattooFlowItem = ref(TATTOO_FILE_ITEM[0])
const focusOnItem = item => focusTattooFlowItem.value = item
</script>

<template>
  <div class="view-tattoo-care">
    <transition name="fade" :duration="300">
      <aside class="scroll-nav" v-if="showScrollNav">
        <ul>
          <li
            v-for="section in navBarSections"
            :key="section.name"
            :class="{ focus: navBarFocus === section.name }"
            @click="scrollToSection(section)"
          >{{ section.name }}</li>
        </ul>
      </aside>
    </transition>
    <header class="hero-banner">
      <section class="banner" :style="{ backgroundImage: `url(${TattooCareImg})` }"></section>
      <div class="content">
        <h3>用50年的德國經驗</h3>
        <h2>守護你為自己留下的記憶</h2>
        <button @click="scrollToMainSection">
          <p>刺青保養</p>
          <span>
            <i class="arrow-down"></i>
          </span>
        </button>
      </div>
    </header>

    <main ref="mainSectionREF">
      <section class="main-section tattoo-theory" v-scroll-at="'刺青原理'" v-scroll-fadein>
        <h1>
          “刺青簡單來說就是
          <br />利用刺青針將顏料帶到真皮層後所形成的圖像”
        </h1>
        <div class="wrapper">
          <span>人類的皮膚主要分成三層，分別是表皮層、真皮層以及皮下組織，其中表皮層能夠形成保護層使我們不會受到外在環境的細菌入侵；真皮層包含毛囊、汗腺以及血管，也是刺青顏料主要停留的地方；最底層就是皮下組織也可以稱做皮下脂肪，主要用來作為皮膚以及肌肉、骨骼的連結。</span>

          <div v-if="ScreenState.mode === 'COMPUTER'" class="images">
            <div>
              <img :src="require('@/assets/img/TattooTheory1.png')" />
              <p>刺青中</p>
            </div>
            <div>
              <img :src="require('@/assets/img/TattooTheory2.png')" />
              <p>恢復期</p>
            </div>
            <div>
              <img :src="require('@/assets/img/TattooTheory3.png')" />
              <p>復原後</p>
            </div>
          </div>
        </div>

        <div v-if="ScreenState.mode === 'MOBILE'" class="image-swiper">
          <swiper :slides-per-view="1" :loop="true">
            <swiper-slide
              v-for="imgSrc, index in ['刺青中', '恢復期', '復原後']"
              :key="`tattoo-theory-${index}`"
            >
              <div>
                <img :src="require(`@/assets/img/TattooTheory${index + 1}.png`)" :alt="imgSrc" />
                <p>{{ imgSrc }}</p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </section>

      <section class="main-section tattoo-care" v-scroll-at="'術後保養'" v-scroll-fadein>
        <h1>刺青術後保養方式</h1>
        <span>
          刺青後的照顧可以說是刺青最重要的環節之一，刺青後的傷口沒有照顧好，除了傷口恢復期拉長、傷口感染以及刺青顏色受到影響外，最嚴重的情形是有可能導致喪命的，因此下面就要來告訴大家如何做好刺青傷口的照顧。
          <br />
          <br />刺青傷口的恢復期因人而異，最主要受到刺青大小的影響，刺青的面積越大通常需要越久的時間復原傷口，反之則短時間就有可能使傷口復原。
        </span>

        <div class="care-flow">
          <h3>刺青傷口清潔 (每日三次)</h3>
          <div class="flow-wrapper">
            <care-flow-item
              v-for="item in TATTOO_FILE_ITEM"
              :key="item.file"
              :file="item.file"
              :focus="item.file === focusTattooFlowItem.file"
              @click="focusOnItem(item)"
            />
            <i class="arrow-right"></i>
            <div class="mask"></div>
          </div>
          <span>{{ focusTattooFlowItem.desc }}</span>
        </div>
      </section>

      <section class="main-section tattoo-product" v-scroll-at="'保養品推薦'">
        <div class="dark-mask"></div>

        <div class="fibra-gel-wrapper">
          <div class="title">
            <h1>
              Fibra
              <br />Gel
            </h1>
            <h2>舒敏復原膠</h2>
            <button @click="router.push('/product/舒敏復原膠')">了解產品</button>
            <img :src="FibraGelImg" alt />
          </div>
          <div class="character">
            <div class="about">
              <h3>關於舒敏復原膠</h3>
              <ul>
                <li>50年的研發經驗，產品由德國研發及製造空運來台</li>
                <li>增強皮膚保溼以及防禦功能，舒緩肌膚的不適感</li>
                <li>形成一層保護層，提高皮膚耐受性</li>
                <li>降低表淺傷口水份散失、改善皮膚的屏障功能</li>
                <li>無添加任何香精、色素、防腐劑，成分皆為人體仿生，致敏性及刺激性極低</li>
              </ul>
            </div>
            <div class="why">
              <h3>為什麼刺青保養要選擇舒敏復原膠？</h3>
              <ul>
                <li>無添加香精、色素等複雜化工成分，能夠避免脆弱的刺青傷口受到刺激、發炎</li>
                <li>Pharmaceuticals 期刊研究證實「泛醇」在刺青後的再上皮化 (re-epithelialization) 程度較凡士林高 50%-200%</li>
                <li>Pharmaceuticals 期刊研究證實「泛醇」用於刺青保養能夠將刺青傷口的水分散失程度快速降低，使傷口恢復快速、刺青色澤鮮明</li>
                <li>US Davis Biomolecules 研究證實「黏多醣」能有效幫助傷口進行修復，還能夠促進細胞外基質分泌、減緩發炎以及抑制免疫細胞遷徙。除此之外，也能在傷口修復的過程中控制濕度，讓更多的養分流入皮膚、更多的廢物流出皮膚</li>
                <li>在恢復過程中幫助你舒緩傷口不適、加快修復以及為刺青顏色增添光澤</li>
              </ul>

              <button
                v-if="ScreenState.mode === 'MOBILE'"
                @click="router.push('/product/舒敏復原膠')"
              >了解產品</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
