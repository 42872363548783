import database from './database'

export async function sendError(error_system, error_api, error_request, error_response) {
  await database.table("error").set({
    error_api,
    error_system,
    error_request,
    error_response,
    error_create_at: database.FieldValue.serverTimestamp()
  });
}
