<script setup>
import { timestampParse } from '@/utils'
import { inject, onMounted, watch, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useOrderStore } from '../../store/order'
import { useProductStore } from '../../store/product'
import LOGISTIC_STATE from '../../utils/ecpayLogisticsState.json'
import { CfInput, CfCheckbox } from '@/components/Form'
import CfConfirm from '@/components/Message/Confirm'
import CfSkeleton from '@/components/Skeleton'
import Icon from '@/components/Icon'
import moment from 'moment'
import LoadingSpinner from '@/assets/img/loading_spinner.svg'
import { fetchApi } from '@/utils'
import { getZipCode } from '@/utils/zip'


const STATUS_DICT = {
  COMPLETE: {
    text: '已完成',
    color: '#3A5A26',
  },
  PREPARE_PRODUCT: {
    text: '備貨中',
    color: ''
  },
  DELIVERY: {
    text: '運送中',
    color: ''
  },
  ARRIVE: {
    text: '已送達',
    color: '#1E4D5F'
  },
  RETURN_APPLY: {
    text: '退貨審合中',
    color: '#1E4D5F'
  },
  RETURN: {
    text: '已退貨',
    color: ''
  },
  NO_PICK_UP: {
    text: '未取貨，已取消',
    color: ''
  },
}

const $message = inject('$message')
const OrderStore = useOrderStore()
const ProductStore = useProductStore()
const Route = useRoute()
const Router = useRouter()

onMounted(async () => {
  if (!OrderStore.orderList.length) await OrderStore.queryOrders()
  if (Route.query.order_number) {
    changeFocus(Route.query.order_number)
    Router.push('/member/order')
  }
})

const showModal = ref(false)
const toThreeDomin = () => window.open(OrderStore.focusOrder.order_payment_url)

async function changeFocus(id) {
  showReturnSection.value = false
  OrderStore.changeFocusOrder(id)
  showModal.value = true
  // console.log(OrderStore.focusOrder.order_logistics_id);
  // await OrderStore.checkOrderLogisticState(OrderStore.focusOrder.orders_id, {
  //   MerchantTradeNo: OrderStore.focusOrder.order_logistics_id,
  //   TimeStamp: moment().unix()
  // })
}

function getLogisticsState(order) {
  if (order.order_logistics_state) {
    return LOGISTIC_STATE[order.order_logistics_subtype][order.order_logistics_state.LogisticsStatus]
  }
  return null
}

const LOGISTICS_ABNORMAL_STATE = ['2070', '2072', '2074', '2076', '3020', '3025', '3019', '3023', '40000']
function renderLogisticsState(order) {
  const logisticsSubtype = order.order_logistics_subtype
  
  if (order.order_logistics_state.length) {
    if (order.order_logistics_state.filter(s => LOGISTICS_ABNORMAL_STATE.includes(s.code)).length) {
      if (order.order_pay_type === 'CREDIT') {
        if (order.order_payment_status === 'RETURN') return '未取貨，已取消訂單(已退款)'
        return '未取貨，已取消訂單(退款處理中)'
      }
      return '未取貨，已取消訂單'
    }
    const stateCode = order.order_logistics_state[order.order_logistics_state.length - 1].code
    return LOGISTIC_STATE[logisticsSubtype][stateCode]
  }
  return LOGISTIC_STATE[logisticsSubtype]['300']
}
//  order.order_payment_status === 'HANDLING' || order.order_pay_type === 'COD'
function checkOrderStatus(order) {
  const { order_pay_type, order_payment_status, order_port_type, order_logistics_subtype, order_logistics_state, order_type } = order
  if (order_type === 'RETURN_APPLY') return { state: '退貨審核中', color: '#df7575' }
  if (order_type === 'RETURN') return { state: '退貨中', color: '#df7575' }
  if (order_type === 'REFUNDED') return { state: '已退款', color: '#878787' }
  if (order_pay_type === 'COD') return { state: '貨到付款', color: '#f0b259' }
  if (order_pay_type === 'CREDIT' && order_payment_status==='HANDLING') return { state: '待付款', color: '#df7575' }
  if (order_pay_type === 'CREDIT' && !['HANDLING', 'SUCCESS'].includes(order_payment_status)) {
    return { state: '付款失敗', color: '#df7575' }
  }
  return {
    state: renderLogisticsState(order), success: ['20000', '3003', '2067', '3022', '3018', '2073'].includes(order_logistics_state[order_logistics_state.length - 1]?.code),
    color: order_logistics_state.filter(s => LOGISTICS_ABNORMAL_STATE.includes(s.code)).length ? '#878787' : null
  }
}

function searchPackageWithNote(order) {
  switch (order.order_logistics_subtype) {
    case 'TCAT':
      window.open(`https://www.t-cat.com.tw/Inquire/Trace.aspx?no=${order.order_logistics_info.BookingNote}`, '_blank').focus()
      break;
    case 'UNIMARTC2C':
      window.open('https://eservice.7-11.com.tw/e-tracking/search.aspx', '_blank').focus()
      break;
    case 'FAMIC2C':
      window.open('https://ecfme.fme.com.tw/fmedcfpwebv2/index.aspx', '_blank').focus()
    default:
      break;
  }
}

const showReturnSection = ref(false)
watch(showReturnSection, value => {
  if (value) {
    returnInfo.SenderName = OrderStore.focusOrder.order_logistics_info.ReceiverName
    returnInfo.SenderCellPhone = "0"+OrderStore.focusOrder.order_logistics_info.ReceiverCellPhone
  } else {
    for (let key in returnInfo) {
      if (key === 'SenderZipCode') {
        returnInfo[key] = null
        continue
      }
      returnInfo[key] = ""
    }
  }
})

const refs = reactive({})
function refElement(ref, key) {
  refs[key] = ref
}
function callInvalid(key, msg) {
  $message.error(msg)
  if (key) refs[key].callInvalid()
}

const showConfirmReturnSubmit = ref(false)
const returnComment = ref('')
const returnAddresIsSame = ref(false)
const returnInfo = reactive({
  SenderName: "",
  SenderCellPhone: "",
  SenderAddress: "",
  returnComment: "",
  SenderZipCode: null
})
watch(returnAddresIsSame, value => {
  if (value) returnInfo.SenderAddress = OrderStore.focusOrder.order_logistics_info.ReceiverAddress
  else returnInfo.SenderAddress = ""
})
function toggleShowReturn(bool) {
  showReturnSection.value = bool
  if (!bool) returnComment.value = ''
}
async function returnApplySubmit() {
  if (Object.values(returnInfo).every(e => e || e===null)) {
    // const zipcode = await fetchApi(`http://zip5.5432.tw/zip5json.py?adrs=${returnInfo.SenderAddress}`)
    const zipInfo = await getZipCode(returnInfo.SenderAddress)
    if (!zipInfo.zipcode) return $message.warning('找不到與此地址相符的郵遞區號，請確認地址是否正確')
    returnInfo.SenderZipCode = !zipInfo.zipcode
    console.log(returnInfo);
    const res = await OrderStore.returnApply(OrderStore.focusOrder.orders_id, returnInfo)
    if (res.status === 200) {
      $message.success('我們已收到您的退貨申請')
      showReturnSection.value = false
    }
  } else {
    if (!returnInfo.SenderName) return callInvalid('SenderName', '請填寫退貨人姓名')
    if (!returnInfo.SenderCellPhone) return callInvalid('SenderPhone', '請填寫退貨人手機號碼')
    if (!returnInfo.SenderAddress) return callInvalid('SenderAddress', '請填寫退貨地址')
    if (!returnInfo.returnComment) return callInvalid('returnComment', '請填寫收退貨原因')
  }
  
}

function logisticsBookingNote(order) {
  switch (order.order_logistics_subtype) {
    case "TCAT": return order.order_logistics_info.BookingNote
    case "FAMIC2C": return order.order_logistics_info.CVSPaymentNo
    case "UNIMARTC2C": return order.order_logistics_info.CVSPaymentNo + order.order_logistics_info.CVSValidationNo
  }
}
</script>

<template>
  <div class="view-member-order">
    <div v-if="!OrderStore.orderList.length" class="loading">
      <div><img :src="LoadingSpinner"  />載入訂單中</div>
    </div>
    <p v-if="OrderStore.loading" class="loading-hint">正在下載最新訂單資料狀態...</p>
    <main v-if="OrderStore.orderList.length">
      <div
        class="order"
        @click="changeFocus(order.orders_id)"
        v-for="order in OrderStore.orderList" :key="order.orders_id"
      >
        <div class="content">
          <h2>{{ order.orders_id }}</h2>
          <p>下訂日期：{{ timestampParse(order.order_create_at).format('YYYY/MM/DD') }}</p>
          <span>${{ order.order_amount }}</span>
        </div>
        <!-- <div
          v-if="order.order_payment_status === 'HANDLING' || order.order_pay_type === 'COD'"
          class="status" :style="{ backgroundColor: order.order_pay_type === 'COD' ? '#f0b259' :'#df7575' }"
        >
          {{ order.order_pay_type === 'COD' ? '貨到付款' : '待付款' }}
        </div>
        <div
          v-else-if="order.order_payment_status === 'SUCCESS'"
          :class="{
            status: true ,
            success: ['20000', '3003', '2067', '3022', '3018', '2073'].includes(order.order_logistics_state[order.order_logistics_state.length - 1]?.code) ? true : null
          }"
        >
          {{ renderLogisticsState(order) }}
        </div>
        <div v-else  class="status" :style="{ backgroundColor: '#df7575' }">付款失敗</div> -->
        <div
          :class="{ status: true, success: checkOrderStatus(order).success }"
          :style="{ backgroundColor: checkOrderStatus(order).color || null }"
        >
          {{ checkOrderStatus(order).state }}
        </div>
      </div>
    </main>

    <h3 v-if="!OrderStore.loading && !OrderStore.orderList.length">目前無任何訂單</h3>

    <section class="order-modal" v-if="OrderStore.focusOrder && showModal" @click.self="() => showModal = false">
      <div class="order-modal-wrapper">
        <div class="order-modal-scroll">
          <header>
            <div class="order-header">
              <h1>訂單編號 {{ OrderStore.focusOrder.orders_id }}</h1>

              <div class="order-action">
                <button
                  v-if="(OrderStore.focusOrder.order_payment_status === 'SUCCESS'
                    && OrderStore.focusOrder.order_type !== 'RETURN_APPLY') || (OrderStore.focusOrder.order_pay_type === 'COD' && OrderStore.focusOrder.order_logistics_state.filter(s => s.code === '20000').length)"
                  @click="toggleShowReturn(true)"
                >退貨申請</button>
                <button v-if="OrderStore.focusOrder.order_logistics_type !== 'INTERNATIONAL'" @click="searchPackageWithNote(OrderStore.focusOrder)">包裹查詢</button>
              </div>
              <span class="close" @click="() => showModal = false"><icon icon="x" /></span>
            </div>

            <Transition name="order-return-collapse">
              <div v-if="showReturnSection" class="order-return">
                <h3>退貨申請</h3>
                <div class="input-wrapper">
                  <cf-input
                    label="退貨人姓名" placeholder="退貨人姓名" v-model="returnInfo.SenderName"
                    :ref="ref => refElement(ref, 'SenderName')" required
                  ></cf-input>
                  <cf-input
                    label="退貨人手機號碼" placeholder="退貨人手機號碼" v-model="returnInfo.SenderCellPhone"
                    :ref="ref => refElement(ref, 'SenderPhone')" required
                  ></cf-input>
                  <div class="block">
                    <cf-input
                      label="退貨地址" placeholder="退貨地址" v-model="returnInfo.SenderAddress"
                      extra="待客服人員審核退貨資訊後，會由黑貓宅急便派車前往此退貨地址收貨"
                      :ref="ref => refElement(ref, 'SenderAddress')" required
                    ></cf-input>
                  </div>
                  <cf-input
                    label="退貨原因" placeholder="退貨原因 (必填)" customClass="block"
                    v-model="returnInfo.returnComment" :ref="ref => refElement(ref, 'returnComment')" required 
                  ></cf-input>
                </div>
                
                <div class="button-group">
                  <div v-if="OrderStore.focusOrder.order_logistics_type === 'HOME'">
                    <cf-checkbox v-model="returnAddresIsSame" :checked="false">退貨地址同宅配取貨地址</cf-checkbox>
                  </div>
                  <button @click="toggleShowReturn(false)">取消</button>
                  <button @click="showConfirmReturnSubmit = true">送出</button>
                </div>
                <cf-confirm
                  content="確定要送出退貨申請嗎？"
                  v-model="showConfirmReturnSubmit"
                  @cancel="showConfirmReturnSubmit = false"
                  @confirm="returnApplySubmit"
                />
              </div>
            </Transition>

            <div class="order-status">
              <h3>訂單資訊</h3>
              <ul class="state">
                <li>訂單編號</li>
                <li>{{ OrderStore.focusOrder.orders_id }}</li>
                <li>下訂時間</li>
                <li>{{ timestampParse(OrderStore.focusOrder.order_create_at).format('YYYY/MM/DD') }}</li>
                <li>付款方式</li>
                <li>{{ OrderStore.focusOrder.order_pay_type === "COD" ? "貨到付款" : "信用卡" }}</li>
                <li>訂單狀態</li>
                <li v-if="OrderStore.focusOrder.order_pay_type === 'CREDIT' && OrderStore.focusOrder.order_payment_status === 'HANDLING'">
                  待3D驗證
                  <button @click="toThreeDomin">點我進行 3D 認證</button>
                </li>
                <li v-else>{{renderLogisticsState(OrderStore.focusOrder)}}</li>
                <li>訂單總額</li>
                <li>${{ OrderStore.focusOrder.order_amount }}</li>
                <li>物流單號</li>
                <li>{{ logisticsBookingNote(OrderStore.focusOrder) || '處理中' }}</li>
              </ul>

              <div class="logistics-info">
                <h3>出貨資訊</h3>
                <ul>
                  <li>收件人姓名</li>
                  <li>{{ OrderStore.focusOrder.order_logistics_info.ReceiverName }}</li>
                  <li>收件人電話</li>
                  <li>{{ OrderStore.focusOrder.order_logistics_info.ReceiverCellPhone }}</li>
                  <template v-if="['HOME','INTERNATIONAL'].includes(OrderStore.focusOrder.order_logistics_type)">
                    <li>收件地址</li>
                    <li>{{ OrderStore.focusOrder.order_logistics_info.ReceiverAddress }}</li>
                  </template>
                  <template v-else>
                    <li>取貨門市</li>
                    <li>
                      {{ OrderStore.focusOrder.order_logistics_subtype === 'FAMIC2C' ? 'Family Mart 全家' : '7-11 統一超商' }}
                      {{ OrderStore.focusOrder.order_logistics_cvs_info.CVSStoreName }}
                    </li>
                    <li>門市地址</li>
                    <li>{{ OrderStore.focusOrder.order_logistics_cvs_info.CVSAddress }}</li>
                  </template>
                </ul>
              </div>
            </div>
          </header>
          <main>
            <h3>訂單內容</h3>
            <ul v-if="ProductStore.productList.length">
              <li v-for="item in OrderStore.focusOrder.order_products" :key="`${OrderStore.focusOrder.orders_id}-${item.product_id}`">
                <div class="img-wrapper">
                  <img
                    :src="ProductStore.productDict[item.product_id]?.product_type === 'SET'
                      ? `/img/product/${ProductStore.productDict[item.product_id].product_images[0]}`
                      : `/img/product/${ProductStore.productDict[item.product_id].product_uid}/avatar.webp`
                    "
                    :alt="ProductStore.productDict[item.product_id].product_images[0]"
                  >
                </div>
                <div class="content">
                  <h4>{{ ProductStore.productDict[item.product_id].product_name_en }}</h4>
                  <h3>{{ ProductStore.productDict[item.product_id].product_name_zh }}</h3>
                  <p>數量: {{ item.product_quantity }}</p>
                  <p>${{ item.product_price * item.product_quantity }}</p>
                </div>
              </li>
            </ul>
            <template v-if="OrderStore.focusOrder.order_coupon_used.filter(e => ['GIVEAWAY', 'BONUS'].includes(e.coupon_type)).length">
              <h3>贈品</h3>
              <ul class="order_coupon_items">
                <template
                  v-for="coupon, index in OrderStore.focusOrder.order_coupon_used" :key="`order_${OrderStore.focusOrder.order_id}_coupon_${index}`"
                >
                  <li v-if="['GIVEAWAY', 'BONUS'].includes(coupon.coupon_type)">
                    <header>
                      <span class="coupon-tag">{{ coupon.coupon_name }}</span>
                    </header>
                    <section>
                      <div v-for="item in coupon.coupon_items" :key="`coupon_item_${item.product_uid}`">
                        <div class="img-wrapper">
                          <img :src="ProductStore.productUidDict[item.product_uid]?.product_type === 'SET'
                      ? `/img/product/${ProductStore.productUidDict[item.product_uid].product_images[0]}`
                      : `/img/product/${item.product_uid}/avatar.webp`" alt="">
                        </div>
                        
                        <span>
                          <h4>{{ ProductStore.productUidDict[item.product_uid]?.product_name_en }}</h4>
                          <h4>{{ ProductStore.productUidDict[item.product_uid]?.product_name_zh }}</h4>
                          <h4>&#x00D7; {{ item.product_quantity }}</h4>
                        </span>
                      </div>
                    </section>
                  </li>
                </template>
              </ul>
            </template>
          </main>
        </div>
      </div>
    </section>
  </div>
</template>
