<script setup>
import { ref, defineAsyncComponent, computed } from 'vue'
import { CfInput, CfSelect, CfCheckbox } from "@/components/Form"
import { ONE_LINE_INPUT, RADIO_CHOICE, CHECKBOX_CHOICE, SELECT, LINEAR_SCALE, TEXTAREA, } from './cell'
import FIELD_TYPE from "../utils/fieldType.json"

const Emits = defineEmits(['answer'])
const Props = defineProps({ field: Object })
const cell = computed(() => ({
  ONE_LINE_INPUT,
  RADIO_CHOICE,
  CHECKBOX_CHOICE,
  SELECT,
  LINEAR_SCALE,
  TEXTAREA
}))

function handleAnswer(answer) {
  Emits('answer', { key: Props.field.key, answer })
}
</script>

<template>
  <div class="questionnaire-question-cell">
    <h3><b v-if="Props.field.required">*</b>{{ Props.field.label }}</h3>
    <span class="line"></span>
    <span class="questionnire-desc" v-if="Props.field.desc">{{ Props.field.desc }}</span>
    <component :is="cell[FIELD_TYPE[Props.field.type].answerComponent]" :field="Props.field" @answer="handleAnswer" />
  </div>
</template>
