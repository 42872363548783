import { defineStore } from "pinia";
import { timestampParse } from '@/utils'
import database from '@/utils/database'

function layoutFormat(type, content) {
  switch (type) {
    case "ARRAY": return content.split('|')
    case "JSON": return JSON.parse(content)
    case "BOOLEAN": return Boolean(content === '1')
    case "TEXT":
    default:
      return content
  }
}

export const useLayoutStore = defineStore({
  id: 'Layout',
  state: () => ({
    layoutList: [],
  }),
  getters: {
    layoutDict() {
      if (this.layoutList.length) {
        return this.layoutList.reduce((acc, l) => ({
          ...acc, [l.layout_name]: { ...l }
        }), {})
      }
      return {}
    } 
  },
  actions: {
    async queryLayout() {
      const res = await database.table('layout').get()
      console.log(res);
      this.layoutList = res.data.map(l => ({
        ...l,
        layout_content: layoutFormat(l.layout_data_type, l.layout_content)
      }))
      return res.data
    }
  }
})
