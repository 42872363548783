import { defineStore } from "pinia";
import { timestampParse } from '@/utils'
import database from '@/utils/database'

export const useQuestionnaireStore = defineStore({
  id: 'Questionnaire',
  state: () => ({
    questionnaire: null,
  }),
  getters: {
  },
  actions: {
    async queryQuestionnaire(url) {
      const res = await database.table('questionnaire_template').where('questionnaire_template_route', '=', url).get()
      console.log(res.data[0]);
      this.questionnaire = {
        ...res.data[0],
        questionnaire_template_fields: JSON.parse(res.data[0].questionnaire_template_fields)
      }
    }
  }
})
