<script setup>
import FaqCollapse from './components/FaqCollapse'
import FaqJson from './FAQ.json'

const FaqJsonWithCategory = FaqJson.reduce((acc, c) => {
  const category = c.category
  if (acc[category]) acc[category].push(c)
  else acc[category] = [c]
  return acc
}, {})
</script>

<template>
  <div class="view-help-faq-container">
    <header class="subpage-header">
      <h1>常見問題</h1>
    </header>

    <section v-for="category in Object.keys(FaqJsonWithCategory)" :key="`fqa-${category}`">
      <h2 class="category">{{ category }}</h2>
      <div class="questions">
        <faq-collapse
          v-for="faq in FaqJsonWithCategory[category]" 
          :key="`fqa-${category}-${faq.q}}`"
          :question="faq.q"
          :answer="faq.a"
          :imgs="faq.imgs"
          :imgs_m="faq.imgs_m"
        />
      </div>
    </section>
  </div>
</template>
