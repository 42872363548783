<script setup>
import { CfInput } from '@/components/Form'
import { ref } from 'vue'
const $props = defineProps({ birthday: Object })

const birthdayMonthREF = ref()
const birthdayDayREF = ref()
const handleBirthdayInput = (e) => {
  if (e.target.value.length < 2) return
  switch (e.target.dataset.cid) {
    case 'y':
      if (e.target.value.length === 4) birthdayMonthREF.value.focus()
      return;
    case 'm':
      if (e.target.value.length === 2) birthdayDayREF.value.focus()
    default:
      break;
  }
}
</script>

<template>
  <cf-input v-if="$props.birthday" customClass="birthday" label="生日" fixLabel>
    <input
      data-cid="y"
      @input="handleBirthdayInput"
      v-model="$props.birthday.y" type="text" placeholder="西元年" maxlength="4">/
    <input
      data-cid="m"
      ref="birthdayMonthREF"
      @input="handleBirthdayInput"
      v-model="$props.birthday.m" type="text" placeholder="月份" maxlength="2">/
    <input
      ref="birthdayDayREF"
      @input="handleBirthdayInput"
      v-model="$props.birthday.d" type="text" placeholder="日期" maxlength="2">
      
    <!-- <template #extra>
      <span class="birthday-extra">每年生日將贈送生日禮</span>
    </template> -->
  </cf-input>
</template>
