<script setup>
import { ref, computed, useSlots } from "@vue/runtime-core"

const Props = defineProps({
  modelValue: String,
  placeholder: {
    default: '',
    type: String
  },
  type: {
    default: 'text',
    type: String
  },
  label: String,
  addonBefore: String,
  addonAfter: String,
  maxlength: [String, Number],
  extra: String,
  required: Boolean,
  customClass: String,
  readonly: Boolean,
  hideLabel: Boolean,
  fixLabel: Boolean,
  rule: String,
  invalidExtra: String
})

const Slots = useSlots()
const Emits = defineEmits(['update:modelValue', 'focus', 'blur'])

const refInvalid = ref(false)
function handleInvalid() {
  hasFirstFocus.value = true
  refInvalid.value = true
}

defineExpose({ callInvalid: handleInvalid })

const handleChange = (e) => {
  refInvalid.value = false
  Emits('update:modelValue', e.target.value)
}

const hasFirstFocus = ref(false)
const isFocusing = ref(false)
function handleFocus() {
  Emits('focus')
  isFocusing.value = true
}
function handleBlur() {
  if (!hasFirstFocus.value) hasFirstFocus.value = true
  Emits('blur')
  isFocusing.value = false
}
const classes = computed(() => ({
  'cf-input': true,
  'focusing': isFocusing.value,
  'invalid': !!(Props.required && !Props.modelValue && hasFirstFocus.value),
  [Props.customClass]: !!Props.customClass
}))

function verifyRule() {
  if (!Props.rule) return true
  const rule = Props.rule.split(':')
  switch (rule[0]) {
    case 'email':
      return /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(Props.modelValue)
    case 'phone':
      return /^0?9\d{8}$/.test(Props.modelValue)
    case 'id':
      return /^\d{9}$/.test(Props.modelValue)
    case 'password':
      return /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/.test(Props.modelValue)
    case 'length':
      return Props.modelValue.length >= rule[1]
    default:
      return rule[0].test(Props.modelValue)
  }
}

const computedInvalidExtra = computed(() => {
  if (!!Props.required && !Props.modelValue && hasFirstFocus.value) {
    return `請輸入${Props.label}` 
  }
  if (refInvalid.value) {
    return Props.invalidExtra
  }
  return !verifyRule() && hasFirstFocus.value ? Props.invalidExtra : ''
})
</script>

<template>
  <span :class="{'cf-input-wrapper': true, [Props.customClass]: !!Props.customClass, 'invalid': computedInvalidExtra }">
    <label :class="classes">
      <slot name="addonBefore">
        <span v-if="Props.addonBefore">{{ Props.addonBefore }}</span>
      </slot>
      <div
        :class="{ 'placeholder': true, 'labelize': Props.fixLabel || Props.modelValue || Props.addonBefore || Slots.addonBefore }"
        v-if="!hideLabel"
      >
        <p>{{ Props.placeholder || Props.label }}</p>
      </div>
      <input
        :value="Props.modelValue"
        @input="handleChange"
        @focus="handleFocus"
        @blur="handleBlur"
        :type="Props.type"
        :readonly="Props.readonly"
        :maxlength="Props.maxlength || null"
        :placeholder="!hideLabel && !fixLabel ? null : Props.placeholder || '輸入' "
      />
      <slot name="addonAfter">
        <span v-if="Props.addonAfter">{{ Props.addonAfter }}</span>
      </slot>
      <slot></slot>
    </label>
    <span class="cf-input-extra" v-if="Props.extra || Slots.extra || computedInvalidExtra">
      <slot name="extra">{{ computedInvalidExtra || Props.extra }}</slot>
    </span>
  </span>
</template>
