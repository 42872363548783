<script setup>
import { inject } from "@vue/runtime-core"
import { useRouter } from "vue-router"
import { useLoginStore } from "../../store/login"
import { usePaymentStore } from "../../store/payment"
import { useProductStore } from "../../store/product"

const Router = useRouter()
const $message = inject('$message')
const ProductStore = useProductStore()
const LoginStore = useLoginStore()
const PaymentStore = usePaymentStore()

const addIntoCart = product_id => {
  const productIndex = PaymentStore.cartItems.map(e => e.product_id).indexOf(product_id)
  if (productIndex === -1 || (PaymentStore.cartItems[productIndex] && PaymentStore.cartItems[productIndex].product_price !== 'MEMBER')) {
    PaymentStore.addProductIntoCart({
      product_id: product_id,
      product_quantity: 1,
      product_price: LoginStore.currentUser ? 'MEMBER' : 'ORIGIN'
    })
  }
  else PaymentStore.editProductQuantity(product_id, 1)
  $message.success('已加入購物車')
}
</script>

<template>
  <div class="view-member-wishlist view-product-explore">
    <header><h2>願望清單</h2></header>
    <section id="wishlist" v-if="LoginStore.currentUser.user_wishlist.length">
      <ul v-if="LoginStore.currentUser">
        <li
          v-for="productId in LoginStore.currentUser.user_wishlist"
          :key="`wishlist_prd_${productId}`"
          @click.stop="Router.push(`/product/${ProductStore.productDict[productId].product_name_en.replace(/\s/g, '-').toLowerCase()}`)"
        >
          <div class="product-image-wrapper">
            <img :src="`../img/product/${ProductStore.productDict[productId].product_uid}/avatar.webp`" :alt="ProductStore.productDict[productId].product_name_zh" />
          </div>
          <div class="product-info">
            <h2>{{ ProductStore.productDict[productId].product_name_zh }}</h2>
            <p>NT${{ ProductStore.productDict[productId].product_price.ORIGIN }}</p>
            <button @click.stop="addIntoCart(ProductStore.productDict[productId].product_id)">加入購物車</button>
          </div>
        </li>
      </ul>
    </section>
    <section class="no-wish-list" v-else><h4>目前無加入任何產品</h4></section>
  </div>
</template>
