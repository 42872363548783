<script setup>
import { computed } from "@vue/runtime-core"
const props = defineProps({
  focus: Boolean,
  file: String
})

const classes = computed(() => ({
  'flow-item': true,
  'focus': props.focus
}))
</script>

<template>
  <div :class="classes">
    <img v-if="focus" :src="require(`@/assets/img/TattooCareFlow/${props.file}_w.svg`)" />
    <img v-else :src="require(`@/assets/img/TattooCareFlow/${props.file}.svg`)" />
  </div>
</template>
