<script setup>
import { ref } from 'vue'
import { CfInput, CfSelect, CfCheckbox } from "@/components/Form"
import { inject, onMounted } from "@vue/runtime-core"
import QuestionCell from './components/QuestionCell'
import FIELD_TYPE from "./utils/fieldType.json"
import { useQuestionnaireStore } from '@/store/questionnaire'
import { useRoute } from 'vue-router'

const FIELDS = [
  { key: 'test-1001', label: '問卷題一：單行文字', type: "ONE_LINE_INPUT", required: true },
  { key: 'test-1002', label: '問卷題二：下拉選單', type: "SELECT", options: [
    { value: 'ss11-1001', text: '選項一' },
    { value: 'ss11-1002', text: '選項二' },
    { value: 'ss11-1003', text: '選項三' },
  ], required: true },
  { key: 'test-1003', label: '問卷提三：單選題', type: "MULTIPLE_CHOICE", options: [
    { value: 'rr11-1001', text: '選匡一' },
    { value: 'rr11-1002', text: '選匡二' },
    { value: 'rr11-1003', text: '選匡三' },
  ], required: true },
  { key: 'test-1004', label: '問卷提四：線性刻度', type: "LINEAR_SCALE",
    scales: ['非常不滿意', '有點不滿意', '普通', '有點滿意', '非常滿意'], required: true },
  { key: 'test-1005', label: '問卷題五：多行文字', type: "TEXTAREA_INPUT", required: true },
  { key: 'test-1006', label: '問卷題六：多選題', type: "MULTIPLE_ANSWER", options: [
    { value: 'cc11-1001', text: '選項一' },
    { value: 'cc11-1002', text: '選項二' },
    { value: 'cc11-1003', text: '選項三' },
  ], required: true },
]

const route = useRoute()
const QuestionnaireStore = useQuestionnaireStore()
const $message = inject('$message')
const questionFields = ref([])

onMounted(async () => {
  const { customUrl } = route.params
  if (customUrl) await QuestionnaireStore.queryQuestionnaire(customUrl)
  questionFields.value = JSON.parse(JSON.stringify(FIELDS))
})

const updateField = ({ key, answer }) => {
  console.log(key, answer);
  const index = questionFields.value.map(f => f.key).indexOf(key)
  questionFields.value.splice(index, 1, { ...questionFields.value[index], answer })
}

const handleSubmit = () => {
  if (!questionFields.value.every(q => !q.required || !!q.answer)) {
    $message.warning('有必填的題目尚未填寫，請確認')
    return
  }
  const answerField = questionFields.value.map(({ answer, type, key }) => ({
    key, type, answer
  }))
  console.log(answerField)
}
</script>

<template>
  <div class="view-questionnaire-container">
    <section class="question-wrapper">
      <header v-if="QuestionnaireStore.questionnaire">
        <h1>{{ QuestionnaireStore.questionnaire.questionnaire_template_name }}</h1>
        <blockquote v-if="QuestionnaireStore.questionnaire.questionnaire_template_desc">
          {{ QuestionnaireStore.questionnaire.questionnaire_template_desc }}
        </blockquote>
      </header>

      <main v-if="QuestionnaireStore.questionnaire">
        <QuestionCell v-for="field in QuestionnaireStore.questionnaire.questionnaire_template_fields" :key="field.key" :field="{ ...field, desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam in distinctio consequatur aliquid perferendis? Ipsum harum voluptatum architecto assumenda officia necessitatibus mollitia porro iste repellendus? Doloribus eligendi ipsa ducimus optio?' }" @answer="updateField" />
      </main>

      <footer>
        <button @click="handleSubmit">提交</button>
      </footer>
    </section>
  </div>
</template>
