import { defineStore } from "pinia";
import { timestampParse, fetchApi } from '@/utils'
import database from '@/utils/database'

export const useGroupStore = defineStore({
  id: 'Group',
  state: () => ({
    group_project_list: [],
  }),
  getters: {
    groupProjectDict() {
      if (this.group_project_list.length) {
        return this.group_project_list.reduce((acc, p) => ({ ...acc, [p.group_project_id]: { ...p } }), {})
      }
      return {}
    }
  },
  actions: {
    async queryGroupProjects() {
      const res = await database.table('group_project').get()
      if (res.state === 200) {
        this.group_project_list = res.data.map(p => ({
          ...p,
          group_project_products: JSON.parse(p.group_project_products)
        }))
      }
    },
    async createGroupOrder(payload) {
      const res = await database.table('group_order').set({
        ...payload,
        group_order_create_at: database.FieldValue.serverTimestamp()
      })
      return res
    }
  }
})
