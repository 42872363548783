<script setup>
import { onBeforeMount, onBeforeUnmount, onMounted, provide, ref, reactive, watch } from 'vue';
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import CfFooter from "./components/CfFooter.vue"
import NavBar from "./components/NavBar.vue"
import Message from "./components/Message/Message.vue"
import Icon from "./components/Icon.vue"
import { useLoginStore } from './store/login';
import { useOrderStore } from './store/order';
import { useProductStore } from './store/product';
import { useCouponStore } from './store/coupon';
import { usePaymentStore } from './store/payment';
import { useEmailStore } from './store/email'
import { useLayoutStore } from './store/layout'
import { fetchApi, setCookie, getCookieByKey, matchCookieByKey, removeCookieByKey, removeFromLocal } from '@/utils'
import { usePostStore } from './store/post';
import { useRoute, useRouter } from 'vue-router';
import { gaEvent } from '@/utils/event'
import moment from 'moment'

const screenState = reactive({
  mode: 'MOBILE'
})
const layoutState = reactive({
  showNavTopNews: true
})

provide('ScreenProvider', screenState)
provide('LayoutSetting', layoutState)
provide('$message', reactive({}))
let func = () => {
  if (window.innerWidth < 900) screenState.mode = 'MOBILE'
  else screenState.mode = 'COMPUTER'
}
const router = useRouter()
const route = useRoute()
const LoginStore = useLoginStore()
const LayoutStore = useLayoutStore()
const OrderStore = useOrderStore()
const ProductStore = useProductStore()
const PaymentStore = usePaymentStore()
const PostStore = usePostStore()
const CouponStore = useCouponStore()
const EmailStore = useEmailStore()

const showJumpingCountdown = ref(false)
const unSubscribePaymentStore = ref(null)
const intervalInstance = ref(null)
const countdown = ref(4)

function startJumpingCountdown() {
  showJumpingCountdown.value = true
  intervalInstance.value = setInterval(() => {
    if (countdown.value <= 0) {
      router.push('/payment')
      clearInterval(intervalInstance.value)
    } else {
      countdown.value--
    }
  }, 1000);
}


const toProducts = () => {
  showAniversarayPopup.value = false
  router.push('/product')
}
const cancelPopup = () => showHomePagePopup.value = false

watch(() => route.path, (newRoute) => {
  if (newRoute === '/payment') handleCancel()
})
function handleToPayment() {
  router.push('/payment')
}
function handleCancel() {
  showJumpingCountdown.value = false
  unSubscribePaymentStore.value() //取消訂閱
  clearInterval(intervalInstance.value) //清除倒數計時
}
onBeforeMount(async() => {
  CouponStore.queryCoupon()
  await PaymentStore.reloadSessionOrder()
  unSubscribePaymentStore.value = PaymentStore.$subscribe((mutation, state) => {
    console.log(mutation);
    if (state.cartItems.length) startJumpingCountdown()
  })
  EmailStore.queryEmailTemplates()
  const auth = getAuth()
  const loginCookie = getCookieByKey('cf:login')
  if (!loginCookie) {
    removeFromLocal('couponUsed')
    return
  } else {
    const now = new moment().unix()
    if (now - loginCookie > 259200) {
      removeCookieByKey('cf:login')
      removeFromLocal('couponUsed')
      return
    }
  }
  onAuthStateChanged(auth, async user => {
    if (user) {
      setCookie('cf:login', new moment().unix())
      gaEvent('login', {
        method: 'email',
      })
      const result = await LoginStore.saveUser(user)
      if (result.state === 205) router.push(`/register?login=fb&cus=${result.user_id}`)
      
      OrderStore.queryOrders()
      CouponStore.queryCoupon()
      LoginStore.queryVouchers()
    }
  })

  const res = await fetchApi('/api/line/autoLogin/');
  console.log('line login state', res);
  if (res.state === 200 && res.user_line_id) {
    gaEvent('login', {
      method: 'line',
    })
    await LoginStore.queryUserWithLineId(res.user_line_id)
    setCookie('cf:login', new moment().unix())
    if (!LoginStore.currentUser.user_name) {
      const { to } = route.query
      if (route.path === '/payment') router.push(`/register?redirect=payment_${to}&login=line&cus=${LoginStore.currentUser.key_id}`)
      else router.push(`/register?login=line&cus=${LoginStore.currentUser.key_id}`)
    }
    OrderStore.queryOrders()
    CouponStore.queryCoupon()
    LoginStore.queryVouchers()
  }
})


async function clearPaymentSession() {
  const res = await fetchApi('/api/session/', { unset: ['cartItems', 'couponUsed', 'shippingInfo', 'CVSInfo'] })
}

const showHomePagePopup = ref(false)
const homePagePopupImg = ref('')
const homepageMobilePopupImg = ref('')
onMounted(async () => {
  func()
  window.addEventListener('resize', func)
  //*Accuhit 如果是從 Nix Line OA 進站將 user_token 就存入 cookie
  const queryFromLocation = new URLSearchParams(window.location.search)
  if (queryFromLocation.has('domain') && queryFromLocation.has('user_token')) {
    setCookie('cf:accu_user_token', queryFromLocation.get('user_token'))
  }
  TPDirect.setupSDK(process.env.VUE_APP_TAPPAY_APP_ID, process.env.VUE_APP_TAPPAY_APP_KEY, 'Production')

  await LayoutStore.queryLayout()
  if (LayoutStore.layoutDict.HOME_POPUP_SWITCH.layout_content) {
    const { COOKIE_ID, pc_img, mb_img } = LayoutStore.layoutDict.HOME_POPUP_INFO?.layout_content
    const popupCookie = getCookieByKey(`cf:[popup]${COOKIE_ID}`)
    homePagePopupImg.value = pc_img
    homepageMobilePopupImg.value = mb_img || pc_img
    if (!popupCookie) {
      setCookie(`cf:[popup]${COOKIE_ID}`, new moment().unix())
      showHomePagePopup.value = true
    }
  }

  CouponStore.queryCoupon()
  ProductStore.queryProduct()
  PostStore.queryPost()
  // clearPaymentSession()
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', func)
})
</script>

<template>
  <Message />
  <nav-bar></nav-bar>
  <div :class="{ 'jump-to-payment': true, 'show': showJumpingCountdown }">
    <p><icon icon="cart-fill" />{{ countdown+1 }} 秒後將自動跳轉至購物車</p>
    <div class="button-group">
      <button @click="handleToPayment">直接前往</button>
      <button @click="handleCancel">取消</button>
    </div>
  </div>
  <router-view />
  <div v-if="showHomePagePopup" class="home-page-popup">
    <div class="wrapper" @click="toProducts">
      <img :src="`/img${screenState.mode === 'COMPUTER' ? homePagePopupImg : homepageMobilePopupImg}`">
      <button @click.stop="cancelPopup"><icon icon="x-lg" /></button>
    </div>
  </div>
  <cf-footer></cf-footer>
</template>

