<script setup>
import { onMounted } from "vue";
import { useCommentStore } from "../../store/comment";
import { useProductStore } from "../../store/product";
import Icon from "@/components/Icon"

const ProductStore = useProductStore()
const CommentStore = useCommentStore()
onMounted(() => {
  if (!CommentStore.commentList.length) CommentStore.queryComment()
})

function avg_stars(comments) {
  return Math.round(comments.reduce((acc, c) => acc + parseInt(c.comment_stars), 0) / comments.length*100) / 100
}
</script>

<template>
  <div class="view-comments">
    <header>
      <div><h1>產品使用心得 REVIEW</h1></div>
    </header>
    <main>
      <ul class="product-list">
        <li v-for="product in ProductStore.productAndSet.PRODUCT" :key="`comments-product-${product.product_uid}`">
          <router-link :to="`/comments/product/${product.product_name_en.split(' ').join('-').toLowerCase()}`">
            <div class="img-wrapper">
              <img :src="`/img/product/${product.product_uid}/avatar.webp`" :alt="`Chris Farrell 克莉斯瑞｜${product.product_name_zh}`">
            </div>
            <div class="content">
              <p class="subtitle">{{ product.product_sub_title }}</p>
              <h2>{{ product.product_name_zh }}</h2>
              <div class="score" v-if="CommentStore.filterByProduct[product.product_id]">
                <div class="stars">
                  <span class="base"><Icon icon="star-fill" v-for="i in 5" :key="`star-${i}`" /></span>
                  <span
                    class="fill"
                    :style="{
                      width: `${avg_stars(CommentStore.filterByProduct[product.product_id])/5*100}%`
                    }"
                  >
                    <Icon icon="star-fill" v-for="i in 5" :key="`star-${i}`" />
                  </span>
                </div>
                <p>
                  {{ avg_stars(CommentStore.filterByProduct[product.product_id]) }}
                  ({{CommentStore.filterByProduct[product.product_id].length}})
                </p>
              </div>
              <button>查看商品所有評價</button>
            </div>
          </router-link>
        </li>
      </ul>
    </main>
  </div>
</template>
