<template>
  <div class="view-privacy">
    <header>
      <h1>隱私權政策</h1>
      <p>Privacy Policy</p>
    </header>
    <main>
      <section>
        <h3>隱私權保護政策暨個資蒐集告知</h3>
        <span>Chris Farrell Taiwan (以下稱「Chris Farrell」或「我們」)對您個人資料與隱私權保護非常重視。因此訂有以下「Chris Farrell Taiwan 隱私權政策」（以下稱「本政策」及其所列相關條件及連結內容），您使用本站，代表您已閱讀並同意本政策，因此請您務必詳閱本政策。若您不同意本政策或有任何疑義，請停止使用本站，並可聯繫我們，我們將盡力答覆您對於本政策的疑問。您無須填寫個人資訊即可瀏覽本站大部分網頁內容，但有時會請您留下必要資訊讓我們提供更適合您的服務。</span>
      </section>

      <section>
        <h3>1.本隱私權政策之適用範圍</h3>
        <span>
          本政策」適用於所有 Chris Farrell Taiwan 的品牌及其子公司或關係企業。
          <br />「您必須年滿二十(20)歲才能在「本網站」提供您的個人資料。若您未滿二十(20)歲，您必須取得您的父母／法定代理人之同意。您的父母／法定代理人必須閱讀並了解「本網站」所有內容並同意您在「本網站」提供個人資料。我們不會故意蒐集二十(20)歲以下個人之資料。當您繼續使用「本網站」，表示您的父母／法定代理人已經閱讀並了解「本網站」內容並同意您在「本網站」輸入您的個人資料。
          <br />
          <br />當您繼續使用「本網站」，即代表您無保留的接受並同意「本政策」之所有內容。Chris Farrell Taiwan 保留為因應法規變更及其他因素而隨時修改「本政策」內容之權利，基此，您每次進入或使用「本網站」時，請重新檢視「本政策」以了解變更及修改內容。
        </span>
      </section>

      <section>
        <h3>2.關於 Chris Farrell Taiwan</h3>
        <span>
          [ Chris Farrell Taiwan ] 為贏家風采國際事業有限公司所代理品牌之一。Chris Farrell Taiwan 負責蒐集、處理、利用與管理您與我們分享的個人資料。本政策所指「Chris Farrell 」、「我們」或「我們的」，即指 Chris Farrell Taiwan 。如應適用之個人資料保護相關法規訂定有資料控管者時，Chris Farrell Taiwan 即為個人資料之控管者。
          <br />如欲聯繫我們，請聯繫信箱 info@chris-farrell.com.tw。
          <br />如欲了解 Chris Farrell 之詳情，請瀏覽： https://www.chris-farrell.com.tw/。
        </span>
      </section>

      <section>
        <h3>3. Chris Farrell 的隱私承諾</h3>
        <span>
          我們確保將隱私及安全內建於我們所從事的活動中未經您的同意，我們不會寄發行銷訊息；且您得隨時改變您的決定我們絕不出售您的資料我們承諾維護您的資料安全，包括只選擇與可信賴的夥伴共事我們承諾以開放及透明的態度說明如何使用您的資料我們不會以未告知您的方法使用您的資料我們尊重您的權利，且會在符合法律及我們的維運責任下，盡可能配合您的要求
          <br />如欲了解更多有關我們的隱私權政策，請查閱以下所闡述之本政策內容，包括我們可能直接向您蒐集或透過您與我們的互動所蒐集的個人資料類別、我們可能使用該等資料之方式、我們可能分享該等資料之對象、我們保護該等資料並保障其安全之方式、以及您做為個人資料當事人之權利。或許本政策並非所有內容均適用於您的情況，本政策純粹讓您概覽我們或會進行互動的所有可能情況。
          <br />如您對於個人資料有任何疑問或顧慮，請透過以下方式與我們聯繫 support@chris-farrell.com.tw。
        </span>
      </section>

      <section>
        <h3>4.何謂個人資料？</h3>
        <span>「個人資料」係指任何得以直接（例如您的姓名）或間接（例如透過獨特編號等經過假名化處理的資料）識別您身分的單一或多個資料。個人資料包括電郵／住所地址、行動電話號碼、使用者名稱、個人檔案頭像、個人偏好及購物習慣、用戶內容、財務及福利資訊、以及身體狀況等。個人資料亦可能包括一串特殊辨識碼，例如您的IP位址或您行動裝置的位址，以及cookies。</span>
      </section>

      <section>
        <h3>5.個人資料之蒐集方式與適用範圍</h3>
        <span>
          我們將依照本網站提供之個別服務及活動性質取得使用者之姓名、地址、住家電話號碼、行動電話號碼、退款轉帳帳戶資訊等金融機構帳戶之號碼、身分證字號、外籍人士護照號碼、出生年月日、性別、年齡、國籍、外型描述、使用消費品之種類及服務之細節、個人或家庭之消費模式（包含瀏覽cookie記錄）等。使用者有自由選擇提供個人資料之權利，但若您不提供時，我們將無法提供相關服務，亦可能無法履行與您間之權利義務。
          <br />若您使用我們的應用程式及／或裝置，我們將蒐集您於使用期間所提供之個人資料，以向您推薦或提供您所要求之服務及建議。
          <br />我們若從第三方收到與您相關的資訊時將會加以蒐集，包括但不限於當您將您的帳號與第三方服務連結、使用 Facebook 等第三方合作夥伴登入或資料由公開可得的來源取得等。
          <br />我們可能使用Cookie技術，管理及記錄您於本網站中的活動紀錄，以便於提供更適合使用者個人需要之服務。Cookie是讓網站伺服器能夠辨識使用者的資料。一般網頁被讀取時，伺服器無法辨識使用者是否曾經來過以及來過的時間次數等資料，於是瀏覽網頁時，伺服器將一段簡短資訊經由瀏覽器寫入使用者硬碟，下次瀏覽器在要求伺服器傳回網頁之前，會將Cookies的資料先傳給伺服器，伺服器可依據Cookies的資料來判斷使用者，有了使用者的造訪資料，網頁伺服器可分析並針對不同讀者之不同喜好，執行不同的動作或傳回特定的資訊。本網站會在下列情況下，在您瀏覽器中寫入並讀取Cookies：
          <ul>
            <li>為提供更好、更個人化的服務，並方便您參與個人化的互動活動，例如儲存您偏好的特定領域資料，或儲存密碼以方便您本網站時不必每次再輸入密碼。</li>
            <li>為統計瀏覽人數及分析瀏覽模式，以了解網頁瀏覽的情況，做為我們改善服務的參考。</li>
          </ul>
          <br />如果您選擇拒絕所有的Cookies，您可能無法使用部份個人化服務，或是參與部份的活動，您可以在瀏覽器中選擇修改對Cookies的接受程度，包括接受所有Cookies、設定Cookies時得到通知、拒絕所有Cookies等。
          <br />我們會保留使用者在瀏覽或查詢時於伺服器自行產生的相關記錄，包括連線的IP位址、使用時間、使用的瀏覽器、瀏覽以及點選的資料和紀錄等。這些資料係供我們內部作網站流量和網路行為調查的總量分析，以利於提昇我們的服務品質。我們僅對全體使用者行為總和進行分析，並不會對個別使用者進行分析。
          <br />本網站為保護您的隱私及權益，當您使用信用卡方式進行購物結帳時，付款作業系統將直接連結至信用卡收單銀行之網路刷卡介面，我們不會記錄您在該介面輸入的任何資料，俾利維護您信用卡資料之機密性。
          <br />在本網站刊登廣告之廠商，或與我們合作連結的網站，可能會蒐集使用者個人資料。這些廣告廠商或連結網站應有其個別隱私權保護政策或措施，該規定或措施均不適用於我們隱私權保護政策暨個資蒐集告知，我們不負任何法律責任。使用者可能在本網站中的俱樂部、社群網站等提供個人資料，此部分因我們並非蒐集者，故不在我們隱私權保護政策暨個資蒐集告知適用範圍內，我們不負任何法律責任。
          <br />使用者傳送至我們之個人資料以外的任何其他通訊或材料，包括但不限於意見、客戶回應、建議、支持、請求、問題等內容（以下簡稱「其他訊息」）視為非本條規定個人資料範圍，將以非保密資料和非專有資料處理；而當使用者將其他訊息傳送至我們並被接收時，即被視為使用者同意且無償將其他訊息授權我們為調查、統計等使用。
        </span>
      </section>

      <section>
        <h2>聯絡我們</h2>
        <span>
          若您對本隱私權政策有任何疑問或建議，或您想要我們更新您的喜好資訊，請寄送電子郵件到以下信箱 support@chris-farrell.com.tw
          <br />
          <br />您也可以寫信至：
          <br />Chris Farrell Taiwan 台灣區總代理 贏家風采國際事業有限公司
          <br />新北市板橋區翠華街 6 巷 86 號
        </span>
      </section>
    </main>
  </div>
</template>
