<script setup>
import { onMounted } from '@vue/runtime-core'
import { RouterView, RouterLink } from 'vue-router'
import { useOrderStore } from '../../store/order'

const OrderStore = useOrderStore()
onMounted(() => {
})
</script>

<template>
  <div class="view-member">
    <header>
      <ul>
        <li><router-link to="/member/order">我的訂單</router-link></li>
        <li><router-link to="/member/coupon">優惠券</router-link></li>
        <li><router-link to="/member/info">個人資訊</router-link></li>
        <li><router-link to="/member/wishlist">願望清單</router-link></li>
      </ul>
    </header>
    <main class="member-wrapper">
      <router-view></router-view>
    </main>
  </div>
</template>
