<script setup>
import BrandLogoPcImg from '@/assets/img/brand_logo_pc.png'
import BrandLogoWPcImg from '@/assets/img/brand_logo_w_pc.png'
import BrandLogoMImg from '@/assets/img/brand_logo_m.svg'
import Icon from '@/components/Icon.vue'
import { ref, watch, inject, onMounted, onBeforeUnmount, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useLoginStore } from '../store/login';
import { usePaymentStore } from '../store/payment';
import { useLayoutStore } from '../store/layout';

const LoginStore = useLoginStore()
const PaymentStore = usePaymentStore()
const router = useRouter()
const route = useRoute()
const ScreenState = inject('ScreenProvider')
const isWhite = ref(false)
const whiteRoute = ['About', 'Post', 'ProductInstruction', 'ProductExplore', 'Vip-Right']

const hasSpread = ref(false)
const toggleSpread = () => hasSpread.value = !hasSpread.value

watch(route, (newVal) => {
  if (whiteRoute.includes(route.name)) isWhite.value = true
  else isWhite.value = false
  hasSpread.value = false
}, { immediate: true })

const toHome = () => {
  if (ScreenState.mode === 'MOBILE') hasSpread.value = false
  router.push('/')
}

const LayoutStore = useLayoutStore()
const LayoutSetting = inject('LayoutSetting')
LayoutStore.$subscribe(() => {
  console.log(LayoutStore.layoutDict['Nav-Top']);
  if (!LayoutStore.layoutDict['Nav-Top'].layout_content) {
    LayoutSetting.showNavTopNews = false
  }
})

const showBackground = ref(false)
const showProductNav = ref(false)
const showKnowledgeNav = ref(false)
const showMemberMenu = ref(false)
const toggleShowBackground = boolean => {
  const windowScrollY = document.documentElement.scrollTop
  if (ScreenState.mode === "COMPUTER" && windowScrollY < window.innerHeight * 0.1) showBackground.value = boolean
  if (!boolean) showProductNav.value = false
  if (!boolean) showKnowledgeNav.value = false
  if (!boolean) showMemberMenu.value = false
}
const toggleShowProductNav = boolean => {
  if (ScreenState.mode === "COMPUTER") showProductNav.value = boolean
  if (boolean) showKnowledgeNav.value = false
}
const toggleShowKnowledgeNav = boolean => {
  if (ScreenState.mode === "COMPUTER") showKnowledgeNav.value = boolean
  if (boolean) showProductNav.value = false
}
const toggleShowMemberMenu = boolean => {
  if (ScreenState.mode === "COMPUTER") showMemberMenu.value = boolean
}
let func = () => {
  const windowScrollY = document.documentElement.scrollTop
  const windowHeight = window.innerHeight
  if (windowScrollY >= window.innerHeight * 0.1) showBackground.value = true
  else showBackground.value = false
}
onMounted(() => {
  window.scrollTo(0, 0)
  document.addEventListener('scroll', func)
})
onBeforeUnmount(() => document.removeEventListener('scroll', func))

const $message = inject('$message')
const logout = async () => {
  router.push('/login')
  const result = await LoginStore.logout()
  if (result.status === 200) $message.success('已登出')
  location.reload()
}

const hideNavTopNews = () => LayoutSetting.showNavTopNews = false

const productCollapsed = ref(true)
const productSkinCollapsed = ref(true)
const productFeatureCollapsed = ref(true)
const knowledgeCollapsed = ref(true)
const toggleCollapse = (name) => {
  switch (name) {
    case 'skin': return productSkinCollapsed.value = !productSkinCollapsed.value
    case 'feature': return productFeatureCollapsed.value = !productFeatureCollapsed.value
    case 'product': return productCollapsed.value = !productCollapsed.value
    case 'knowledge': return knowledgeCollapsed.value = !knowledgeCollapsed.value
  }
}
const mixProductHeight = computed(() => {
  if (productCollapsed.value) return '0px'
  else {
    const skinHeight = productSkinCollapsed.value ? 0 : 260
    const featureHeight = productFeatureCollapsed.value ? 0 : 455
    return `${65 * 3 + skinHeight + featureHeight}px`   
  }
})

const FuncitonList = [
  { query: 'wrinkles-fine-lines', name: '皺紋細紋' },
  { query: 'dullness-uneven-texture', name: '暗沉蠟黃' },
  { query: 'dryness', name: '皮膚乾燥' },
  { query: 'hand-dry', name: '手部乾燥' },
  { query: 'acne-blemishes', name: '痘痘面皰' },
  { query: 'enlarged-pores', name: '毛孔粗大' },
  { query: 'all-skin-types', name: '各式皮膚問題' },
]

function toLineEvent() {
  LayoutSetting.showNavTopNews = false
  window.open('https://lin.ee/EssQxJt', '_blank')
}
</script>

<template>
  <header
    v-if="ScreenState.mode === 'COMPUTER'"
    id="nav"
    :class="{ white: isWhite, background: showBackground }"
    @mouseenter="toggleShowBackground(true)"
    @mouseleave="toggleShowBackground(false)"
  >
    <div v-if="LayoutSetting.showNavTopNews && LayoutStore.layoutDict['Nav-Top']" class="pc-nav-top-news">
      <span @click="toLineEvent">{{ LayoutStore.layoutDict['Nav-Top'].layout_content }}</span>
      <div @click="hideNavTopNews"><Icon icon="x" /></div>
    </div>
    <div class="nav-container">
      <nav class="pc-nav">
        <ul>
          <li @mouseenter="toggleShowProductNav(true)" @click="router.push('/product')">
            <router-link to="/product">系列商品</router-link>
          </li>
          <li @mouseenter="toggleShowKnowledgeNav(true)" @click="router.push('/blog')">
            <router-link to="/blog">保養知識</router-link>
          </li>
          <li>
            <router-link to="/about">品牌故事</router-link>
          </li>
          <li>
            <router-link to="/event">優惠活動</router-link>
          </li>
        </ul>
      </nav>
      <router-link class="brand-logo" to="/">
        <img :src="BrandLogoPcImg" alt="Chris Farrell 克莉斯瑞" />
      </router-link>
      <nav class="pc-nav">
        <ul>
          <li>
            <router-link to="/comments">產品心得</router-link>
          </li>
          <li v-if="!LoginStore.currentUser">
            <router-link to="/login">會員登入</router-link>
          </li>
          <!-- <li>
            <Icon icon="search"></Icon>
          </li> -->
          <li class="member" v-if="LoginStore.currentUser" @mouseenter="toggleShowMemberMenu(true)">
            <router-link to="/member/order">
              <Icon icon="person-circle"></Icon>
            </router-link>

            <aside :class="{'member-menu': true, 'show': showMemberMenu}">
              <ul>
                <li><router-link to="/member/info"><icon icon="info-circle" />會員資料</router-link></li>
                <li><router-link to="/member"><icon icon="file-earmark-text" />我的訂單</router-link></li>
                <li><router-link to="/member/coupon"><icon icon="ticket-perforated" />我的優惠</router-link></li>
                <li @click="logout"><div><icon icon="door-open-fill" />登出</div></li>
              </ul>
            </aside>
          </li>
          <li>
            <router-link v-if="PaymentStore.cartItems.length" to="/payment">
              <Icon icon="cart-fill"></Icon>
              <span
                v-if="PaymentStore.cartItems.length"
                class="items-count"
              >{{ PaymentStore.cartItems.length }}</span>
            </router-link>
            <Icon v-else icon="cart-fill"></Icon>
          </li>
        </ul>
      </nav>
    </div>
    <Transition name="fade-down">
      <div  v-if="showProductNav" class="product-nav">
        <ul>
          <!-- <li><router-link to="/product">全系列商品</router-link></li>
          <p>|</p> -->
          <li><b>依膚質選擇</b></li>
          <li><icon icon="arrow-right-short"/><router-link to="/skin-type/sensitive">敏弱肌</router-link></li>
          <li><icon icon="arrow-right-short"/><router-link to="/skin-type/dry">乾性肌</router-link></li>
          <li><icon icon="arrow-right-short"/><router-link to="/skin-type/combination">混合肌</router-link></li>
          <li><icon icon="arrow-right-short"/><router-link to="/skin-type/fragile">特殊美容</router-link></li>
        </ul>
        <ul>
          <!-- <li><router-link to="/product">全系列商品</router-link></li>
          <p>|</p> -->
          <li><b>依功能選擇</b></li>
          <li v-for="feature in FuncitonList" :key="`nav-bar-product-feature-${feature.query}`">
            <icon icon="arrow-right-short"/><router-link :to="`/product?feature=${feature.query}`">{{ feature.name }}</router-link>
          </li>
        </ul>
      </div>
    </Transition>
    <Transition name="fade-down-knowledge">
      <div  v-if="showKnowledgeNav" class="product-nav knowledge">
        <ul>
          <li><router-link to="/blog">保養專欄</router-link></li>
        </ul>
        <ul>
          <li><router-link to="/product-instruction">產品說明</router-link></li>
        </ul>
      </div>
    </Transition>
  </header>

  <header id="nav" :class="{ 'mobile-nav': true, 'spread': hasSpread }" v-else>
    <div v-if="LayoutSetting.showNavTopNews && LayoutStore.layoutDict['Nav-Top']" class="mobile-nav-top-news">
        <span>{{ LayoutStore.layoutDict['Nav-Top'].layout_content }}</span>
      <div @click="hideNavTopNews"><Icon icon="x" /></div>
    </div>
    <div class="nav-container">
      <i :class="{ 'nav-icon': true, 'spread': hasSpread }" @click="toggleSpread">
        <i class="top"></i>
        <i class="mid"></i>
        <i class="bot"></i>
      </i>
      
      <router-link class="brand-logo-mobile" to="/">
        <img :src="BrandLogoMImg" alt="Chris Farrell 克莉斯瑞" />
      </router-link>

      <router-link class="shopping-cart" to="/payment">
        <Icon icon="cart-fill"></Icon>
        <span v-if="PaymentStore.cartItems.length" class="badge">{{ PaymentStore.cartItems.length }}</span>
      </router-link>
    </div>
    

    <nav :class="{ 'collapse': true, 'spread': hasSpread, 'hadLogin': LoginStore.currentUser, down: LayoutSetting.showNavTopNews }">
      <div class="close" @click="toggleSpread"><span><icon icon="x" /></span></div>

      <ul class="layer-1">
        <li class="title" @click="toggleCollapse('product')">系列產品<icon :icon="productCollapsed ? 'plus'  : 'dash'" /></li>
        <ul
          :class="{ 'layer-2': true, 'product': true, 'collapsed': productCollapsed }"
          :style="{ minHeight: mixProductHeight }"
        >
          <li><router-link class="title" to="/product">
            全部商品<icon icon="arrow-right-short" /></router-link>
          </li>
          <li class="product-route title" @click="toggleCollapse('skin')">
            依膚質分類<icon :icon="productSkinCollapsed ? 'plus'  : 'dash'" />
          </li>
          <ul :class="{ 'layer-3': true, 'skin': true, 'collapsed': productSkinCollapsed }">
            <li class="product-route"><router-link to="/skin-type/sensitive">敏弱肌<icon icon="arrow-right-short" /></router-link></li>
            <li class="product-route"><router-link to="/skin-type/dry">乾肌<icon icon="arrow-right-short" /></router-link></li>
            <li class="product-route"><router-link to="/skin-type/combination">混合肌<icon icon="arrow-right-short" /></router-link></li>
            <li class="product-route"><router-link to="/skin-type/fragile">特殊美容<icon icon="arrow-right-short" /></router-link></li>
          </ul>
          
          <li class="product-route title" @click="toggleCollapse('feature')">
            依功能分類<icon :icon="productFeatureCollapsed ? 'plus'  : 'dash'" />
          </li>
          <ul :class="{ 'layer-3': true, 'feature': true, 'collapsed': productFeatureCollapsed }">
            <li class="product-route" v-for="feature in FuncitonList" :key="`mobile-nav-product-feature-${feature.query}`">
              <router-link :to="`/product?feature=${feature.query}`">
                {{ feature.name }}<icon icon="arrow-right-short" />
              </router-link>
            </li>
          </ul>
        </ul>
        <li>
          <router-link to="/event">優惠活動<icon icon="arrow-right-short" /></router-link>
        </li>
        <li>
          <router-link to="/comments">產品心得<icon icon="arrow-right-short" /></router-link>
        </li>
        <li>
          <router-link to="/about">品牌故事<icon icon="arrow-right-short" /></router-link>
        </li>
        <li @click="toggleCollapse('knowledge')">保養知識<icon :icon="productSkinCollapsed ? 'plus'  : 'dash'" /></li>
        <ul :class="{ 'layer-2': true, 'knowledge': true, 'collapsed': knowledgeCollapsed }">
          <li>
            <router-link to="/blog">保養專欄<icon icon="arrow-right-short" /></router-link>
          </li>
          <li>
            <router-link to="/product-instruction">產品說明<icon icon="arrow-right-short" /></router-link>
          </li>
        </ul>
        <li>
          <router-link to="/vip-rights">會員權益<icon icon="arrow-right-short" /></router-link>
        </li>
        <li v-if="!LoginStore.currentUser">
          <router-link to="/login">會員登入<icon icon="arrow-right-short" /></router-link>
        </li>
        <template v-else>
          <li>
            <router-link to="/member">會員專區<icon icon="arrow-right-short" /></router-link>
          </li>
          <li class="logout" @click.stop="logout">
            <p>登出</p>
          </li>
        </template>
      </ul>
    </nav>
  </header>
</template>
