import { defineStore } from "pinia";
import database from '@/utils/database'
import { timestampParse } from '@/utils'
import moment from 'moment'

function checkEventState(event) {
  const now = moment()
  const start = timestampParse(event.event_date_start)
  const end = timestampParse(event.event_date_end)
  if (now.isBefore(start)) return "unactive"
  else if (now.isAfter(end)) return "past"
  else return "active"
}

function replaceURLsWithLinks(text) {
  var urlRegex = /\[([^\]]+)\]\((https?:\/\/[^\s\)]+)\)/g;
  return text.replace(urlRegex, function(match, text, url) {
    return '<a href="' + url + '" target="_blank">' + text + '</a>';
  });
}


export const useEventStore = defineStore({
  id: 'Event',
  state: () => ({
    eventList: []
  }),
  getters: {
    eventUrlDict() {
      return this.eventList.reduce((acc, cur) => {
        acc[cur.event_url] = cur
        return acc
      }, {})
    },
    activeEvents() {
      return this.eventList.filter(event => event.event_state !== 'past')
    },
    pastEvents() {
      return this.eventList.filter(event => event.event_state === 'past')
    },
    productsInEvent() {
      return this.activeEvents.reduce((acc, cur) => {
        cur.event_linked_product_id.forEach(id => {
          if (!acc[id]) acc[id] = []
          acc[id].push(cur)
        })
        return acc
      }, {})
    }
  },
  actions: {
    async queryEventList() {
      const twoMonthAfter = moment().add(2, 'month')
      const twoMonthAgo = moment().subtract(2, 'month')
      const twoMonthTimeStamp = moment(twoMonthAfter.format(`YYYY-MM-${twoMonthAfter.daysInMonth()}`)).unix()
      const twoMonthAgoTimeStamp = moment(twoMonthAgo.format(`YYYY-MM-01`)).unix()
      const res = await database.table('event').join('coupon', 'coupon_id').orderBy('event.event_date_start', 'DESC').get()
      if (res.state === 200) {
        this.eventList = res.data.map(event => ({
          ...event,
          event_linked_product_id: event.event_linked_product_id ? event.event_linked_product_id.split(',') : [],
          event_content: event.event_content && replaceURLsWithLinks(event.event_content.replace(/\n/g, '<br>')),
          event_state: event.event_date_end ? checkEventState(event) : 'active',
          event_date_start: event.event_date_start || twoMonthAgoTimeStamp,
          event_date_end: event.event_date_end || twoMonthTimeStamp,
        }))
      }
    }
  }
})
