<script setup>
import { ref, markRaw, onMounted, reactive, watch, inject, nextTick, computed, onBeforeUnmount, provide } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Cart from './Cart'
import Shipping from './Shipping'
import Pay from './Pay'
import Result from './Result'
import CouponItems from './components/CouponItems'
import { usePaymentStore } from "../../store/payment";
import { useProductStore } from "../../store/product";
import Icon from '@/components/Icon'
import { useLoginStore } from '../../store/login'
import { useCouponStore } from '../../store/coupon'
import { fetchApi, completePhoneNumber } from '@/utils'
import { useLayoutStore } from '../../store/layout'
import { useOrderStore } from '../../store/order'
import { getCookieByKey, setCookie, removeCookieByKey, timestampParse } from '../../utils'
import { couponConditionJudgment } from '@/store/utils'
import moment from 'moment'
import { gaEvent } from '@/utils/event'

const $message = inject('$message')
const ScreenState = inject('ScreenProvider')
provide('PaymentUseCoupon', reChooseCouponItem)
const PaymentStore = usePaymentStore()
const ProductStore = useProductStore()
const LoginStore = useLoginStore()
const LayoutStore = useLayoutStore()
const CouponStore = useCouponStore()
const OrderStore = useOrderStore()
const router = useRouter()

const STEP_DICT = {
  'CART': {
    component: markRaw(Cart)
  },
  'SHIPPING': {
    component: markRaw(Shipping)
  },
  'PAY': {
    component: markRaw(Pay)
  },
  'RESULT': {
    component: markRaw(Result)
  }
}

const Route = useRoute()
const currentStep = ref('CART')
const currentComponentREF = ref()

async function loadCoupon(coupon_uid, source, sourceCoupon) {
  if (includeCoupon(coupon_uid)) return
  let result={};
  if (source === 'user') result.coupon = sourceCoupon
  else result = await CouponStore.getCouponeWithUid(coupon_uid, 'SYSTEM')

  if (result.coupon) {
    const now = new moment().unix()
    if ((result.coupon.coupon_start && now < result.coupon.coupon_start) || (result.coupon.coupon_exp && now > result.coupon.coupon_exp)) return PaymentStore.removeCoupon(result.coupon.coupon_uid)
    const targetCoupon = result.coupon
    switch (result.coupon.coupon_type) {
      case 'GIVEAWAY':
        if (targetCoupon.coupon_product.length == targetCoupon.coupon_max_product_chosen) {
          const items = result.coupon.coupon_product.map(id => ({
            product_uid: ProductStore.productDict[id].product_uid,
            product_quantity: 1
          }))
          return PaymentStore.useCoupon(result.coupon, items)
        } else {
          const targetCouponCache = PaymentStore.couponCache.find(c => c.coupon_uid === targetCoupon.coupon_uid)
          if (targetCouponCache) return PaymentStore.useCoupon(targetCouponCache, targetCouponCache.items)
          toggleShowCouponModal(true)
          focusCoupon.value = targetCoupon
        }
        break
      case 'BONUS':
        const bonusItems = PaymentStore.cartItems.filter(i => {
          const product = ProductStore.productDict[i.product_id]
          if (result.coupon.coupon_product[0] === 'PRODUCT') return product.product_type === 'PRODUCT' && i.product_quantity >= result.coupon.coupon_bonus_min_standard
          if (result.coupon.coupon_product[0] === 'SET') return product.product_type === 'SET'
          if (result.coupon.coupon_product[0] === 'ALL') return true
          return result.coupon.coupon_product.includes(i.product_id)
        }).map(i => ({
          product_uid: ProductStore.productDict[i.product_id].product_uid,
          product_quantity: parseInt(i.product_quantity/result.coupon.coupon_bonus_min_standard)
        }))
        if (bonusItems.length) return PaymentStore.useCoupon(result.coupon, bonusItems)
        else return PaymentStore.removeCoupon(result.coupon.coupon_uid)
        
      default:
        return PaymentStore.useCoupon(result.coupon)
    }
  }
}

function reChooseCouponItem(coupon) {
  toggleShowCouponModal(true)
  focusCoupon.value = coupon
}

async function autoLoadSystemConditionCoupon() {
  const SysCoupons = await CouponStore.getSystemCoupons()
  SysCoupons.forEach(c => {
    if (!c.isInvalid) {
      if (!includeCoupon(c.coupon_uid)) {
        loadCoupon(c.coupon_uid)
      }
    } else {
      PaymentStore.removeCoupon(c.coupon_uid)
    }
  })
}

async function autoLoadMemberOwnConditionCoupon() {
  await CouponStore.queryCoupon()
  if (LoginStore.currentUser && CouponStore.couponList.length) {
    const usersUsedCoupon = LoginStore.currentUser.user_coupons_used
    const usersCoupon = LoginStore.currentUser.user_own_coupons.filter(c => {
      if (CouponStore.couponIdDict[c.coupon_id].coupon_auto_loading === '1') {
        const coupon = CouponStore.couponIdDict[c.coupon_id]
        if (coupon.coupon_exp && moment().unix() > coupon.coupon_exp) return false
        if (coupon.coupon_period && moment().unix() > parseInt(coupon.coupon_period) + parseInt(c.coupon_get_at)) return false
        if (coupon.coupon_max_usage && coupon.coupon_max_usage <= usersUsedCoupon.filter(u => u === c.coupon_id).length) return false
        if (coupon.coupon_condition) {
          const invalidCondition = couponConditionJudgment(coupon, CouponStore.useStore)
          return !invalidCondition || !invalidCondition.length
        }
        return true
      }
      return false
    }).map(c => ({ ...c, ...CouponStore.couponIdDict[c.coupon_id] }))

    if (usersCoupon.length) {
      usersCoupon.forEach(c => {
        loadCoupon(c.coupon_uid, 'user', c)
      })
    }
  }
}

function includeCoupon(coupon_uid) {
  return PaymentStore.couponUsed.map(c => c.coupon_uid).includes(coupon_uid)
}

// const showMemberLoginReminder = ref(false)
// function closeReminder() {
  // showMemberLoginReminder.value = false
// }
// function toLogin() {
//   Router.push('/register?redirect=payment')
// }

const paymentLastTime = ref()
const paymentEndTime = ref()
const paymentTimeout = ref()
function formatTimeNumber(time) {
  return `${time}`.length === 1 ? `0${time}` : `${time}`
}

function timeoutCountdown() {
  if (paymentLastTime.value > 0) {
    paymentLastTime.value = paymentEndTime.value - new Date().getTime()
    if (paymentLastTime.value <= 480000 && !includeCoupon('pay_in_5_min')) loadCoupon('pay_in_5_min')
    setTimeout(timeoutCountdown, 50);
  } else {
    paymentLastTime.value = -1
    PaymentStore.removeCoupon('pay_in_5_min')
  }
}

watch(paymentLastTime, (val) => {
  if (val === -1) return PaymentStore.removeCoupon('pay_in_5_min')
})

onMounted(async () => {
  window.scrollTo(0, 0)
  const { query } = Route
  if (query.to) {
    CouponStore.queryCoupon()
    currentStep.value = query.to.toUpperCase()
  }
  const { state, login, cus } = query
  if (state==='200' && login==='line') {
    setCookie('cf:login', new moment().unix())
    LoginStore.queryUserWithId(cus)
  }
  if (query.fromcvs==='1') currentStep.value = 'SHIPPING'

  const PaymentTimeoutStart = parseInt(getCookieByKey('cf:payment_start'))
  if (!PaymentTimeoutStart) {
    paymentLastTime.value = 600000
    const timeStart = new Date().getTime()
    setCookie('cf:payment_start', timeStart)
    paymentEndTime.value = timeStart + 600000
    
  } else {
    if (new Date().getTime() - PaymentTimeoutStart > 18000000) {
      paymentLastTime.value = 600000
      const timeNow = new Date().getTime()
      setCookie('cf:payment_start', timeStart)
      paymentEndTime.value = timeNow + 600000
    } else {
      const timeNow = new Date().getTime()
      paymentLastTime.value = PaymentTimeoutStart + 600000 - timeNow
      paymentEndTime.value = PaymentTimeoutStart + 600000
    }
  }
  timeoutCountdown()

  //自動依條件載入當期系統型優惠券
  autoLoadSystemConditionCoupon()
  //自動依條件載入會員持有的自動載入型優惠券
  autoLoadMemberOwnConditionCoupon()

  if (OrderStore.orderList.length) {
    paymentLastTime.value = -1
  }


  // if (!LoginStore.currentUser) reminderTimeout.value = setTimeout(() => showMemberLoginReminder.value = true, 3000)
})

const resetPaymentTimeout = () => {
  const timeStart = new Date().getTime()
  setCookie('cf:payment_start', timeStart)
  paymentEndTime.value = timeStart + 300000
  paymentLastTime.value = 300000
}

LoginStore.$subscribe(async (mutation, state) => {
  if (state.currentUser) {
    // showMemberLoginReminder.value = false
    CouponStore.queryCoupon()
    //自動依條件載入當期系統型優惠券
    autoLoadSystemConditionCoupon()
    //自動依條件載入會員持有的自動載入型優惠券
    autoLoadMemberOwnConditionCoupon()
  }
})

watch(PaymentStore.cartItems, async (cartItems) => {
  //自動依條件載入當期系統型優惠券
  autoLoadSystemConditionCoupon()
  //自動依條件載入會員持有的自動載入型優惠券
  autoLoadMemberOwnConditionCoupon()


  //如果購物車中加購商品以外的商品都被移除，則將加購商品也移除
  if (!cartItems.filter(p => p.product_price !== 'ADDITIONAL').length) PaymentStore.cleanAdditionalProduct()
}, { immediate: true})

OrderStore.$subscribe(async (mutation, state) => {
  if (state.orderList.length) {
    paymentLastTime.value = -1
  }

  //創始會員生日禮
  if (state.orderList.length && LoginStore.currentUser && LoginStore.currentUser.user_group === 'ORIGINAL') {
    const userBirthdayMonth = timestampParse(LoginStore.currentUser.user_birthday).format('M')
    if (userBirthdayMonth === moment().format('M')) {
      const thisMonthOrder = state.orderList.filter(o => moment(o.order_create_at).format('M') === moment().format('M'))
      if (!thisMonthOrder.length && !includeCoupon('original_birthday')) {
        await loadCoupon('original_birthday')
      }
    } else {
      PaymentStore.removeCoupon('original_birthday')
    }
  }
})

async function nextStep() {
  if (ScreenState.mode === 'MOBILE') {
    if (currentComponentREF.value.handleNext) {
      const result = await currentComponentREF.value.handleNext()
      if (!result) return
    }
    window.scrollTo(0, 0)
  }

  switch (currentStep.value) {
    case 'CART':
      currentStep.value = 'SHIPPING'
      break;
    case 'SHIPPING':
      gaEvent('begin_checkout', {
        currency: 'TWD',
        value: PaymentStore.amount,
        coupon: PaymentStore.couponUsed.map(c => c.coupon_name).join(','),
        items: PaymentStore.cartItems.map(product => ({
          item_id: ProductStore.productDict[product.product_id].product_capi_uid,
          item_name: ProductStore.productDict[product.product_id].product_name_zh,
          item_category: ProductStore.productDict[product.product_id].product_type.toLowerCase(),
          price: ProductStore.productDict[product.product_id].product_price[product.product_price],
          quantity: product.product_quantity
        }))
      })
      // let PaymentTimeoutStart = getCookieByKey('cf:payment_start')
      // if (PaymentTimeoutStart) { // 如果餘時間內前往結賬頁，即可獲得 50 元折扣優惠券
      //   let diff = moment().diff(timestampParse(Math.floor(PaymentTimeoutStart/1000)), 'minutes')
      //   if (diff > 2 && diff < 7)  loadCoupon('pay_in_5_min')
      // }
      currentStep.value = 'PAY'
      break;
    case 'PAY':
      currentStep.value = 'RESULT'
      break;
    default:
      currentStep.value = 'CART'
      break;
  }
}
const lastStep = () => {
  if (ScreenState.mode === 'MOBILE') window.scrollTo(0, 0)
  switch (currentStep.value) {
    case 'SHIPPING':
      currentStep.value = 'CART'
      break;
    case 'PAY':
      currentStep.value = 'SHIPPING'
      break;
    default:
      break;
  }
}

const loginInfo = reactive({ account: '', password: '' })
const loading = ref(false)
const login = async () => {
  loading.value = true
  if (loginInfo.account && loginInfo.password) {
    const result = await LoginStore.loginWithEmail(loginInfo)
    if (result.status === 200) {
      $message.success('登入成功')
      paymentLastTime.value = -1
    }
    else $message.warning(result.msg)
  }
  loading.value = false
}

const Router = useRouter()
const toRegister = () => Router.push('/register')

const showCouponModal = ref(false)
function toggleShowCouponModal(bool) {
  showCouponModal.value = bool
}

const focusCoupon = ref(null)
const couponGiveawayItems = reactive([])
function useCoupon(coupon) {
  if (coupon.coupon_super === "PUBLIC_FIRST_200" && PaymentStore.amount < 1000) {
    return $message.warning('未達使用優惠券標準')
  }
  if (coupon.coupon_super === "2024_new_year_old_member" && PaymentStore.amount < 1800) {
    return $message.warning('消費滿$1800才可使用')
  }
  if (coupon.coupon_type === 'DISCOUNT') return PaymentStore.useCoupon(coupon)
  focusCoupon.value = coupon
}

const handleCouponItemUse = bool => {
  if (bool) {
    if (couponGiveawayItems.length != focusCoupon.value.coupon_max_product_chosen) {
      $message.warning(`您未選滿贈品，贈品總共可選擇${focusCoupon.value.coupon_max_product_chosen}項`)
      return
    }
    PaymentStore.useCoupon(focusCoupon.value, couponGiveawayItems)
  } 
  couponGiveawayItems.splice(0, couponGiveawayItems.length)
  focusCoupon.value = null
}
const chooseItem = item => {
  const index = couponGiveawayItems.map(e => e.product_uid).indexOf(item.product_uid)
  if (index === -1 && couponGiveawayItems.length < focusCoupon.value.coupon_max_product_chosen) {
    couponGiveawayItems.push(item)
  }
  if (index !==-1) couponGiveawayItems.splice(index, 1)
}

function checkCouponExpired(coupon) {
  if (coupon.coupon_exp) {
    return new moment().unix() > coupon.coupon_exp
  }
  return false
}

const couponUid = ref('')
const useCouponUid = async () => {
  const { state, coupon, message } = await CouponStore.getCouponeWithUid(couponUid.value)
  switch (state) {
    case 400: return $message.warning('找不到符合此優惠碼的優惠券')
    case 500: return $message.warning('此優惠券為限量，限量次數已被領完')
    case 501: return $message.warning('此優惠券已過有效期限')
    case 502: return $message.warning('您已使用過優惠券並超過該優惠券最大使用次數')
    case 503: return $message.warning(message)
    case 200:
      if (!PaymentStore.couponUsed.filter(c => c.coupon_uid === coupon.coupon_uid).length) {
        if (coupon.coupon_type === "DISCOUNT") {
          useCoupon(coupon)
          couponUid.value = ''
          return $message.success('已套用優惠')
        }
        if (coupon.coupon_type === "GIVEAWAY") {
          toggleShowCouponModal(true)
          focusCoupon.value = coupon
          couponUid.value = ''
          return
        }        
      }
    default: break;
  }
}

const voucherInputREF = ref(null)
const showVoucherInput = ref(false)
const toggleShowVoucherInput = async (bool=true) => {
  showVoucherInput.value = bool
  if (bool) {
    await nextTick()
    voucherInputREF.value?.focus()
  }
}

const voucherUsed = ref(0)
const checkVoucherUsed = () => {
  PaymentStore.useVoucher(voucherUsed.value)
  toggleShowVoucherInput(false)
}

//購物金輸入防抖校正
const voucherTimeout = ref()
const voucherNumberEdit = newVal => {
  const intNewVal = Number(newVal.replace(/\b[0e]{1,}/g, ''))
  if (intNewVal < 0 || intNewVal === NaN || !intNewVal) return voucherUsed.value = 0
  if (intNewVal > LoginStore.activeVoucherAmount) return voucherUsed.value = LoginStore.activeVoucherAmount
  voucherUsed.value = newVal.replace(/\b[0e]{1,}/g, '')
}
const voucherDebounce = (func, wait, debounce=true) => {
  if (voucherTimeout.value) clearTimeout(voucherTimeout.value)
  if (debounce) voucherTimeout.value = setTimeout(() => func(), wait)
  else func()
}
const voucherChange = (e, debounce=true) => voucherDebounce(()=>voucherNumberEdit(e.target.value), 500, debounce)

const spreadLoginV2 = ref(false)
const loginV2Email = ref('')
const loginV2Pwd = ref(null)
const loginV2Loading = ref(false)
function toggleLoginV2(bool) {
  if(bool===undefined) return spreadLoginV2.value = !spreadLoginV2.value
  spreadLoginV2.value = bool
}
async function handleCheckAccount() {
  loginV2Loading.value = true
  if (!loginV2Email.value) return $message.warning('請輸入信箱')
  const res = await LoginStore.checkAccount(loginV2Email.value)
  if (res) {
    loginV2Pwd.value = ''
    
  } else {
    router.push(`/register?redirect=payment_${currentStep.value.toLowerCase()}&email=${loginV2Email.value}`)
  }
  loginV2Loading.value = false
}

async function handleLogin() {
  loginV2Loading.value = true
  if (loginV2Email.value && loginV2Pwd.value) {
    const result = await LoginStore.loginWithEmail({ account: loginV2Email.value, password: loginV2Pwd.value })
    if (result.status === 200) {
      $message.success('登入成功')
      paymentLastTime.value = -1
    }
    else $message.warning(result.msg)
  }
  loginV2Loading.value = false
}

async function handleLineLogin() {
  const lineLoginUri = 'https://access.line.me/oauth2/v2.1/authorize'
  const urlObj = new URL(window.location)
  const timestamp = moment().valueOf()
  const randomNum = parseInt(Math.random()*10000)
  const loginRequest = {
    response_type: 'code',
    client_id: process.env.VUE_APP_LINE_CHANNEL_ID,
    redirect_uri: `https://www.chris-farrell.com.tw/api/line/login?redirect=payment_${currentStep.value.toLowerCase()}`,
    scope: 'profile%20openid%20email',
    nonce: 'ChrisFarrellLineLogin',
    state: `${timestamp}${randomNum}`
  }
  const combiUri = `${lineLoginUri}?${Object.entries(loginRequest).map(entries => entries[0]+'='+entries[1]).join('&')}`
  window.location = combiUri
}

async function handleFBLogin() {
  const result = await LoginStore.loginWithFB()
  if (result.state === 200) $message.success('登入成功')
  if (result.state === 405) $message.warning('FB 登入錯誤，請重新整理頁面')
  else router.push(`/register?redirect=payment_${currentStep.value.toLowerCase()}login=fb&cus=${result.user_id}`)
}
</script>

<template>
  <div :class="{ 'view-payment': true, 'reminder': paymentLastTime <= 480000 && paymentLastTime >= 0 }">
    <Transition name="top-in">
      <div
        v-if="paymentLastTime <= 480000 && paymentLastTime >= 0"
        :class="{ 'timeout-counter': true, 'expiring': paymentLastTime <= 600000 }"
      >
        <div class="display"><icon icon="clock-history" />
          剩餘 {{ formatTimeNumber(Math.floor(paymentLastTime/60000)) }} : {{ formatTimeNumber(Math.floor(paymentLastTime/1000%60)) }} : {{ `${paymentLastTime}`.slice(-3, -1) }}
        </div>
        <span>時間內完成結帳，可享＄50元折扣</span>
        <button v-if="paymentLastTime <= 300000" @click="resetPaymentTimeout">延長</button>
      </div>
    </Transition>

    <header class="steps">
      <div :class="{ 'step': true, 'active': currentStep === 'CART' || currentStep === 'RESULT' }">
        <div class="circle"><p v-if="currentStep !== 'RESULT'">1</p></div>
        <p>購物車</p>
      </div>
      <span class="line"></span>
      <div :class="{ 'step': true, 'active': currentStep === 'SHIPPING' || currentStep === 'RESULT' }">
        <div class="circle"><p v-if="currentStep !== 'RESULT'">2</p></div>
        <p>運送方式</p>
      </div>
      <span class="line"></span>
      <div :class="{ 'step': true, 'active': currentStep === 'PAY' || currentStep === 'RESULT' }">
        <div class="circle"><p v-if="currentStep !== 'RESULT'">3</p></div>
        <p>付款</p>
      </div>
    </header>
    
    <main class="step-wrapper">
      <section v-if="ScreenState.mode === 'MOBILE' && !LoginStore.currentUser" class="login-section">
        <div :class="{'login-v2': true, 'spread': spreadLoginV2}">
          <header @click="e => toggleLoginV2()"><p>登入或註冊會員</p><icon icon="caret-down-fill"/></header>
          <main>
            <h3>{{ loginV2Pwd!==null ? "登入您的帳號" : "輸入您的 Email" }}，享受會員優惠</h3>
            <input type="email" placeholder="*信箱" v-model="loginV2Email">
            <input v-if="loginV2Pwd!==null" class="pwd" type="password" placeholder="*密碼" v-model="loginV2Pwd">
            <button
              :class="{'loading-button': true, loading: loginV2Loading }"
              v-if="loginV2Pwd===null"
              @click="handleCheckAccount">繼續
            </button>
            <button :class="{'loading-button': true, loading: loginV2Loading }" v-else @click="handleLogin">登入</button>
            <hr>
            <h3>或使用社群帳號登入</h3>
            <button @click="handleLineLogin"><icon class="line" icon="line" />Line 登入/註冊</button>
          </main>
        </div>
        <div class="hint">註冊會員即享$200首購折扣及首購禮六件組</div>
      </section>

      <component
        ref="currentComponentREF"
        :is="STEP_DICT[currentStep].component"
        class="step-comp"
        :nextStep="nextStep"
        :lastStep="lastStep"
      ></component>
      <aside>
        <!-- <Transition name="fade-down">
          <div
            v-if="paymentLastTime <= 480000 && paymentLastTime >= 0 && ScreenState.mode === 'COMPUTER'"
            :class="{ 'timeout-counter-aside': true, 'expiring': paymentLastTime <= 600000 }"
          >
            <div class="display"><icon icon="clock-history" />
              剩餘 {{ formatTimeNumber(Math.floor(paymentLastTime/60000)) }} : {{ formatTimeNumber(Math.floor(paymentLastTime/1000%60)) }} : {{ `${paymentLastTime}`.slice(-3, -1) }}
            </div>
            <span>時間內完成結帳，可享＄50元折扣</span>
          </div>
        </Transition> -->
        <section class="login" v-if="ScreenState.mode === 'COMPUTER'">
          <header>{{ LoginStore.currentUser ? '會員資訊' : '登入' }}</header>
          <template v-if="!LoginStore.currentUser">
            <input v-model="loginInfo.account" type="text" placeholder="帳號" />
            <input v-model="loginInfo.password" @keydown.enter="login" type="password" placeholder="密碼" />
            <button @click="login" :disabled="loading">登入會員</button>

            <div class="register">
              <header>社群帳號</header>
              <button @click="handleLineLogin" class="line-login"><icon icon="line" /> Line 登入/註冊</button>
            </div>

            <div class="register">
              <header>註冊會員</header>
              <p>加入會員即享$200首購折扣及首購禮六件組</p>
              <button @click="toRegister">加入會員</button>
            </div>
          </template>
          <template v-else>
            <p>姓名：{{ LoginStore.currentUser?.user_name || '未設定' }}</p>
            <p>電話：{{ LoginStore.currentUser.user_phone ? completePhoneNumber(LoginStore.currentUser.user_phone) : '未設定' }}</p>
            <!-- <span class="extra">已套用會員優惠</span> -->
          </template>
        </section>
        <section class="payment-detail">
          <header>
            <h3>付款詳情</h3>
          </header>

          <main>
            <div class="coupon-use" v-if="LoginStore.currentUser">
              <input type="text" v-model="couponUid" placeholder="推薦碼/優惠碼" />
              <button @click="useCouponUid">套用</button>
            </div>

            <div class="coupon-choose">
              <a v-if="LoginStore.currentUser" @click="toggleShowCouponModal(true)">選擇優惠券</a>
              <ul class="coupon-used">
                <li v-for="coupon in PaymentStore.couponUsed" :key="`coupon_used_${coupon.coupon_uid}`">
                  <icon icon="ticket-perforated-fill" />
                  <p>{{ coupon.coupon_name }}</p>
                  <div v-if="coupon.coupon_scope!=='SYSTEM'">
                    <icon @click="PaymentStore.removeCoupon(coupon.coupon_uid)" icon="x-lg" />
                  </div>
                </li>
                <li v-if="!LoginStore.currentUser" class="register-remider2">註冊會員即享$200首購折扣及首購禮六件組</li>
              </ul>
            </div>

            <ul class="amount">
              <li>
                <p>總計</p>
                <p>${{ PaymentStore.amount }}</p>
              </li>
              <li v-if="LoginStore.activeVoucherAmount > 0" class="voucher">
                <button @click="toggleShowVoucherInput">購物金折抵</button>
                <p>-${{ PaymentStore.voucherUsed }}</p>
              </li>
              <li v-if="LoginStore.activeVoucherAmount > 0" class="voucher-use">
                <p>購物金餘額 NT${{ LoginStore.activeVoucherAmount }}</p>
                <transition>
                  <div class="input-wrapper" v-if="showVoucherInput">
                    <span>
                      <input
                        ref="voucherInputREF"
                        v-model="voucherUsed"
                        type="number"
                        @keyup="voucherChange"
                        @change="(e) => voucherChange(e, false)"
                      />
                    </span>
                    <button @click="checkVoucherUsed">套用</button>
                  </div>
                </transition>
              </li>
              <li>
                <p>優惠折扣</p>
                <p>- ${{ PaymentStore.amount - PaymentStore.sumOfCartPrice - PaymentStore.voucherUsed }}</p>
              </li>
              <li>
                <p>運費</p>
                <p>
                  {{ currentStep === 'PAY' ? `$${PaymentStore.freeShippingCost}` : '於結帳頁面計算' }}
                </p>
              </li>
              <li class="total">
                <p>付款總金額</p>
                <p>${{ PaymentStore.sumOfCartPrice + PaymentStore.freeShippingCost }}</p>
              </li>
              <i>
                <span>稅金 (已內含5%加值型營業稅)</span>
              </i>
            </ul>
          </main>
        </section>

        <div id="pptype" class="pay-and-port-type">
          <h4>可使用付款方式</h4>
          <div class="icons">
            <i class="fa-brands fa-cc-visa"></i>
            <i class="fa-brands fa-cc-mastercard"></i>
            <i class="fa-brands fa-cc-jcb"></i>
            <p class="cod">, 貨到付款</p>
          </div>
          <h4>可選擇貨運方式</h4>
          <div class="icons">
            <p>7-11, FamilyMart</p>
            <p>黑貓宅配到府, 港澳國際物流</p>
          </div>
        </div>

        <footer>
          <button class="backToChoose" @click="router.push('/product')">繼續選購商品</button>
          <div class="button-group">
            <button v-if="currentStep !== 'CART'" class="last" @click="lastStep">上一步</button>
            <button v-if="currentStep !== 'PAY'" class="next" @click="nextStep">下一步</button>
          </div>
        </footer>
      </aside>
    </main>

    <div v-if="showCouponModal" class="coupon-modal" @click.self="toggleShowCouponModal(false)">
      <div class="wrapper">
        <header>
          <h3>我的優惠券</h3>
          <div class="close" @click="toggleShowCouponModal(false)"><icon icon="x-lg"/></div>
        </header>

        <transition name="left-in">
          <main v-if="!focusCoupon">
            <ul v-if="CouponStore.couponList.length">
              <template
                v-for="coupon in CouponStore.couponList.filter(c => ['MEMBER', 'ALL'].includes(c.coupon_scope))"
                :key="coupon.coupon_uid"
              >
              <li
                v-if="!PaymentStore.couponUsed.map(e => e.coupon_uid).includes(coupon.coupon_uid)"
                :class="{ used: PaymentStore.couponUsed.includes(coupon.coupon_uid), expired: checkCouponExpired(coupon) }"
              >
                <div class="content">
                  <h4>{{ coupon.coupon_name }}</h4>
                  <span class="desc">{{ coupon.coupon_desc }}</span>
                </div>
                <div class="use">
                  <button @click="useCoupon(coupon)" :disabled="checkCouponExpired(coupon)">
                    {{ checkCouponExpired(coupon) ? '已過期' : '使用'}}
                  </button>
                </div>
              </li>
              </template>
            </ul>
            <p class="no-coupon" v-else>目前沒有優惠券可使用</p>
          </main>
        </transition>
        <transition name="right-in">
          <main v-if="focusCoupon" class="giveaway-items">
            <h3>{{ focusCoupon.coupon_name }}優惠券
            - 贈品選擇 {{ couponGiveawayItems.length }} / {{ focusCoupon.coupon_max_product_chosen }}</h3>
            <section>
              <ul>
                <template v-if="focusCoupon.coupon_product[0] === 'ALL'">
                  <coupon-items type="PRODUCT" :couponGiveawayItems="couponGiveawayItems" @choose="chooseItem"></coupon-items>
                </template>
                <template v-if="focusCoupon.coupon_product[0] === 'SET'">
                  <coupon-items type="SET" :couponGiveawayItems="couponGiveawayItems" @choose="chooseItem"></coupon-items>
                </template>
                <template v-else>
                  <coupon-items
                    type="CUSTOM"
                    :couponGiveawayItems="couponGiveawayItems"
                    :customProdcutIds="focusCoupon.coupon_product"
                    @choose="chooseItem"
                  />
                </template>
              </ul>
            </section>
            <div class="button-group">
              <button @click="handleCouponItemUse(false)">取消</button>
              <button @click="handleCouponItemUse(true)">確定</button>
            </div>
          </main>
        </transition>
      </div>
    </div>
  </div>
</template>
