import { defineStore } from "pinia";
import { useIngredientStore } from "./ingredient";
import { useLayoutStore } from './layout'
import database from '@/utils/database'

export const useProductStore = defineStore({
  id: 'Product',
  state: () => ({
    productList: [],
    focusProductName: ""
  }),
  getters: {
    publishedProducts() {
      if (this.productList.length) return this.productList.filter(p => p.product_is_launched !== '0')
      return []
    },
    productAndSet() {
      if (this.publishedProducts.length) {
        return this.publishedProducts.reduce((acc, p) => {
          acc[p.product_type].push({ ...p })
          return acc
        }, { PRODUCT: [], SET: [] })
      }
      return { PRODUCT: [], SET: [] }
    },
    hotSaleProducts() {
      if (this.publishedProducts.length) {
        return this.publishedProducts.filter(p => p.product_is_hot_sale === '1')
      }
      return []
    },
    focusProduct() {
      const IngredientStore = useIngredientStore()
      if (this.productList.length && this.focusProductName) {
        const focusIndex = this.productList.map(p => p.product_name_en.replace(/\s/g, '-').toLowerCase()).indexOf(this.focusProductName)
        console.log(focusIndex)
        let product_ingredients
        const product = {
          ...this.productList[focusIndex],
          product_flow: this.productList[focusIndex].product_flow
            ? this.productList[focusIndex].product_flow.split(',').map(pid => {
              if (pid === 'self') return { product_id: this.productList[focusIndex].product_id }
              if (pid[0] === '+') return { product_id: pid.slice(1, pid.length), must: true }
              if (pid[0] === '-') return { product_id: pid.slice(1, pid.length), must: false }
              return { product_id: pid }
            })
            : []
        }
        return product
      }
      return null
    },
    productDict() {
      if (this.productList.length) {
        return this.productList.reduce((acc, p) => ({
          ...acc,
          [p.product_id]: { ...p }
        }), {})
      }
      return {}
    },
    productUidDict() {
      if (this.productList.length) {
        return this.productList.reduce((acc, p) => ({
          ...acc,
          [p.product_uid]: { ...p }
        }), {})
      }
      return {}
    },
    additionalProductList() {
      if (this.productList.length) {
        return this.productList.filter(p => p.product_type === 'PRODUCT' && p.product_is_additional)
      }
      return []
    }
  },
  actions: {
    async queryProduct() {
      const LayoutStore = useLayoutStore()
      const res = await database.table('product').orderBy('product_sort_index', 'ASC').get()
      console.log(res)
      const data = res.data.map(e => ({
        ...e,
        product_price: JSON.parse(e.product_price),
        product_images: e.product_images 
          ? e.product_type === 'SET'
            ? [...e.product_images.split('|')] 
            : [`${e.product_uid}/${e.product_type === 'PRODUCT' ? LayoutStore.layoutDict['PRODUCT_AVATAR_NAME']?.layout_content || 'avatar' : 'avatar'}.webp`,...e.product_images.split('|')] 
          : [],
        product_size: e.product_size ? e.product_size.split(',') : [],
        product_desc: e.product_desc ? e.product_desc.replaceAll(/\n/g, '<br>'): '',
        product_characters: e.product_characters ? e.product_characters.split('|') : [],
        product_set_items: e.product_set_items && e.product_set_items.split(','),
        product_skin_type: e.product_skin_type ? e.product_skin_type.split(',') : [],
        product_feature: e.product_feature ? e.product_feature.split(',') : [],
        product_detail_images: e.product_detail_images ? e.product_detail_images.split(',') : [],
        product_is_additional: e.product_is_additional === '1',
        product_ingredients: e.product_ingredients ? e.product_ingredients.replace(/\^([\d])\^/g, match => {
          return `<sup>${match[1]}</sup>`
        }) : null
      }))
      this.productList = ([...data])
    }
  }
})
