<script setup>
import Icon from '@/components/Icon'
import BirthdayInputGroup from '@/components/BirthdayInputGroup'
import { CfInput, CfSelect } from '@/components/Form'
import { useLoginStore } from '../../store/login'
import { reactive } from '@vue/reactivity'
import { ref, inject, onMounted } from '@vue/runtime-core'
import { timestampParse } from '@/utils'
import moment from 'moment'

const FORM_FIELDS = ["user_name", "user_phone", "user_gender", "user_birthday", "user_city", "user_urban_area", "user_address"]
const VOUCHER_TYPE_DICT = {
  SHOP_BACK: '消費回饋',
  FRIEND_BACK: '推薦碼回饋',
  BIRTHDAY: '生日禮'
}
const LoginStore = useLoginStore()
const userInfo = reactive({})
onMounted(() => {
  if (LoginStore.currentUser) injectUserStateIntoForm()
})

const currenUserMutated = ref(false)
LoginStore.$subscribe((mutation, newState) => {
  if (currenUserMutated.value) return
  if (newState.currentUser) {
    injectUserStateIntoForm()
    currenUserMutated.value = true
  }
})

function injectUserStateIntoForm() {
  for (const key of FORM_FIELDS) {
    if (key === 'user_birthday') {
      const momentDate = moment(parseInt(LoginStore.currentUser[key])*1000).format('YYYY/MM/DD')
      if (momentDate === 'Invalid date') {
        userInfo[key] = { y: '', m: '', d: '' }
        continue
      }
      const [y='', m='', d=''] = momentDate.split('/')
      userInfo[key] = { y, m, d }
      continue
    }
    userInfo[key] = LoginStore.currentUser[key]
  }
}

const $message = inject('$message')
function dateToMoment({ y, m, d }) {
  const dateMoment = moment([parseInt(y), parseInt(m)-1, parseInt(d)])
  console.log(dateMoment.isValid());
  return dateMoment
}
async function updateNewUserInfo() {
  const dateMoment = dateToMoment(userInfo.user_birthday)
  if (!dateMoment.isValid()) return $message.warning('生日格式錯誤，請遵循（西元年份/月份/日期）格式進行填寫')
  console.log(dateMoment.unix());
  const res = await LoginStore.updateUser({ ...userInfo, user_birthday: dateMoment.unix()})
  if (res.status === 200) $message.success('已更新資訊')
}

async function changePassword() {
  const result = await LoginStore.changePassword()
  if (result.state) $message.success('已發送重設密碼連結至您的信箱')
}

const inviteCode = ref('')
const bindingLoading = ref(false)
async function bindUser() {
  bindingLoading.value = true
  if (!inviteCode.value) {
    bindingLoading.value = true
    return $message.warning('推薦碼不得為空！')
  }
  let regex = /[A-Z0-9]{10}/g
  if (!regex.test(inviteCode.value)) {
    bindingLoading.value = true
    return $message.warning('推薦碼為十碼的大寫英文或數字組合，請輸入正確格式！')
  }
  const res = await LoginStore.bindUser(inviteCode.value)
  if (res.state === 200) $message.success('已成功使用邀請碼，優惠券已派發至您的帳號')
  if (res.state === 500) $message.error('無法與自己綁定')
  else $message('找不到擁有此推薦碼的用戶，請再次確認推薦碼是否正確')
  bindingLoading.value = false
}

const showVoucherModal = ref(false)
const toggleShowVoucherModal = (bool=true) => showVoucherModal.value = bool

const onCopySuccess = () => $message.success('已複製邀請碼')
</script>

<template>
  <div class="view-member-info">
    <header v-if="LoginStore.currentUser">
      <div class="member-state">
        <div>
          <icon icon="file-person-fill" />
          <h2>{{ LoginStore.currentUser.user_name}}</h2>
          <span :class="{ tag: true, original: LoginStore.currentUser.user_group === 'ORIGINAL' }">
            {{ LoginStore.currentUser.user_group === 'ORIGINAL' ? '創始會員' : 'CF 好友'}}
          </span>
        </div>
        <p>上次登入 {{ LoginStore.currentUser.last_login_at }}</p>
      </div>

      <div class="email-and-password" v-if="LoginStore.user_login_type === 'EMAIL'">
        <div>
          <p>電子信箱：{{ LoginStore.currentUser.user_email }}</p>
          <p>密碼：****************</p>
        </div>
        <div><button @click="changePassword">變更密碼</button></div>
      </div>

      <div class="social-account">
        <p>登入方式：</p>
        <div v-if="LoginStore.currentUser.user_login_type==='LINE'" class="line">
          <icon icon="line" />
          <p>已連結 Line 社群帳號</p>
        </div>
        <div v-if="LoginStore.currentUser.user_login_type==='FACEBOOK'" class="fb">
          <icon icon="facebook" />
          <p>已連結 FB 社群帳號</p>
        </div>
      </div>

      <div class="voucher">
        <icon icon="coin"></icon>
        <h3>購物金帳戶：${{ LoginStore.activeVoucherAmount }}</h3>
        <button @click="toggleShowVoucherModal">購物金明細</button>
      </div>
    </header>

    <main>
      <section class="wrapper binding">
        <header><icon icon="person-fill-check"/>好友推薦碼綁定</header>
        <div class="my-invite-code">
          <h4>我的推薦碼：{{ LoginStore.currentUser?.user_invite_code }}</h4>
          <button
            v-clipboard:copy="LoginStore.currentUser?.user_invite_code"
            v-clipboard:success="onCopySuccess"
          ><icon icon="clipboard-check-fill" /><p>複製推薦碼</p></button>
        </div>
        <div v-if="!LoginStore.currentUser?.user_binding" class="input">
          <cf-input placeholder="推薦碼" extra="使用推薦碼即可獲得 $100 元折價券" v-model="inviteCode"></cf-input>
          <button
            :class="{ 'loading-button': true, loading: bindingLoading }"
            @click.prevent="bindUser"
          >使用推薦碼</button>
        </div>
        <div v-else class="already-bind"><Icon icon="check-circle-fill" />已綁定推薦碼</div>
      </section>
      
      <section class="wrapper">
        <header>
          <icon icon="pencil-square" />
          編輯會員資料
        </header>
        <section v-if="LoginStore.currentUser">
          <div>
            <header>個人資料</header>
            <form>
              <cf-input v-model="userInfo.user_name" placeholder="姓名 (須與身分證相符)"></cf-input>
              <cf-input v-model="userInfo.user_phone" placeholder="電話號碼" type="phone" addonBefore="+886"></cf-input>
              <!-- <cf-input v-model="userInfo.user_birthday" placeholder="西元年份/月份/日期" label="生日" fixLabel></cf-input> -->
              <BirthdayInputGroup :birthday="userInfo.user_birthday" />
              <cf-select v-model="userInfo.user_gender" label="性別" :options="[{ value: 'MALE', text: '生理男'}, { value: 'FEMALE', text: '生理女' }, { value: 'OTHERS', text: '其他' }]">
              </cf-select>
            </form>
          </div>
          <div>
            <header>預設出貨資料</header>
            <form>
              <cf-input v-model="userInfo.user_city" placeholder="城市"></cf-input>
              <cf-input v-model="userInfo.user_urban_area" placeholder="鄉鎮市區"></cf-input>
              <cf-input v-model="userInfo.user_address" placeholder="詳細地址"></cf-input>
            </form>
          </div>
        </section>
        <div class="button-group">
          <button @click="injectUserStateIntoForm" class="cancel">取消</button>
          <button class="save" @click="updateNewUserInfo">儲存</button>
        </div>
      </section>
    </main>

    <section v-if="showVoucherModal" class="voucher-detail-modal">
      <div class="wrapper">
        <header>
          <div class="title">
            <icon icon="coin" />
            <span>
              <h2>購物金帳戶明細</h2>
              <b>可用總額：{{ LoginStore.activeVoucherAmount }}</b>
            </span>
          </div>
          <div class="close">
            <div v-if="false" class="explain">
              <icon icon="info-circle" />
              <p>什麼是購物金</p>
            </div>
            <span @click="toggleShowVoucherModal(false)"><icon icon="x-square-fill" /></span>
          </div>
        </header>
        <main>
          <ul>
            <li>
              <p>類型</p>
              <p>金額</p>
              <p>餘額</p>
              <p>派發時間</p>
              <p>有效期限</p>
            </li>
            <li
              v-for="voucher in LoginStore.voucherList"
              :key="`voucher_${voucher.voucher_id}`"
              :class="{ 
                out: voucher.voucher_value - voucher.voucher_used ===0,
                expire: voucher.voucher_create_at + voucher.voucher_period < moment().unix()
              }"
            >
              <p>{{ VOUCHER_TYPE_DICT[voucher.voucher_type] }}</p>
              <p>${{ voucher.voucher_value }}</p>
              <p>${{ voucher.voucher_value - voucher.voucher_used }}</p>
              <p>{{ timestampParse(voucher.voucher_create_at).format('YYYY/MM/DD') }}</p>
              <p>{{ timestampParse(voucher.voucher_create_at + voucher.voucher_period).format('YYYY/MM/DD')  }}</p>
            </li>
            <li v-for="e in Array(7-LoginStore.voucherList.length<0 ? 2 : 10-LoginStore.voucherList.length).fill()" :key="e"></li>
          </ul>
        </main>
      </div>
    </section>
  </div>
</template>
