import { defineStore } from "pinia";
import { timestampParse } from '@/utils'
import { useLoginStore } from './login'
import database from '@/utils/database'
import { getLocal } from "../utils";
import moment from 'moment'
import { couponConditionJudgment } from './utils'

export const useCouponStore = defineStore({
  id: 'Coupon',
  state: () => ({
    couponList: []
  }),
  getters: {
    couponDict() {
      if (this.couponList.length) {
        return this.couponList.reduce((acc, c) => ({
          ...acc,
          [c.coupon_uid]: ({ ...c })
        }), {})
      }
      return {}
    },
    couponIdDict() {
      if (this.couponList.length) {
        return this.couponList.reduce((acc, c) => ({
          ...acc,
          [c.coupon_id]: ({ ...c })
        }), {})
      }
      return {}
    },
    memberCoupons() {
      return this.couponList.length ? this.couponList.filter(c => c.coupon_scope === 'MEMBER') : []
    }
  },
  actions: {
    async queryCoupon() {
      const LoginStore = useLoginStore()
      console.log(LoginStore.currentUser);
      let newCouponList = []
      if (LoginStore.ownCouponIds) {
        const res = await database.table('coupon')
          .where('coupon_id', 'in', LoginStore.ownCouponIds)
          .get()
        newCouponList = res.data.map(e => {
          const conponDistributed = LoginStore.currentUser.user_own_coupons.find(c => c.coupon_id === e.coupon_id)
          const couponExp = conponDistributed ? parseInt(conponDistributed.coupon_get_at) + parseInt(e.coupon_period) : undefined
          return {
            ...e,
            coupon_product: e.coupon_product ? e.coupon_product.split(',') : null,
            coupon_giveaway: e.coupon_giveaway ? e.coupon_giveaway.split(',') : null,
            coupon_exp: e.coupon_exp || couponExp,
            coupon_condition: e.coupon_condition ? JSON.parse(e.coupon_condition) : null,
          }
        })
      }
      const localCoupon = getLocal('couponUsed') || []
      this.couponList = [...newCouponList, ...localCoupon]
    },
    async getSystemCoupons() { //自動下載帶有條件且未到期的系統型優惠券
      const SystemCouponResult = await database.table('coupon')
        .where('coupon_scope', '=', 'SYSTEM')
        .where('coupon_condition', 'IS NOT NULL')
        .get()
      if (SystemCouponResult.data.length) {
        const coupons = SystemCouponResult.data.filter(c =>!c.coupon_exp || c.coupon_exp > moment().unix()).map(e => {
          e.coupon_condition = JSON.parse(e.coupon_condition)
          let invalidCondition = couponConditionJudgment(e, this.useStore)
          return {
            ...e,
            coupon_product: e.coupon_product ? e.coupon_product.split(',') : null,
            coupon_giveaway: e.coupon_giveaway ? e.coupon_giveaway.split(',') : null,
            isInvalid: invalidCondition && invalidCondition.length ? true : false
          }
        })
        console.log(coupons);
        return coupons
      }
      return []
    },
    async getCouponeWithUid(uid, scope="ALL") {
      const LoginStore = useLoginStore()
      const userCouponsUsed = LoginStore.currentUser?.user_coupons_used || []
      // const result = this.couponList.filter(c => c.coupon_uid === uid)
      // if (result.length) {
      //   return { state: 200, coupon: result[0] }
      // }
      const publicCouponResult = await database.table('coupon')
        .where('coupon_uid', '=', uid)
        .where('coupon_scope', '=', scope)
        .get()
      if (publicCouponResult.data.length) {
        const coupon = publicCouponResult.data.map(e => ({
          ...e,
          coupon_product: e.coupon_product ? e.coupon_product.split(',') : null,
          coupon_giveaway: e.coupon_giveaway ? e.coupon_giveaway.split(',') : null,
          coupon_condition: e.coupon_condition ? JSON.parse(e.coupon_condition) : null,
        }))[0]

        if (coupon.coupon_condition) {
          const invalidCondition = couponConditionJudgment(coupon, this.useStore)
          if (invalidCondition && invalidCondition.length) return { state: 503, coupon, message: invalidCondition[0] }
        }
        if (coupon.coupon_quantity !== null && coupon.coupon_quantity <= 0) return { state: 500, coupon }
        if ((coupon.coupon_start && coupon.coupon_start > moment().unix()) || (coupon.coupon_exp && coupon.coupon_exp < moment().unix())) return { state: 501 }
        if (coupon.coupon_max_usage && userCouponsUsed.filter(id => id === coupon.coupon_id).length >= coupon.coupon_max_usage) return { state: 502 }
        this.couponList.push(coupon)
        return { state: 200, coupon}
      }
      
      return { state: 400 }
    },
    async usePublicCoupon(idArray) {
      await database.table('coupon')
        .where('coupon_id', 'in', idArray)
        .update({ coupon_quantity: database.FieldValue.increment(-1) })
    }
  }
})
