<script setup>
import { computed, onMounted, ref, useSlots } from 'vue'
const Props = defineProps({
  modelValue: String,
  theme: String,
  label: String,
  placeholder: String,
  spread: {
    type: Boolean,
      default: false
  },
  options: {
    type: Array,
    default: () => []
  },
  block: Boolean,
  required: Boolean,
  searchable: Boolean,
  hideLabel: Boolean,
  hideRequiredMark: Boolean
})

const $slots = useSlots()
const isSpread = ref(false)
onMounted(() => {
  isSpread.value = Props.spread
})

const classes = computed(() => ({
  'k-select': true,
  'isSpread': isSpread.value,
  [`k--${Props.theme}`]: !!Props.theme,
  'block': !!Props.block
}))
const currentValue = computed(() => {
  const filterVal = Props.options.filter(o => o.value === Props.modelValue)
  if (filterVal.length) {
    return filterVal[0].text
  }
  return ''
})

const $emit = defineEmits(['update:modelValue', 'change'])
function toggleSpread() {
  isSpread.value = !isSpread.value
}
function unSpread() {
  isSpread.value = false
}
function handleSelect(e) {
  $emit('update:modelValue', e.target.value)
  $emit('change', e.target.value)
  isSpread.value = false
}
</script>

<template>
  <label :class="{ 'k-select-wraper': true }" for="select1">
    <div class="label" v-if="Props.label && !hideLabel">
      <p>{{ Props.label }}</p>
    </div>
    <input class="select-value" v-if="Props.required" :value="Props.modelValue" required />
    <div :class="classes">
      <div class="k-select-render" @click.stop="toggleSpread">
        <input
          class="k-select-title"
          :placeholder="Props.placeholder || 'Please select an option'"
          :value="currentValue"
          readonly
        />
      </div>
      <transition name="drop-down">
        <ul
          v-if="($slots.default || Props.options.length) && isSpread"
          class="option-list"
          @click.stop="handleSelect"
        >
          <slot></slot>
          <option v-for="item in Props.options" :value="item.value" :key="item.value">{{ item.text }}</option>
        </ul>
      </transition>
    </div>
    <!-- <span class="extra">
      <k-icon icon="exclamation-triangle" theme="danger" />本欄位為必填，請選擇選項
    </span> -->
  </label>
</template>
