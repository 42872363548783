import fieldStyle from './tappayFieldStyle.json'

// const genField = (numberREF, expirationREF, ccvREF) => ({
//   number: {
//     element: numberREF.value,
//     placeholder: '**** **** **** ****'
//   },
//   expirationDate: {
//     element: expirationREF.value,
//     placeholder: 'MM / YY'
//   },
//   ccv: {
//     element: ccvREF.value,
//     placeholder: 'ccv'
//   }
// })

// export function TapPayInit() {
//   TPDirect.setupSDK(122434, 'app_avJEjvXDDafvWH0FiSVhCXyUeZlxKsdScMBB5t0SEh6wLnFckl1ptwU0Sp2J', 'sandbox')
// }

export const CARD_TYPE = {
  visa: 'fab fa-cc-visa',
  jcb: 'fab fa-cc-jcb',
  amex: 'fab fa-cc-amex',
  mastercard: 'fab fa-cc-mastercard',
  unionpay: 'fas  fa-credit-card',
  unknown: 'fas fa-credit-card'
}

const TAPPAY_FIELDS = {
  number: {
    element: '#card-number',
    placeholder: '**** **** **** ****'
  },
  expirationDate: {
    element: '#card-expirationDate',
    placeholder: 'MM / YY'
  },
  ccv: {
    element: '#card-ccv',
    placeholder: 'ccv'
  }
}

export function genTapPayField(fieldsArr) {
  return {
    fields: fieldsArr.reduce((acc, f) => ({
      ...acc,
      [f]: { ...TAPPAY_FIELDS[f] }
    }), {}),
    styles: fieldStyle
  }
}

export function TapPayOnUpdate(type, callback) {
  TPDirect[type].onUpdate(callback)
}

export async function TapPayByPrime(API, formData) {
  try {
    const response = await fetch(API, {
      method: 'POST',
      headers: { "Content-Type": "application/json", "x-api-key": process.env.VUE_APP_TAPPAY_PARTNER_KEY },
      body: JSON.stringify(formData)
    })
    const data = await response.json()
    console.log(data);
    return data
  } catch (error) {
    console.log(error);

  }
}
