<script setup>
import Icon from '@/components/Icon'
import { onMounted } from '@vue/runtime-core';
import { useRouter, useRoute } from 'vue-router';
import { useLoginStore } from '../../store/login';
import { usePaymentStore } from "../../store/payment";

const PaymentStore = usePaymentStore()
const LoginStore = useLoginStore()
const Router = useRouter()
const Route = useRoute()

const { query } = Route

const toOrder = () => {
  if (LoginStore.currentUser) {
    Router.push(`/member/order?order_number=${query.order_number}`)
  } else {
    Router.push(`/search-order?id=${query.order_number}`)
  }
}

function toProducts() {
  Router.push('/product')
}
</script>

<template>
  <div class="view-result">
    <section>
      <icon :icon="query.status == '0' || query.type === 'cod' ? 'bag-check-fill' : 'bag-x'" :style="{ color: query.status != '0'&& query.type !== 'cod' ? '#df7575' : null }" />
      <h2 v-if="query.type !== 'cod'">付款{{ query.status == '0' ? '成功' : '失敗' }}</h2>
      <h2 v-else>已建立訂單</h2>
      <span>訂單編號：{{ query.order_number || '-' }}</span>
      <p v-if="query.type === 'cod'">我們已收到您的訂單，我們將儘速為您安排出貨</p>
      <p v-else>{{  query.status == '0' ? '我們已收到您的訂單與付款，我們將儘速為您安排出貨' : '交易失敗，銀行端出現問題，請重新進行下單'}}</p>
      <button v-if="query.type === 'cod' || query.status == '0'" @click="toOrder">查看訂單</button>
      <button v-else @click="toProducts">回商品頁</button>
    </section>
  </div>
</template>
