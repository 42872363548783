<script setup>
import Icon from '@/components/Icon'
import Clipboard from 'clipboard'
import { useCouponStore } from '../../store/coupon'
import { timestampParse } from '@/utils'
import { useLoginStore } from '../../store/login'

const CouponStore = useCouponStore()
const LoginStore = useLoginStore()
const calculateExp = (coupon, start) => {
  if (coupon.coupon_period) return timestampParse(parseInt(start)+parseInt(coupon.coupon_period))
  if (coupon.coupon_exp) return timestampParse(coupon.coupon_exp)
  return null
}
</script>

<template>
  <div class="view-member-coupon">
    <header>
      <ul>
        <!-- <li>即將開始</li> -->
        <li v-if="CouponStore.couponList.length" :class="{ active: true }">可使用優惠券</li>
        <li v-else>目前無任何優惠券</li>
        <!-- <li>已失效</li> -->
      </ul>
    </header>
    <main v-if="LoginStore.currentUser && CouponStore.couponList.length">
      <div class="coupon" v-for="coupon in LoginStore.currentUser.user_own_coupons" :key="`coupon_${coupon.coupon_id}`">
        <div class="content">
          <h2>{{ CouponStore.couponIdDict[coupon.coupon_id].coupon_name }}</h2>
          <p>有效期限 {{ calculateExp(CouponStore.couponIdDict[coupon.coupon_id], coupon.coupon_get_at)?.format('YYYY/MM/DD') || '- 無期限' }}</p>
          <span>{{ CouponStore.couponIdDict[coupon.coupon_id].coupon_desc }}</span>
        </div>
        <div class="icon">
          <icon icon="ticket-perforated-fill" />
        </div>
      </div>
    </main>
  </div>
</template>
