<script setup>
import ProductExploreBabnner from '@/assets/img/productExploreBabnner.webp'
import CVS_FAMI_WEBP from '@/assets/img/CVS_FAMI.webp'
import CVS_UNIMART_WEBP from '@/assets/img/CVS_UNIMART.webp'
import { onMounted, ref, inject, nextTick, reactive, computed } from "vue";
import { useGroupStore } from "@/store/group";
import { useRoute, useRouter } from "vue-router";
import { checkEmail, checkPhoneNumber, getLocal, saveToLocal, getElementPositionYWithLoop, fetchApi, timestampParse, removeFromLocal } from '@/utils'
import Icon from '@/components/Icon'
import moment from 'moment'
import { useProductStore } from '../../store/product';
import { Swiper, SwiperSlide } from 'swiper/vue'

const Route = useRoute()
const Router = useRouter()
const ProductStore = useProductStore()
const GroupStore = useGroupStore()
const focus_group_project = ref()
const CVSInfo = ref({})
const userInfo = reactive({ name: '', phone: '', email: '' })
const productQuantity = ref(1)
const ScreenState = inject('ScreenProvider')
const $message = inject('$message')
const PageStep = ref('BUY')
const CART = ref(null)
const cartItems = reactive({})

onMounted(async () => {
  await GroupStore.queryGroupProjects()
  if (Route.params.address && GroupStore.group_project_list.map(p => p.group_project_address).includes(Route.params.address) ) {
    console.log(GroupStore.group_project_list);
    focus_group_project.value =  GroupStore.group_project_list.filter(p => p.group_project_address === Route.params.address)[0]
    const localCart = getLocal(`cf:group_project_${focus_group_project.value.group_project_id}_cart`)
    if (localCart) Object.keys(localCart).forEach(id => cartItems[id] = localCart[id]);
  }
  else {
    Router.replace('/404')
  }

  if (Route.query.fromcvs === '1') {
    const { CVSStoreID, CVSStoreName, CVSAddress, LogisticsSubType } = Route.query
    CVSInfo.value = {
      CVSStoreID, CVSStoreName, CVSAddress, LogisticsSubType
    }
    const positionY = getElementPositionYWithLoop(CART.value)
    window.scrollTo({ top: ScreenState.mode === "MOBILE" ? positionY - 52  : positionY - 112, behavior: 'smooth' })
  }
});

function scrollToCart() {
  const positionY = getElementPositionYWithLoop(CART.value)
  window.scrollTo({ top: ScreenState.mode === "MOBILE" ? positionY - 52  : positionY - 112, behavior: 'smooth' })
}

async function handleQuantityChange(product_id, bool) {
  if (bool) {
    if (cartItems[product_id]) cartItems[product_id]++
    else cartItems[product_id] = 1
  } else {
    if (cartItems[product_id] && cartItems[product_id]-1 >=0) cartItems[product_id]--
  }
  saveToLocal(`cf:group_project_${focus_group_project.value.group_project_id}_cart`, cartItems)
}

const ecpayMapREF = ref(null)
const ecpayMapHtml = ref()
async function handleChooseCVS(LogisticsSubType) {
  ecpayMapHtml.value = true
  await nextTick()
  console.log(ecpayMapREF.value);
  const form = ecpayMapREF.value.getElementsByTagName('form')[0]
  form.setAttribute('action', 'https://logistics.ecpay.com.tw/Express/map') //上線前修改成正式環境
  console.log(window.location.hostname);
  form.innerHTML = `
    <input name="ServerReplyURL" value="https://${window.location.hostname}/api/map-back-group/">
    <input name="MerchantID" value="${process.env.VUE_APP_ECPAY_LOGISTICS_C2C_MID}">
    <input name="IsCollection" value="Y">
    <input name="LogisticsType" value="CVS">
    <input name="LogisticsSubType" value="${LogisticsSubType}">
    <input name="Device" value="${ScreenState.mode === 'MOBILE' ? '1' : '0'}">
    <input name="ExtraData" value="${Route.params.address}">
  `//上線前修改成正常網域
  console.log(form);
  form.submit()
}

const groupProjectProductDict = computed(() => {
  if (focus_group_project.value) return focus_group_project.value.group_project_products.reduce((acc, p) => ({ ...acc, [p.product_id]: { ...p } }), {})
  else return {}
})
const sumOfCart = computed(() => Object.keys(cartItems).reduce((acc, id) => {
  return acc + parseInt(groupProjectProductDict.value[id].price) * cartItems[id]
}, 0))
const loading = ref(false)
async function handleOrderSubmit() {
  if (!sumOfCart.value) return $message.warning('未選購任何團購商品')
  if (!userInfo.name) return $message.warning('請輸入姓名')
  if (!checkEmail(userInfo.email)) return $message.warning('Email格式錯誤')
  if (!checkPhoneNumber(userInfo.phone)) return $message.warning('手機號碼格式錯誤')
  if (!CVSInfo.value.CVSStoreID) return $message.warning('請選擇門市')
  loading.value = true
  const orderUidRes = await fetchApi('/api/genUid/', {});
  if (orderUidRes.id) {
    console.log(CVSInfo);
    console.log(CVSInfo.value.LogisticsSubType);
    const logisticRes = await fetchApi('/api/ecpay/', { api:'/Express/Create', shippingInfo: {
      MerchantTradeDate: moment().format('yyyy/MM/DD HH:mm:ss'),
      LogisticsType: 'CVS',
      LogisticsSubType: CVSInfo.value.LogisticsSubType,
      ReceiverStoreID: CVSInfo.value.CVSStoreID,
      ReturnStoreID: '177418', //7-11 指定退貨門市為新北大門市
      IsCollection: 'Y',
      GoodsAmount:  sumOfCart.value > 999 ? sumOfCart.value : sumOfCart.value + 70,
      GoodsName: '保養品',
      SenderName: '顏爾佑',
      SenderCellPhone: '0988879595',
      ReceiverName: userInfo.name,
      ReceiverCellPhone: userInfo.phone,
      ReceiverEmail: userInfo.email,
      TradeDesc: 'Chris Farrell Taiwan 保養品',
      ServerReplyURL: 'https://api.chris-farrell.com.tw/index.php/order/ecpay'
    }})
    console.log(logisticRes);
    if (logisticRes.state === '1') {
      const orderRes = await GroupStore.createGroupOrder({
        group_project_id: focus_group_project.value.group_project_id,
        group_order_uid: orderUidRes.id,
        group_order_email: userInfo.email,
        group_order_name: userInfo.name,
        group_order_phone: userInfo.phone,
        group_order_amount: sumOfCart.value > 999 ? sumOfCart.value : sumOfCart.value + 70,
        group_order_product: JSON.stringify([...Object.entries(cartItems)
          .map(([id, product_quantity]) => ({ product_id: id, product_quantity }))]
          .filter(({ product_quantity }) => product_quantity > 0)
        ),
        group_order_logistics_id: logisticRes.data.MerchantTradeNo,
        group_order_logistics_info: JSON.stringify(logisticRes.data)
      })
      console.log(orderRes);
      PageStep.value = 'RESULT'
      $message.success('已成功建立訂單')
      removeFromLocal(`cf:group_project_${focus_group_project.value.group_project_id}_cart`)
    } else {
      $message.error('物流系統出現問題，請聯繫客服')
      sendError(
        'ORDER_LOGISTICS',
        'https://logistics-stage.ecpay.com.tw/Express/Create',
        JSON.stringify([PaymentStore.shippingInfo, PaymentStore.cvsInfo]),
        JSON.stringify(logisticRes)
      )
    }
  } else {
    $message.error('訂單生成系統出現問題，請聯繫客服')
    sendError(
      'ORDER_UID',
      '/api/genUid/',
      JSON.stringify(orderUidRes)
    )
  }
  loading.value = false
}

function toProducts() {
  Router.push('/product')
}

const renderGroupProjectTitle = computed(() => {
  if (focus_group_project.value && ProductStore.productList.length) {
    return focus_group_project.value.group_project_products
      .filter(p => parseInt(p.price) > 0)
      .map(p => ProductStore.productDict[p.product_id].product_name_zh).join('、')
  }
  return ''
})

const SwiperContoller = ref(null)
function setSwiper(swiper) {
  SwiperContoller.value = swiper
}

function getProductWithId(id) {
  return ProductStore.productList.length ? ProductStore.productDict[id] : {}
}
</script>

<template>
  <div class="view-group view-product" v-if="GroupStore.group_project_list && GroupStore.group_project_list.length && ProductStore.productList.length">
    <header  v-if="focus_group_project" class="hero" :style="{ backgroundImage: `url(${ProductExploreBabnner})` }">
      <div class="mask">
        <div class="content">
          <h2>{{ renderGroupProjectTitle }}</h2>
          <h1>
            {{ focus_group_project.group_project_name }}
            {{ timestampParse(focus_group_project.group_project_start).unix() > moment().unix() ? '[尚未開始]' : '' }}
          </h1>
          <p
            v-if="focus_group_project && timestampParse(focus_group_project.group_project_end).unix() > moment().unix()"
          >活動期間：{{timestampParse(focus_group_project.group_project_start).format('YYYY/MM/DD')}} ~ {{timestampParse(focus_group_project.group_project_end).format('YYYY/MM/DD')}}</p>
          <p v-else>活動已截止</p>
        </div>
      </div>
      <div class="desc">
        <p v-if="focus_group_project && timestampParse(focus_group_project.group_project_end).unix() > moment().unix()">
          本活動期限至 {{ timestampParse(focus_group_project.group_project_end).format('YYYY/MM/DD') }} 晚上12:00 截止，未滿 $1000 須額外負擔運費 NT$70
        </p>
        <p v-else>本活動已於 {{ timestampParse(focus_group_project.group_project_end).format('YYYY/MM/DD') }} 截止</p>
      </div>
    </header>

    <main v-if="focus_group_project && timestampParse(focus_group_project.group_project_end).unix() > moment().unix()">
      <header>
        <h3>團購商品</h3>
      </header>
      <section class="products">
        <div class="product">
          <Swiper
            @swiper="swiper => setSwiper(swiper)"
            :slides-per-view="ScreenState.mode === 'COMPUTER' ? 2 : 1"
            :space-between="ScreenState.mode === 'COMPUTER' ? 40 : 48"
            :loop="true"
          >
            <swiper-slide
              v-for="{ product_id, price } in focus_group_project.group_project_products"
              :key="`group-product-${focus_group_project.group_project_id}-${product_id}`"
            >
              <div class="image-wrapper">
                <img :src="`/img/product/${ProductStore.productDict[product_id].product_uid}/avatar.webp`" />
              </div>
              <div class="content">
                <h2>{{ getProductWithId(product_id).product_name_zh }}</h2>
                <h3>{{ getProductWithId(product_id).product_sub_title }}</h3>
                <p>{{ getProductWithId(product_id).product_summary }}</p>
                <p class="price">
                  <span class="member-price">${{ getProductWithId(product_id).product_price.ORIGIN }}</span>
                  <span>${{ price }}</span>
                  <p v-if="price">團購價</p>
                  <p v-else>隨單附贈</p>
                </p>
                <a
                  v-if="price"
                  :href="`/product/${getProductWithId(product_id).product_name_en.replace(/\s/g, '-').toLowerCase()}`"
                  target="_blank">
                  <button>產品詳情</button>
                </a>
              </div>
            </swiper-slide>
          </swiper>
          <div class="controller">
            <button class="prev" @click="SwiperContoller.slidePrev()"><icon icon="arrow-left-short"/></button>
            <button class="next" @click="SwiperContoller.slideNext()"><icon icon="arrow-right-short"/></button>
          </div>
        </div>
      </section>
    
      <section ref="CART" class="action" v-if="PageStep === 'BUY' && focus_group_project && timestampParse(focus_group_project.group_project_start).unix() < moment().unix()">
        <div class="cart-wrapper">
          <h2>團購購物車</h2>
          <ul v-if="focus_group_project && focus_group_project.group_project_products.length">
            <li
              v-for="{ product_id, price } in focus_group_project.group_project_products"
              :key="`group-cart-${focus_group_project.group_project_id}-${product_id}`"
            >
              <img :src="`/img/product/${getProductWithId(product_id).product_uid}/avatar.webp`">
              <div class="info">
                <h4>{{ getProductWithId(product_id).product_name_en }}</h4>
                <h3>{{ getProductWithId(product_id).product_name_zh}}</h3>
                <p v-if="getProductWithId(product_id).product_size[0]">
                  {{ getProductWithId(product_id).product_size[0] }}ml * {{ cartItems[product_id] || 0 }}
                </p>
              </div>

              <div class="quantity" v-if="price">
                <button @click="handleQuantityChange(product_id, false)"><icon icon="dash" /></button>
                <span>{{ cartItems[product_id] || 0 }}</span>
                <button @click="handleQuantityChange(product_id, true)"><icon icon="plus" /></button>
              </div>
              <div v-else :style="{paddingLeft: ScreenState.mode === 'COMPUTER' ? '60px' : 0}">1</div>

              <div v-if="price" class="price">
                <span>{{ cartItems[product_id] || 0 }} x NT${{ price }}</span>
                <p>NT${{ (cartItems[product_id] || 0) * price }}</p>
              </div>
              <div v-else class="price"><p>贈</p></div>
            </li>
          </ul>
        </div>

        <div class="shipping">
          <h2>出貨資訊</h2>
          <div class="form">
            <header>
              <div class="circle"></div>
              <h3>超商取貨付款</h3>
              <div class="button-group">
                <button @click="handleChooseCVS('UNIMARTC2C')"><img :src="CVS_UNIMART_WEBP">選擇 7-11 門市</button>
                <button @click="handleChooseCVS('FAMIC2C')"><img :src="CVS_FAMI_WEBP">選擇全家門市</button>
              </div>
            </header>
            <main>
              <input type="text" v-model="userInfo.name" placeholder="收件人姓名">
              <input type="text" v-model="userInfo.phone" placeholder="手機號碼">
              <input type="text" v-model="userInfo.email" placeholder="電子郵件">
              <input
                type="text"
                :value="CVSInfo.LogisticsSubType ? `${CVSInfo.LogisticsSubType === 'UNIMARTC2C' ? '7-11' : '全家'} - ${CVSInfo.CVSStoreName}` : ''"
                placeholder="門市名稱" readonly
              >
              <input type="text" v-model="CVSInfo.CVSAddress" placeholder="未選擇門市地址" readonly
              >
              <span class="extra"></span>
            </main>
            <footer>
              <h3>總結帳金額：NT${{ sumOfCart }} {{ sumOfCart > 999 ? '' : `+ 運費 NT$70 = NT$${sumOfCart + 70}` }}</h3>
              <button :class="{ 'loading-button': true, 'loading': loading || null }" @click="handleOrderSubmit">送出訂單</button>
            </footer>
          </div>
        </div>
      </section>
      <div v-if="PageStep === 'RESULT'" class="view-result">
        <section>
          <icon icon="bag-check-fill"/>
          <h2>已建立團購訂單</h2>
          <p>我們已收到您的訂單，我們將儘速為您安排出貨</p>
          <button @click="toProducts">逛逛其他商品</button>
        </section>
      </div>
    </main>
  </div>
  <div v-else>
    <p>NONE</p>
  </div>
  <div class="ecpay-map-modal" v-if="ecpayMapHtml">
      <main>
        <section ref="ecpayMapREF" class="map">
          <form action="" method="POST"></form>
        </section>
      </main>
    </div>
</template>
