<script setup>
import { useRouter } from "vue-router";
import CfConfirm from '@/components/Message/Confirm'
import { ref } from 'vue'

const router = useRouter()
const showConfirm = ref(false)
</script>

<template>
  <div class="view-not-found">
    <h1>404</h1>
    <p>我們很抱歉！您所查詢的頁面不存在。</p>
    <!-- <button @click="router.push('/')">回到首頁</button> -->
    <button @click="() => showConfirm = true">回到首頁</button>
    <cf-confirm v-model="showConfirm" @confirm="router.push('/')">
      <p>請問確定要回去嗎？</p>
    </cf-confirm>
  </div>
</template>
