<script setup>
import { CfRadio } from "@/components/Form"
import { computed, ref, watch } from "vue"
const Props = defineProps({ field: Object })
const Emits = defineEmits(['answer'])
const answer = ref('')

watch(answer, (newVal) => {
  console.log(newVal);
  Emits('answer', answer.value)
})
</script>

<template>
  <section class="cell linear-scale">
    <cf-radio
      v-for="scale, index in Props.field.scales"
      :key="`${Props.field.key}-scale-${index}`"
      :label="scale"
      :value="scale"
      v-model="answer"
    />
  </section>
</template>
