<script setup>
import { ref, reactive, inject, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useOrderStore } from "../store/order";
import { CfInput } from '@/components/Form'
import { timestampParse } from '@/utils'
import LOGISTIC_STATE from '@/utils/ecpayLogisticsState.json'
import { useProductStore } from "../store/product";

const route = useRoute()
const ProductStore = useProductStore()
const OrderStore = useOrderStore()
const $message = inject('$message')
const ordersId = ref('')
const loading = ref(false)
const result = reactive({
  state: null,
  order: null
})

onMounted(async () => {
  if (route.query.id) {
    ordersId.value = route.query.id
    await handleSearch(route.query.id)
  }
})

async function handleSearch() {
  loading.value = true
  const res = await OrderStore.searchOrder(ordersId.value.replace(/^\s+|\s+$/g, ''))
  if (!res) {
    result.state = false
    $message.warning('查詢不到此編號的訂單，請確認訂單編號是否正確')
    loading.value = false
    return
  }
  result.state = true
  result.order = res
  loading.value = false
}

function searchPackageWithNote(order) {
  switch (order.order_logistics_subtype) {
    case 'TCAT':
      window.open(`https://www.t-cat.com.tw/Inquire/Trace.aspx?no=${order.order_logistics_info.BookingNote}`, '_blank').focus()
      break;
    case 'UNIMARTC2C':
      window.open('https://eservice.7-11.com.tw/e-tracking/search.aspx', '_blank').focus()
      break;
    case 'FAMIC2C':
      window.open('https://ecfme.fme.com.tw/fmedcfpwebv2/index.aspx', '_blank').focus()
    default:
      break;
  }
}

function renderLogisticsState(order) {
  const logisticsSubtype = order.order_logistics_subtype
  
  if (order.order_logistics_state.length) {
    const stateCode = order.order_logistics_state[order.order_logistics_state.length - 1].code
    return LOGISTIC_STATE[logisticsSubtype][stateCode]
  }
  return LOGISTIC_STATE[logisticsSubtype]['300']
}

const showReturnSection = ref(false)
const showConfirmReturnSubmit = ref(false)
const returnComment = ref('')
function toggleShowReturn(bool) {
  showReturnSection.value = bool
  if (!bool) returnComment.value = ''
}
async function returnApplySubmit() {
  if (returnComment.value) {
    const res = await OrderStore.returnApply(result.order.orders_id, returnComment.value)
    if (res.status === 200) {
      $message.success('我們已收到您的退貨申請，在經審核後訂單將會進入退貨程序')
      showReturnSection.value = false
    }
  } else {
    $message.warning('未填寫退貨原因')
  }
}
</script>

<template>
  <div class="view-search-order-container">
    <header>
      <h1>線上訂單查詢</h1>
      <p>輸入訂單編號以查詢訂單內容與物流狀態</p>
      <div>
        <cf-input placeholder="輸入訂單編號" v-model="ordersId" hideLabel />
        <button @click="handleSearch" :disabled="!ordersId || loading">查詢</button>
      </div>
    </header>

    <section class="order-modal" v-if="result.state">
      <div class="order-modal-wrapper">
        <div class="order-modal-scroll">
          <header>
            <div class="order-header">
              <h1>訂單編號 {{ result.order.orders_id }}</h1>

              <div class="order-action">
                <!-- <button
                  v-if="result.order.order_payment_status === 'SUCCESS'
                    && result.order.order_type !== 'RETURN_APPLY'"
                  @click="toggleShowReturn(true)"
                >退貨申請</button> -->
                <button @click="searchPackageWithNote(result.order)">包裹查詢</button>
              </div>
            </div>

            <Transition name="order-return-collapse">
              <div v-if="showReturnSection" class="order-return">
                <h3>退貨申請</h3>
                <cf-input placeholder="退貨原因 (必填)" v-model="returnComment" required></cf-input>
                <div class="button-group">
                  <button @click="toggleShowReturn(false)">取消</button>
                  <button @click="showConfirmReturnSubmit = true">送出</button>
                </div>
                <cf-confirm
                  content="確定要送出退貨申請嗎？"
                  v-model="showConfirmReturnSubmit"
                  @cancel="showConfirmReturnSubmit = false"
                  @confirm="returnApplySubmit"
                />
              </div>
            </Transition>

            <div class="order-status">
              <h3>訂單資訊</h3>
              <ul class="state">
                <li>訂單編號</li>
                <li>{{ result.order.orders_id }}</li>
                <li>下訂時間</li>
                <li>{{ timestampParse(result.order.order_create_at).format('YYYY/MM/DD') }}</li>
                <li>付款方式</li>
                <li v-if="result.order.order_pay_type === 'CREDIT'">信用卡</li>
                <li v-else>貨到付款</li>
                <li>訂單狀態</li>
                <li v-if="result.order.order_payment_status === 'HANDLING' && result.order.order_pay_type === 'CREDIT'">
                  待3D驗證
                  <!-- <button @click="toThreeDomin">點我進行 3D 認證</button> -->
                </li>
                <li v-else>{{renderLogisticsState(result.order)}}</li>
                <li>訂單總額</li>
                <li>${{ result.order.order_amount }}</li>
              </ul>

              <div class="logistics-info">
                <h3>出貨資訊</h3>
                <ul>
                  <li>收件人姓名</li>
                  <li>{{ result.order.order_logistics_info.ReceiverName }}</li>
                  <li>收件人電話</li>
                  <li>{{ result.order.order_logistics_info.ReceiverCellPhone }}</li>
                  <template v-if="result.order.order_logistics_type === 'HOME'">
                    <li>收件地址</li>
                    <li>{{ result.order.order_logistics_info.ReceiverAddress }}</li>
                  </template>
                  <template v-else>
                    <li>取貨門市</li>
                    <li>
                      {{ result.order.order_logistics_subtype === 'FAMIC2C' ? 'Family Mart 全家' : '7-11 統一超商' }}
                      {{ result.order.order_logistics_cvs_info.CVSStoreName }}
                    </li>
                    <li>門市地址</li>
                    <li>{{ result.order.order_logistics_cvs_info.CVSAddress }}</li>
                  </template>
                </ul>
              </div>
            </div>
          </header>
          <main>
            <h3>訂單內容</h3>
            <ul v-if="ProductStore.productList.length">
              <li v-for="item in result.order.order_products" :key="`${result.order.orders_id}-${item.product_id}`">
                <div class="img-wrapper">
                  <img
                    :src="`/img/product/${ProductStore.productDict[item.product_id].product_images[0]}`"
                    :alt="ProductStore.productDict[item.product_id].product_images[0]"
                  >
                </div>
                <div class="content">
                  <h4>{{ ProductStore.productDict[item.product_id].product_name_en }}</h4>
                  <h3>{{ ProductStore.productDict[item.product_id].product_name_zh }}</h3>
                  <p>數量: {{ item.product_quantity }}</p>
                  <p>${{ item.product_price * item.product_quantity }}</p>
                </div>
              </li>
            </ul>
            <template v-if="result.order.order_coupon_used.filter(e => e.coupon_type === 'GIVEAWAY').length">
              <h3>贈品</h3>
              <ul class="order_coupon_items">
                <template
                  v-for="coupon, index in result.order.order_coupon_used" :key="`order_${result.order.order_id}_coupon_${index}`"
                >
                  <li v-if="coupon.coupon_type === 'GIVEAWAY'">
                    <header>
                      <span class="coupon-tag">{{ coupon.coupon_name }}</span>
                    </header>
                    <section>
                      <div v-for="item in coupon.coupon_items" :key="`coupon_item_${item.product_uid}`">
                        <div class="img-wrapper">
                          <img :src="`/img/product/${ProductStore.productUidDict[item.product_uid].product_images[0]}`" alt="">
                        </div>
                        
                        <span>
                          <h4>{{ ProductStore.productUidDict[item.product_uid].product_name_en }}</h4>
                          <h4>{{ ProductStore.productUidDict[item.product_uid].product_name_zh }}</h4>
                        </span>
                      </div>
                    </section>
                  </li>
                </template>
              </ul>
            </template>
          </main>
        </div>
      </div>
    </section>
  </div>
</template>
