<script setup>
import { ref, watch } from "vue";
import Icon from "@/components/Icon";

const $props = defineProps({
  file: {
    type: File,
    default: null
  },
  uid: {
    type: String,
    default: ''
  }
})
const $emit = defineEmits(['change'])
const fileEntity = ref(null)
const previewUrl = ref(null)
const fileREF = ref(null)

watch(() => $props.file, (newFile) => {
  if (newFile) {
    fileEntity.value = newFile
    genPreviewUrl(newFile)
  }
}, { immediate: true })

function handleClick() {
  fileREF.value.click()
}

function genPreviewUrl(file) {
  let reader = new FileReader();
  reader.onload = el => previewUrl.value = el.target.result
  reader.readAsDataURL(file);
}

function handleChange($event) {
  const inputFile = $event.target.files[0]
  $emit('change', inputFile, $props.uid)
  fileEntity.value = inputFile
  if (inputFile) genPreviewUrl(inputFile)
  else previewUrl.value = null
}
</script>

<template>
  <div
    class="component-product-image-uploader"
    @click="handleClick"
  >
    <input ref="fileREF" type="file" style="display: none;" @change="handleChange" accept="image/png, image/jpeg, image/heic">
    <div v-if="!$props.file" class="placeholder">
      <icon icon="plus" />
      <p>點擊或將照片拖曳進此區</p>
    </div>
    <div v-else class="preview">
      <img :src="previewUrl">
    </div>
  </div>
</template>
