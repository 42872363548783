import { defineStore } from "pinia";
import { useLoginStore } from './login'
import { timestampParse, fetchApi } from '@/utils'
import database from '@/utils/database'
import { EMAIL_CONTENT_TEMPLATES } from './mock/emailContent'

export const useEmailStore = defineStore({
  id: 'Email',
  state: () => ({
    emailTemplateList: []
  }),
  getters: {
    filterEmailTemplateWithType() {
      if (this.emailTemplateList.length) {
        return this.emailTemplateList.reduce((acc, t) => {
          if (acc[t.email_template_type]) {
            acc[t.email_template_type].push({ ...t })
            return acc
          }
          return { ...acc, [t.email_template_type]: [{ ...t }] }
        }, {})
      }
      return {}
    },
    systemEmailDict() {
      if (this.emailTemplateList.length) {
        const systemFilter = this.emailTemplateList.filter(t => t.email_template_type === 'SYSTEM')
        return systemFilter.reduce((acc, t) => ({ ...acc, [t.email_template_system_id]: { ...t }}), {})
      }
      return {}
    },
    emailTemplateDict() {
      if (this.emailTemplateList.length) {
        return this.emailTemplateList.reduce((acc, t) => ({ ...acc, [t.email_template_id]: { ...t }}), {})
      }
      return {}
    }
  },
  actions: {
    async queryEmailTemplates() {
      const res = await database.table('email_template').get()
      if (res.state === 200) {
        this.emailTemplateList = res.data.map(template => ({
          ...template,
          email_template_schema: JSON.parse(template.email_template_schema)
        }))
      }
    },
    async searchEmails() {
      return void8
    },
    async sendEmail({ subject, contentId, content }, recipients) {
      const res = await fetchApi('/api/surenotify/send/', {
        data: { subject, content: contentId? this.emailTemplateDict[contentId].email_template_content : content },
        recipients: recipients
      })
      console.log(res);
      return res
    } 
  }
})
