import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import "@/assets/scss/index.scss"
import "swiper/swiper.scss"
import smoothscroll from 'smoothscroll-polyfill';
import { VueClipboard } from '@soerenmartius/vue3-clipboard'

function RootStorePlugin(context) {
  return {
    useStore: context.pinia._s
  }
}
const pinia = createPinia()
pinia.use(RootStorePlugin)
smoothscroll.polyfill();
createApp(App).use(pinia).use(router).use(VueClipboard).mount('#app')
