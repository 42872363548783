<script setup>
import LoadingSpinner from "@/assets/img/loading_spinning.svg"
import CFLogo from "@/assets/img/brand_logo_pc.png"
import { computed, onMounted, ref } from "vue";
import { useCommentStore } from "../../store/comment";
import { useProductStore } from "../../store/product";
import Icon from "@/components/Icon"
import { useRoute, useRouter } from "vue-router";
import { timestampParse } from "../../utils";

const SKIN_TYPE_DICT = {
  dry: '乾肌',
  sensitive: '敏弱肌',
  fragile: '特殊美容',
  oil: '油肌',
  combination: '混合肌',
  rough: '毛孔粗大',
  acne: '痘肌',
  others: '其他'
}
const GENDER_DICT = {
  MALE: '生理男',
  FEMALE: '生理女',
  OTHER: '其他'
}

const ProductStore = useProductStore()
const CommentStore = useCommentStore()
const Router = useRouter()
const Route = useRoute()
const loading = ref()
onMounted(async () => {
  window.scrollTo(0, 0)
  if (!CommentStore.commentList.length) await CommentStore.queryComment()
  loading.value = false
})

const focusComment = computed(() => CommentStore.commentIdDict[Route.params.postId])
const focusCommentImage = ref(null)
function showCommentImage(image) {
  focusCommentImage.value = image
}
function hideCommentImage() {
  focusCommentImage.value = null
}
</script>

<template>
  <Transition name="sample-fade-in">
  <div v-if="loading" class="view-event-loading">
    <div class="loading-wrapper">
      <img class="spinner" :src="LoadingSpinner">
      <img class="logo" :src="CFLogo">
    </div>
  </div>
  <div v-else class="view-comments post">
    <header>
      <div>
        <span @click="() => { Router.back() }"><icon icon="arrow-left"/></span>
        <h1 v-if="focusComment">{{ focusComment.comment_title }}</h1>
        <h1 v-else>404 找不到此評論</h1>
        <div class="score" v-if="focusComment">
          <Icon :class="{'light': i <= focusComment.comment_stars}" icon="star-fill" v-for="i in 5" :key="`star-${i}`" />
        </div>
      </div>
    </header>

    <main v-if="ProductStore.productList.length">
      <div class="img-wrapper">
        <img :src="focusComment.coment_avatar ||`/img/product/${ProductStore.productDict[focusComment.product_id].product_uid}/avatar.webp`" :alt="focusComment.user_name">
      </div>
      <article>
        <header>
          <h2 v-if="focusComment.user_id">{{ focusComment.user_name[0] }}〇{{ focusComment.comment_only_show_firstname === '1' ? focusComment.user_name[2] || '' : '〇' }}</h2>
          <h2 v-else>{{ focusComment.comment_user_name }}</h2>
          <p v-if="focusComment.user_gender">性別：{{ GENDER_DICT[focusComment.user_gender || focusComment.comment_gender] }}</p>
          <p>膚質：{{ SKIN_TYPE_DICT[focusComment.comment_skin_type] }}</p>
          <p>評論日期：{{ timestampParse(focusComment.comment_create_at).format('YYYY-MM-DD') }}</p>
        </header>

        <p v-html="focusComment.comment_content.replace(/\n/g, '<br>')"></p>
        <div class="images" v-if="focusComment.comment_images">
          <div class="image-wrapper" v-for="image in focusComment.comment_images" :key="image" @click="showCommentImage(image)">
            <img :src="`/img/_comment/${image}`">
          </div>
        </div>
      </article>
    </main>

    <div class="comment-image-popup" v-if="focusCommentImage">
      <div class="mask" @click="hideCommentImage">
        <span class="close" @click="hideCommentImage"><icon icon="x"/></span>
        <div class="content">
          <img :src="`/img/_comment/${focusCommentImage}`">
        </div>
      </div>
    </div>
  </div>
  </Transition>
</template>
