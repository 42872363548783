<template>
  <div class="view-privacy">
    <header>
      <h1>服務條款</h1>
      <p>Terms & Conditions</p>
    </header>

    <main>
      <section>
        <span>
          Chris Farrell Taiwan 台灣官網(以下以「我們」或「本網站」替代)按照以下使用條款為您提供本網站的內容和服務。透過進入或使用本網站，您確認已閱讀、理解並同意（並無限制或保留）受此等使用條款（包含本公司之隱私權政策）之約束。 請仔細閱讀這些使用條款。
          <br />
          <br />您必須年滿二十歲才能在「本網站」提供您的個人資料、註冊帳號或進行購物。若您未滿二十歲，您必須取得您的父母／監護人之同意。您的父母／監護人必須閱讀並了解「本網站」所有內容並同意您在「本網站」進行購物、註冊帳號或提供個人資料。當您繼續使用「本網站」，表示您的父母／監護人已經閱讀並了解「本網站」內容並同意您在「本網站」購物、註冊帳號與輸入您的個人資料。
          <br />
          <br />進入與使用「本網站」部分內容可能需要您註冊帳號並使用密碼。您有責任維持您的帳號、使用者名稱與密碼之機密性。您有責任提供並維持完整、正確與真實的帳號資訊。您可以透過Email或聯絡客服隨時取消您的帳號。若有任何違反「本條款」規定之情事，我們保留不經通知停止您進入／使用「本網站」之權利（包括但不限於拒絕服務、終止帳號等）。
          <br />
          <br />透過進入或使用 Chris Farrell Taiwan 經營的網站，您同意受本使用條款中規定的條款約束。如您不同意任何此等條款，請勿使用本網站。
        </span>
      </section>

      <section>
        <h3>1. 隱私</h3>
        <span>請閱讀我們的隱私權政策了解我們的隱私權政策。</span>
      </section>

      <section>
        <h3>2. 購買相關政策與程序</h3>
        <span>檢視透過本站訂購的政策與程序(例如訂單處理、郵寄與手續、退換貨)。</span>
      </section>
      <section>
        <h3>3. 資訊正確性</h3>
        <span>我們將盡量於本網站上正確描述本站產品；然而，經適用法許可，我們不保證產品描述、顏色、資訊或其它本站提供的內容正確、完整、可靠、最新或無謬誤。</span>
      </section>
      <section>
        <h3>4. 智慧財產</h3>
        <span>
          「本網站」及其所包含之元素，包括但不限於品牌、文字、內容、軟體、影片、音樂、音效、圖像、照片、插圖、藝術作品、影像、名稱、logo、商標、服務標章與其他素材（下稱「內容」）皆受著作權、商標權及／或其他智慧財產權之保護。「內容」包括所屬於 Chris Farrell Taiwan 或由 Chris Farrell Taiwan 控制者，亦包括所屬第三方或由第三方掌控並授權 Chris Farrell Taiwan 使用者。您不得為任何目的（包括但不限於商業目的）將「本網站」（全部或一部）加以複製、重製、重編、分解、拆組、進行反向工程、傳佈、發行、展示、表現、修改、製作衍生作品後上傳、傳輸或以其他方式利用。
          <br />
          <br />所有個別文章、報導與其他「本網站」之構成元素皆可能屬於受著作權保護之作品，您同意遵守「本網站」所有著作權等相關聲明或限制條款。 我們可能於「本網站」授權您下載「本網站」之部份內容（下稱「可下載內容」）。我們僅授權您為個人及私人目的，在免收使用費且受智慧財產權保護之法律條件下，准予您對「可下載內容」之非專屬且不可轉讓之使用權，並嚴格禁止任何再製、陳述、修改或散布「可下載內容」。在您下載或使用「可下載內容」時，即表示您已同意在遵循「本條款」及相關限制之前提下使用。
          <br />
          <br />若我們向您開放在「本網站」編輯照片之權限（特別是針對透過虛擬方式試用產品），您茲此確認並同意「本網站」僅供您個人且在符合「本網站」之預期目的範圍內為您私人所用。您無權使用「本網站」從事可能有損第三方之尊嚴、名譽或權利之行為。您無權在未獲第三方事先明確同意之情況下擅自修改及／或展示第三方之影像，且您無權在未獲我們事先明確同意之情況下擅自修改及／或展示（不論是否透過「本網站」修改或展示）「本網站」之一部或全部內容（尤其是顯示模特兒影像之內容）。
        </span>
      </section>
      <section>
        <h3>5. 您的義務與責任</h3>
        <span>
          進入或使用本網站時，您應遵守此等使用條款和本網站發佈的進入或使用之特別警告或指示。您應時刻按照法律和慣例並以誠信行事。嚴禁透過使用或進入本網站而破壞或損害本網站及其內容或安全措施，或侵擾或貶損 Chris Farrell Taiwan 或其產品或服務或人員。不得向本網站或透過本網站發送未經要求的電子郵件（垃圾郵件）。 使用者不得以以下方式透過本網站傳送、分發、儲存或銷毀任何資料：(a) 違反任何適用法律或法規的方式；(b) 侵犯他人著作權、專利、商標、營業秘密或其他智慧財產權的方式，或侵犯他人隱私權或其他個人權利的方式；或 (c) 誹謗、淫穢、威脅、辱駡或憎恨性的方式。
          <br />
          <br />若您疏忽或故意不履行此等使用條款規定的任何義務，您應就可能對 Chris Farrell Taiwan 台灣網站、關聯公司、夥伴或許可者的損失與損害賠償。
        </span>
      </section>
      <section>
        <h3>6. 您的帳戶</h3>
        <span>除上述年齡限制外，您可以檢視或使用本站功能，包括購物，但為了進入與使用本站某些部分，您必須註冊帳號。您有責任維持您的帳號、使用者名稱與密碼機密性並限制使用您的電腦。您有責任提供並維持當前、完整、正確與真實帳戶資訊。您同意接受帳戶、使用者名稱和／或密碼的活動責任。若您代表他人進入與使用本站，代表您有權約束該人遵守條款與政策，如您沒有此權利，您同意受到條款與政策的約束並接受因不當使用本站或內容引起的責任。您可以透過Email或聯絡客服隨時取消您的帳號。若違反本條款與政策或經我們自行決定為最佳利益，我們保留權利不經通知拒絕服務和／或終止帳號。</span>
      </section>
      <section>
        <h3>7. 使用者內容</h3>
        <span>
          透過本網站，您可對於產品評價、部落格和其它功能提供自己的意見或評論。Chris Farrell Taiwan 得（但沒有義務）監督或審查用戶在本網站提交或發表意見或評論或僅僅進行相互交流的任何資訊（包括但不限於產品評價、部落格和其它功能，以及任何此類交流的內容）。 在台灣法律所允許的範圍內， Chris Farrell Taiwan 對任何此類交流的內容均不承擔任何責任。
          <br />
          <br />但 Chris Farrell Taiwan 保留阻擋或刪除有下列情況的交流或資料的權利：(a) 侮辱、誹謗或淫穢的；(b) 欺詐、欺騙或誤導性的；(c) 違反他人的著作權、商標或其他智慧財產權；(d) 違反任何法律或法規；和/或 (e) 冒犯性的或 Chris Farrell Taiwan 全權酌情認為不可接受的其他情況。請注意，您可能發表或傳送的任何個人資訊將由 Chris Farrell Taiwan 隱私權政策規範。
        </span>
      </section>
      <section>
        <h3>8. 資料提供</h3>
        <span>您提供本公司的任何未經要求的建議、想法、詢問、資料、回饋或其他資訊（隱私權政策中規範的您的個人資料除外）將被視為非專用及非機密；此類資訊之提供，表彰您已授予本公司非專屬、免授權金、永久性的、可轉讓的、不可撤銷的和可完全再授權的權利，以任何方式、媒介或技術（無論是現時已知或此後開發的）使用、複製、修改、改編、出版、出售、轉讓和翻譯，以及從任何提呈資料創作衍生作品、分發及展示該等提呈資料，無論以單獨形式或作為其他作品的一部分。您亦確認，您提供的資料將不予歸還，本公司可將您的提呈資料及其中的任何構思、概念，或技術秘訣(Know How)用於任何目的（包括但不限於開發、製造、分發及推銷產品）。</span>
      </section>
      <section>
        <h3>9. 第三方連結</h3>
        <span>Chris Farrell Taiwan 不對本網站連結之任何無關連的站外網頁承擔責任。</span>
      </section>

      <section>
        <h3>10. 聲明與保證；責任限制</h3>
        <span>
          本網站「按原樣」呈現。本公司不就此等使用條款或本網站作任何種類的聲明或保證（明示或默示），包括但不限於可售性、不侵權或對某特定用途的適用性之保證，除非該等聲明及保證依法不可合法排除。
          <br />
          <br />本公司不對本網站或其內容的及時性、正確性、不可用性或可用性中斷、病毒或其他缺陷承擔責任。任何情況下，本公司不會就與本網站有關之任何直接、間接、特殊、附帶引起或相應而生之任何種類的損害（包括所失利益）負責。即使本公司已被告知可能產生此類損害，亦無論損害賠償請求權是基於契約、侵權行為或其它形式，本公司均不負責。 您同意，就您（或使用您用戶名稱和密碼的任何人）因進入、使用本網站及本網站上的任何使用者內容或違反本使用條款而引致的任何第三方索賠、訴訟或請求所帶來的任何損失、損害、索賠、罰金、罰款或費用（包括合理的律師費），本公司、經理人、員工、代理人和授權人抗辯，作出補償並使其免受損害。您還同意，就您使用軟體機器人（software robots）、spiders、crawlers，或類似的資料收集及擷取工具或您採取對本公司的基礎結構加諸不合理負擔或負荷之任何其他行動所帶來的任何損失、損害或費用（包括合理的律師費），對 Chris Farrell Taiwan 作出補償。
        </span>
      </section>
      <section>
        <h3>準據法及管轄法院</h3>
        <span>對本網站和本使用條款的任何解釋和適用應由台灣法律規範。有關本網站以及本使用條款所定的所有權利和義務的任何爭議應由具備專屬管轄權的台灣台北地方法院爲第一審管轄法院。</span>
      </section>
    </main>
  </div>
</template>
