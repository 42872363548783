<script setup>
import { ref, computed, onMounted, onBeforeUnmount, inject } from "vue"
import Icon from "@/components/Icon"

const Props = defineProps({
  question: String,
  answer: {
    type: String,
    default: ''
  },
  imgs: {
    type: Array,
    default: () => []
  },
  imgs_m: {
    type: Array,
    default: () => []
  }
})

const ScreenState = inject('ScreenProvider')
const loading = ref(true)
const showAnswer = ref(true)
function toggleShowAnswer() {
  showAnswer.value = !showAnswer.value
}

const questionREF = ref(null)
const offsetHeight = ref(20000)
const observer = ref(null)
onMounted(async () =>{
  if (Props.imgs.length) {
    const promises = Array.from(questionREF.value.getElementsByTagName('img')).map(e => new Promise((res) => {
    if (e.complete) res()
      e.onload = () => res()
    }))
    await Promise.all(promises)
  }
  
  offsetHeight.value = questionREF.value.offsetHeight
  showAnswer.value = false
  setTimeout(() => {
    loading.value = false
  }, 300);
})

function replaceURLsWithLinks(text) {
  var urlRegex = /\[([^\]]+)\]\((https?:\/\/(?!.*?<img\b)[^\s\)]+)\)/g;
  return text.replace(urlRegex, function(match, text, url) {
    if (/<img\b[^>]*>/i.test(text)) {
      return match; // 返回原始的超链接文本
    } else {
      return '<a href="' + url + '">' + text + '</a>';
    }
  });
}



const answerHtmlFormat = computed(() => {
  return replaceURLsWithLinks(Props.answer.replace(/\n/g, '<br>'))
})
</script>

<template>
  <div class="component-help-faq-collapse" :class="{ 'show': showAnswer, 'loading': loading }">
    <header @click="toggleShowAnswer">
      <h3>{{ Props.question }}</h3>
      <span><icon icon="chevron-down" /></span>
    </header>

    <main ref="questionREF" :style="{ maxHeight: showAnswer ? `${offsetHeight}px` : 0 }">
      <div class="image">
        <img v-for="img in ScreenState.mode==='COMPUTER' || !Props.imgs_m.length ? Props.imgs : Props.imgs_m" :src="`${img}`" :key="img" />
      </div>
      <div
        class="content"
        v-html="answerHtmlFormat"
      />
    </main>
  </div>
</template>

<style lang="scss" scoped>
@mixin laptop {
  @media screen and (max-width: 1600px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 1000px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 900px) {
    @content;
  }
}


.component-help-faq-collapse {
  padding: 20px 8px;
  opacity: 1;
  transition: .3s;

  @include mobile {
    padding: 8px 8px 12px 8px;
  }

  &.loading {
    opacity: 0;
  }

  & ~ .component-help-faq-collapse {
    border-top: 1px solid #b1b1b1;
  }

  header {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    cursor: pointer;

    @include laptop {
      padding: 8px 0;
    }

    @include mobile {
      padding: 12px 0;
    }

    h3 {
      font-size: 16px;
    }
  }

  main {
    max-height: 0;
    overflow: hidden;
    transition: .6s;

    .content {
      color: #767676;
      line-height: 1.8em;
      font-weight: 400;
    }

    @include laptop {
      img {
        width: 60vw;
      }
    }

    @include mobile {
      .content {
        line-height: 1.5em; 
      }

      .image {
        margin-top: 12px;
      }

      img {
        display: block;
        width: 100%;
        border-radius: 8px;
        object-fit: cover;

        & ~ img {
          margin-top: 8px;
        }
      }
    }
  }

  &.show {
    header {
      span .comp-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(180deg);
      }
    }
  }
}
</style>
