<script setup>
import Icon from '@/components/Icon'
import { onMounted, onBeforeUnmount, ref } from 'vue'

const Props = defineProps({
  modelValue: String,
  label: String,
  defaultItem: String,
  options: {
    type: Array,
    default: []
  },
  invalidExtra: String,
  hideLabel: Boolean
})

function handleClickAway(e) {
  if (e.target.closest('.payment-comp-selector')) return
  spread.value = false
}
onMounted(() => document.getElementsByClassName('view-payment')[0].addEventListener('click', handleClickAway))
onBeforeUnmount(() => document.getElementsByClassName('view-payment')[0].removeEventListener('click', handleClickAway))

const spread = ref(false)
const refInvalid = ref(false)
const Emits = defineEmits(['update:modelValue'])

function handleSpread(bool=true) {
  spread.value = bool
}

function handleChoose(item) {
  spread.value = false
  refInvalid.value = false
  Emits('update:modelValue', item)
}

function handleInvalid() {
  refInvalid.value = true
}
defineExpose({ callInvalid: handleInvalid })
</script>

<template>
  <div :class="{'payment-comp-selector': true, invalid: refInvalid}">
    <span class="title" v-if="!hideLabel"><p>{{ label }}</p></span>
    <header :class="{'show-value': true, 'has-value': modelValue}" @click="handleSpread(!spread)">
      <span>{{ Props.modelValue || Props.label}}</span>
      <icon :class="{ spread }" icon="caret-down-fill" />
    </header>
    <ul :class="{ items: true, spread }">
      <li v-if="defaultItem && !Props.options.length">{{ defaultItem }}</li>
      <li
        :class="{ choosed: item === Props.modelValue }"
        v-for="item in Props.options" :key="`selector_item_${item}`"
        @click="handleChoose(item)"
      >
        {{ item }}
      </li>
    </ul>

    <span class="extra" v-if="invalidExtra && refInvalid">{{ invalidExtra }}</span>
  </div>
</template>
