<script setup>
import { ref, inject, onMounted, onBeforeUnmount } from 'vue'
import Icon from '../components/Icon.vue'
const toTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })
const ScreenState = inject('ScreenProvider')

const showGoTop = ref(false)
let func = () => {
  const windowScrollY = document.documentElement.scrollTop
  const windowHeight = window.innerHeight
  if (windowScrollY >= window.innerHeight) showGoTop.value = true
  else showGoTop.value = false
}
onMounted(() => {
  window.scrollTo(0, 0)
  document.addEventListener('scroll', func)
})
onBeforeUnmount(() => document.removeEventListener('scroll', func))
</script>

<template>
  <footer id="cf-footer">
    <section>
      <div class="about">
        <header>認識 Chris Farrell</header>
        <ul>
          <li>
            <router-link to="/about">品牌故事</router-link>
          </li>
          <!-- <li>
            <router-link to="/tattoo-care">刺青保養</router-link>
          </li> -->
          <li>
            <router-link to="/product-instruction">產品說明</router-link>
          </li>
        </ul>
      </div>
      <div class="custom-service">
        <header>客服中心</header>
        <ul>
          <li><router-link to="/vip-rights">會員權益</router-link></li>
          <li><router-link to="/help/contact-us">聯絡我們</router-link></li>
          <li><router-link to="/help/return-order">退換貨說明</router-link></li>
          <li><router-link to="/help/payment-options">付款方式</router-link></li>
          <li><router-link to="/help/faq">常見問題</router-link></li>
          <li><router-link to="/search-order">訂單查詢</router-link></li>
        </ul>
      </div>
      <div class="custom-service">
        <header>客服聯絡資訊</header>
        <ul>
          <li>若有任何問題來信或是加入 line@ 聯繫客服人員</li>
          <li>客服信箱：support@chris-farrell.com.tw</li>
          <li>客服電話：02-29617175</li>
          <li>服務時間：09:00 - 18:00</li>
        </ul>
      </div>
      <div class="social-media">
        <header>社群平台</header>
        <ul>
          <li>
            <a target="_blank" href="https://www.facebook.com/chrisfarrelltw">
              <icon icon="facebook" />Facebook
            </a>
          </li>
          <li>
            <a target="_blank" href="https://www.instagram.com/chrisfarrell_tw/">
              <icon icon="instagram" />Instagram
            </a>
          </li>
          <li>
            <a target="_blank" href="https://lin.ee/EssQxJt">
              <icon icon="line" />Line@
            </a>
          </li>
        </ul>
      </div>
    </section>
    <p class="copy-right">
      Copyright © 2022 Chris Farrell 臺灣總代理 贏家風采國際事業有限公司 - All Rights Reserved |
      <router-link to="/help/privacy">隱私權政策</router-link>
    </p>
    <div :class="{'go-top': true, 'show': showGoTop}" @click="toTop">
      <i class="top-arrow"></i>
      <p>top</p>
    </div>
  </footer>
</template>
