<script setup>
import Icon from '@/components/Icon'
import { defineEmits } from 'vue'

const TYPE_ICON = {
  success: 'check2-circle',
  warning: 'exclamation-octagon-fill',
  error: 'x-circle-fill',
  info: 'info-circle-fill',
}

const Props = defineProps({ type: { type: String, default: 'info' }, content: String, modelValue: Boolean })
const emits = defineEmits(['confirm', 'cancel', 'update:modelValue'])
const handleClick = boolean => {
  emits('update:modelValue', false)
  if (boolean) emits('confirm')
  else emits('cancel')
}
</script>

<template>
  <div class="cf-confirm" v-if="Props.modelValue">
      <div class="wrapper">
        <icon :icon="TYPE_ICON[Props.type]" />
        <slot><p>{{ Props.content }}</p></slot>
        <div class="button-group">
          <button @click="handleClick(false)">取消</button>
          <button @click="handleClick(true)">確定</button>
        </div>
      </div>
  </div>
</template>
