import { fetchApi } from "@/utils";

export function accu_register(user) {
  const { userId, user_name, user_email, user_phone, user_address } = user
  console.log("accu_register")
  console.log({ userId, user_name, user_email, user_phone, user_address });
  dos.beaconSend(32, 3, 1, 10, userId, user_name, user_phone, user_email, user_address, "", "", "", "", "" );
}

export function accu_login(userId) {
  console.log("accu_login")
  console.log(fpc);
  dos.beaconSend(32, 3, 1, 13, userId, fpc, "www.chris-farrell.com.tw");
}

export function accu_line_bind(userId, userToken) {
  console.log("accu_line_bind")
  console.log(userId, userToken);
  dos.beaconSend(32, 3, 1, 13, userId, userToken, "@chrisfarrell");
  accu_login(userId)
}

export async function accu_crm_bind(userId) { //尚未完成
  const res = await fetchApi('/api/accuhit/', { body: JSON.stringify({
    member_key: userId,
    token: userId,
    token_from: '贏家風采國際_crm',
  })})
  console.log(res);
  return res
}
