<script setup>
</script>

<template>
  <div class="hlep-cs-landing">
    <header class="subpage-header">
      <h1>歡迎來到 Chris Farrell Taiwan 客服中心</h1>
    </header>
    <section>
      <p>這裡將為您提供有關官網購物、訂單、隱私權等問題的回答，點選以下類別，即可查看回答</p>
      <p>週一至週五：10:00AM至12:00PM/2:00PM至5:00PM（假日除外）</p>
      <ul>
        <li><router-link to="/search-order">訂單查詢</router-link></li>
        <li><router-link to="/help/faq">常見問題</router-link></li>
        <li><router-link to="/help/return-order">退換貨說明</router-link></li>
        <li><router-link to="/help/privacy">隱私權政策</router-link></li>
      </ul>
      <br>
      <p>其他有關訂單及網站的問題，請聯絡 Chris Farrell Taiwan 客服團隊</p>
      <p>欲聯絡客服請透過官方 <a href="https://page.line.me/369ljfeu" target="_blank">Line@</a> 或寫信致客服信箱：support@chris-farrell.com.tw</p>
    </section>
  </div>
</template>
