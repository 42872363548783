<script setup>
import { CfInput, CfCheckbox } from '@/components/Form'
import CVS_FAMI_WEBP from '@/assets/img/CVS_FAMI.webp'
import CVS_UNIMART_WEBP from '@/assets/img/CVS_UNIMART.webp'
import COUNTRY_DICT from '@/utils/country.json'
import NATIONAL_DICT from '@/utils/internationalLogisDistrict.json'
import { nextTick, onMounted, reactive, ref, shallowRef, defineExpose, inject, onBeforeUnmount, computed, watch } from 'vue'
import { useLoginStore } from '../../store/login'
import { usePaymentStore } from '../../store/payment'
import { euqalObject } from '../../utils'
import { getZipCode } from '@/utils/zip'
import { pixelEvent } from '@/utils/pixel'
import moment from 'moment'
import { useRoute } from 'vue-router'
import { gaEvent } from '@/utils/event'
import Selector from './components/Selector'
import { useEmailStore } from '../../store/email'
import { useProductStore } from '../../store/product'

const Props = defineProps({ nextStep: Function, lastStep: Function })
const ScreenProvider = inject('ScreenProvider')
const $message = inject('$message')
const phone = ref('')
const order_port_type = ref('HOME')
const order_pay_type = ref('CREDIT')
const acceptJoinMember = ref(false)
const rememberShippingInfo = ref(true)
const acceptEDM = ref(true)
const choosePayType = type => {
  if (type === "COD") {
    PaymentStore.cleanCVSInfo()
    order_port_type.value = "HOME"
  }
  order_pay_type.value = type
}
const choosePortType = type => {
  if (type === "HOME") {
    PaymentStore.cleanCVSInfo()
  }
  if (type === "INTERNATIONAL") {
    choosePayType("CREDIT")
  }
  order_port_type.value = type
}

const ProductStore = useProductStore()
const EmailStore = useEmailStore()
const LoginStore = useLoginStore()
const shippingInfo = reactive({
  user_phone_area: '+886'
})
const injectShippingInfo = userInfo => {
  shippingInfo.firstName = userInfo.user_name ? userInfo.user_name[0] : userInfo.firstName
  shippingInfo.lastName = userInfo.user_name ? userInfo.user_name.slice(1, 3) : userInfo.lastName
  shippingInfo.user_phone = userInfo.user_phone
  shippingInfo.user_email = userInfo.user_email
  shippingInfo.user_city = userInfo.user_city
  shippingInfo.user_urban_area = userInfo.user_urban_area
  shippingInfo.user_address = userInfo.user_address
  shippingInfo.user_phone_area = userInfo.user_phone_area.includes('+') ? userInfo.user_phone_area : `+${userInfo.user_phone_area}`

  if (shippingInfo.logisticsType === 'INTERNATIONAL') {
    nationalAreaInfo.user_area = userInfo.user_area
    nationalAreaInfo.user_city = userInfo.user_city
    nationalAreaInfo.user_urban_area = userInfo.user_urban_area
    nationalAreaInfo.user_address = userInfo.user_address
  }
}

const nationalAreaInfo = reactive({
  user_area: '',
  user_city: '',
  user_urban_area: '',
  user_address: ''
})

watch(() => ({ ...nationalAreaInfo }), (newInfo, oldInfo) => {
  console.log(newInfo, oldInfo);
  if (newInfo.user_area !== oldInfo.user_area) {
    shippingInfo.user_city = ''
    shippingInfo.user_urban_area = ''
    nationalAreaInfo.user_city = ''
    nationalAreaInfo.user_urban_area = ''
  }
  if (newInfo.user_city !== oldInfo.user_city) {
    shippingInfo.user_urban_area = ''
    nationalAreaInfo.user_urban_area = ''
  }
}) 

const nationalCity = computed(() => {
  if (!nationalAreaInfo.user_area) return []
  return NATIONAL_DICT[nationalAreaInfo.user_area].districts.map(d => d.name)
})
const nationalTown = computed(() => {
  if (!nationalAreaInfo.user_city || !nationalAreaInfo.user_city) return []
  return NATIONAL_DICT[nationalAreaInfo.user_area].districts.find(d => d.name === nationalAreaInfo.user_city)?.towns || []
})
const citysItems = Object.keys(COUNTRY_DICT)
const countryItems = computed(() => COUNTRY_DICT[shippingInfo.user_city]?.towns || [])

const registerMember = ref(true)
const memberPassword = ref('')
const checkPassword = ref('')

let stageTimeoutInternalInst
const stageTimeout = ref(0)
const infoErrorTimes = ref(0)
const Route = useRoute()
onMounted(() => {
  if (PaymentStore.shippingInfo) {
    injectShippingInfo(PaymentStore.shippingInfo)
    if (PaymentStore.shippingInfo.logisticsType === 'INTERNATIONAL') {
      choosePortType('INTERNATIONAL')
    }
  } else {
    if (LoginStore.currentUser) {
      injectShippingInfo(LoginStore.currentUser)
      registerMember.value = false
    }
  }
  
  if (Route.query.fromcvs==='1') {
    order_port_type.value = 'CVS'
    const { CVSStoreID, CVSStoreName, CVSAddress, LogisticsSubType } = Route.query 
    PaymentStore.cvsInfo = {
      CVSStoreID, CVSStoreName, CVSAddress, LogisticsSubType
    }
  }
  if (Route.query.ExtraData === 'COD') {
    order_pay_type.value = 'COD'
  }
  stageTimeoutInternalInst = setInterval(() => {
    stageTimeout.value += 1
  }, 1000);
})
onBeforeUnmount(() => {
  clearInterval(stageTimeoutInternalInst)
})

const currenUserMutated = ref(false)
LoginStore.$subscribe((mutation, newState) => {
  if (currenUserMutated.value) return
  if (newState.currentUser) {
    injectShippingInfo(newState.currentUser)
    registerMember.value = false
    currenUserMutated.value = true
  } else {
    for (const key of Object.keys(shippingInfo)) {
      shippingInfo[key] = ''
    }
  }
})

const ecpayMapREF = ref(null)
const ecpayMapHtml = ref()
async function handleChooseStore(LogisticsSubType) {
  ecpayMapHtml.value = true
  await nextTick()
  console.log(ecpayMapREF.value);
  const form = ecpayMapREF.value.getElementsByTagName('form')[0]
  form.setAttribute('action', 'https://logistics.ecpay.com.tw/Express/map') //上線前修改成正式環境
  console.log(window.location.hostname);
  form.innerHTML = `

    <input name="ServerReplyURL" value="https://${window.location.hostname}/api/map-back/">
    <input name="MerchantID" value="${process.env.VUE_APP_ECPAY_LOGISTICS_C2C_MID}">
    <input name="IsCollection" value="${order_pay_type.value === 'COD' ? 'Y' : 'N'}">
    <input name="LogisticsType" value="CVS">
    <input name="LogisticsSubType" value="${LogisticsSubType}">
    <input name="Device" value="${ScreenProvider.mode === 'MOBILE' ? '1' : '0'}">
    <input name="ExtraData" value="${order_pay_type.value}">
  `//上線前修改成正常網域
  console.log(form);
  form.submit()
}

const refs = reactive({})
function refElement(ref, key) {
  refs[key] = ref
}

const submitLoading = ref(false)
function callInvalid(key, msg) {
  $message.error(msg)
  infoErrorTimes.value += 1
  if (key) refs[key].callInvalid()
  submitLoading.value = false
}


const PaymentStore = usePaymentStore()
async function handleSubmit() {
  submitLoading.value = true
  let checkKey = ['firstName', 'lastName', 'user_phone']
  
  //merge international shipping info
  if (order_port_type.value === 'INTERNATIONAL') {
    shippingInfo.user_area = nationalAreaInfo.user_area
    shippingInfo.user_city = nationalAreaInfo.user_city
    shippingInfo.user_address = nationalAreaInfo.user_address
    if (shippingInfo.user_area === '香港') {
      if (!nationalAreaInfo.user_urban_area) return callInvalid('user_urban_area', '請選擇地區')
      shippingInfo.user_urban_area = nationalAreaInfo.user_urban_area
    }
  }

  console.log(shippingInfo);

  PaymentStore.payType = order_pay_type.value
  if (!shippingInfo.firstName) return callInvalid('firstName', '請填寫收件人姓氏')
  if (!shippingInfo.lastName) return callInvalid('lastName', '請填寫收件人名字')
  if (shippingInfo.user_phone_area === '+886' && (!shippingInfo.user_phone || ![9,  10].includes(shippingInfo.user_phone.length))) return callInvalid('user_phone', '手機格式錯誤，請確認是否為9開頭的九位數號碼')
  if (order_port_type.value !== 'CVS' ) {
    checkKey = [...checkKey, 'user_address', 'user_city', 'user_urban_area']
    if (!shippingInfo.user_city) return callInvalid('user_city', order_port_type === 'INTERNATIONAL' ? '請選擇行政區' : '請選擇縣市')
    if (!shippingInfo.user_urban_area && shippingInfo.user_area !== '澳門') return callInvalid('user_urban_area', '請選擇鄉鎮市區')
    
    if (order_port_type.value === 'HOME') {
      //確認地址市是否存在且查詢得到郵遞區號
      const ReceiverAddress = shippingInfo.user_city + shippingInfo.user_urban_area + shippingInfo.user_address
      const zipInfo = await getZipCode(ReceiverAddress)
      if (!zipInfo.zipcode) return callInvalid('user_address', '無法找到與出貨地址匹配的郵遞區號，請確認地址是否正確')
    }
  }
  if (order_port_type.value === 'CVS' && !PaymentStore.cvsInfo) return callInvalid('user_phone', '未選擇取貨門市')
  if (order_pay_type.value === 'CREDIT' && !shippingInfo.user_email) return callInvalid('user_email', '信用卡付款請填寫 Email')
  if (!checkKey.every(key => shippingInfo[key])) return callInvalid(null, '未完全填妥取貨資訊')
  const adjustPhone = /^09\d{8}$/.test(shippingInfo.user_phone) ? shippingInfo.user_phone.slice(1, 10) : shippingInfo.user_phone
  const result = await PaymentStore.saveShippingInfo({ ...shippingInfo,
    user_email: shippingInfo.user_email.replace(/\s/g, ''),
    user_phone: shippingInfo.user_phone_area === '+886' ? adjustPhone : shippingInfo.user_phone,
    logisticsType: order_port_type.value
  })

  //google analytics event
  gaEvent('add_shipping_info', {
    currency: 'TWD',
    value: PaymentStore.amount,
    coupon: PaymentStore.couponUsed.map(c => c.coupon_name).join(','),
    shipping_tier: PaymentStore.cvsInfo?.LogisticsSubType || PaymentStore.shippingInfo.logisticsType,
    items: PaymentStore.cartItems.map(product => ({
      item_id: ProductStore.productDict[product.product_id].product_capi_uid,
      item_name: ProductStore.productDict[product.product_id].product_name_zh,
      item_category: ProductStore.productDict[product.product_id].product_type.toLowerCase(),
      price: ProductStore.productDict[product.product_id].product_price[product.product_price],
      quantity: product.product_quantity
    }))
  })

  if (registerMember.value && !LoginStore.currentUser) {
    if (!shippingInfo.user_email) return callInvalid('user_email', '請輸入 Email')
    if (!memberPassword.value) return callInvalid('memberPassword', '請輸入密碼')
    if (checkPassword.value !== memberPassword.value) return callInvalid('checkPassword', '密碼不一致')
    
    const registerResult = await LoginStore.registerUser({
      firstName: shippingInfo.firstName,
      lastName: shippingInfo.lastName,
      user_email: shippingInfo.user_email.replace(/\s/g, ''),
      user_phone: adjustPhone,
      user_city: shippingInfo.user_city,
      user_urban_area: shippingInfo.user_urban_area,
      user_address: shippingInfo.user_address,
      password: memberPassword.value
    })
    if (registerResult.state === 400 && registerResult.code === 'auth/email-already-in-use') {
      submitLoading.value = false
      return $message.error('此 email 已被註冊')
    }
    const recipients = [{
      name: shippingInfo.firstName + shippingInfo.lastName,
      address: shippingInfo.user_email,
    }]
    await EmailStore.sendEmail({
      subject: EmailStore.systemEmailDict['REGISTER_SUCCESS'].email_template_subject,
      contentId: EmailStore.systemEmailDict['REGISTER_SUCCESS'].email_template_id
    }, recipients)
  }

  if (rememberShippingInfo.value && LoginStore.currentUser) {
    if (order_port_type.value === "HOME") {
      await LoginStore.updateUser({
        user_city: shippingInfo.user_city,
        user_urban_area: shippingInfo.user_urban_area,
        user_address: shippingInfo.user_address
      })
    }
  }
  pixelEvent('InitiateCheckout', {
    user: {
      user_email: shippingInfo.user_email || '',
      user_phone: `0${adjustPhone}`
    },
    custom_data: {
      content_ids: PaymentStore.cartItems.map(p => ProductStore.productDict[p.product_id].product_capi_uid),
      content_type: 'product',
      content_category: 'Payment',
      contents: JSON.stringify(PaymentStore.cartItems.map(p => ({
        id: ProductStore.productDict[p.product_id].product_capi_uid,
        quantity: p.product_quantity,
        item_price: ProductStore.productDict[p.product_id].product_price[p.product_price]
      }))),
      value: PaymentStore.sumOfCartPrice + PaymentStore.freeShippingCost,
      currency: 'TWD',
      num_items: PaymentStore.cartItems.reduce((acc, cur) => acc + parseInt(cur.product_quantity), 0),
    }
  })

  if (result.status === 200 && ScreenProvider.mode === "COMPUTER") Props.nextStep()
  return true
}

function verifyPassword(password) {
  const re = /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/
  return re.test(password)
}

defineExpose({ handleNext: handleSubmit })
</script>

<template>
  <div class="view-shipping">
    <section class="pay-type">
      <header>
        <h2>付款方式</h2>
      </header>
      <main>
        <div class="pay-type-radio credit">
          <div
            :class="{ 'radio': true, 'choosed': order_pay_type === 'CREDIT' }"
            @click="choosePayType('CREDIT')"
          >
            <span class="picked"></span>
          </div>
          <div class="content-wrapper">
            <h3>信用卡付款</h3>
            <p>線上刷卡後，即進行出貨</p>
          </div>
        </div>
        <div class="pay-type-radio credit" v-if="order_port_type !== 'INTERNATIONAL'">
          <div
            :class="{ 'radio': true, 'choosed': order_pay_type === 'COD' }"
            @click="choosePayType('COD')"
          >
            <span class="picked"></span>
          </div>
          <div class="content-wrapper">
            <h3>貨到付款</h3>
            <p>到貨時將由物流公司或超商門市進行收款</p>
          </div>
        </div>
      </main>
    </section>
    <section class="port-type">
      <header>
        <h2>運送方式</h2>
      </header>
      <main>
        <div class="port-type-radio home">
          <div
            :class="{ 'radio': true, 'choosed': order_port_type === 'HOME' }"
            @click="choosePortType('HOME')"
          >
            <span class="picked"></span>
          </div>
          <div class="content-wrapper">
            <h3>宅配到府{{ PaymentStore.amount < PaymentStore.freeShippingThreshold ? '（運費 $120）' : '' }}</h3>
            <p>由黑貓宅急便直送到府，約 1-3 工作天</p>
          </div>
        </div>
        <div class="port-type-radio convenient">
          <div
            :class="{ 'radio': true, 'choosed': order_port_type === 'CVS' }"
            @click="choosePortType('CVS')"
          >
            <span class="picked"></span>
          </div>
          <div class="content-wrapper">
            <h3>超商取貨{{ PaymentStore.amount < PaymentStore.freeShippingThreshold ? ' （運費 $70）' : '' }}</h3>
            <p>可選擇超商：7-11，全家 Family Mart</p>  
            <span v-if="PaymentStore.cvsInfo">
              已選擇:{{PaymentStore.cvsInfo.LogisticsSubType==='UNIMARTC2C' ? '7-11統一超商': '全家 FamilyMart'}}
              {{ PaymentStore.cvsInfo.CVSStoreName }} {{ PaymentStore.cvsInfo.CVSAddress }}
            
            </span>
          </div>
        </div>
        <div class="choose-store" v-if="order_port_type === 'CVS'">
          <button @click="handleChooseStore('UNIMARTC2C')">
            <div><img :src="CVS_UNIMART_WEBP" alt=""></div>選擇7-11門市
          </button>
          <button @click="handleChooseStore('FAMIC2C')">
            <div><img :src="CVS_FAMI_WEBP" alt=""></div>選擇全家門市
          </button>
        </div>
        <div class="port-type-radio international">
          <div
            :class="{ 'radio': true, 'choosed': order_port_type === 'INTERNATIONAL' }"
            @click="choosePortType('INTERNATIONAL')"
          >
            <span class="picked"></span>
          </div>
          <div class="content-wrapper">
            <h3>國際物流 &lt;港澳地區&gt;{{ PaymentStore.amount < 3000 ? ' （消費未滿$3000 需收運費$500元）' : '' }}</h3>
            <p>海外物流相關問題請詳閱：<router-link to="/help/faq" target="_blank">常見問題 &gt; 國際配送問題</router-link></p>
          </div>
        </div>
      </main>
    </section>
    <section class="port-info">
      <header>
        <h2>取貨資訊</h2>
      </header>
      <main>
        <form>
          <cf-input label="姓氏" v-model="shippingInfo.firstName" :placeholder="`姓氏 ${order_pay_type !== 'COD' ? '(須與身分證相符)' : ''}`" required
            :ref="ref => refElement(ref, 'firstName')" maxlength="2"
          />
          <cf-input label="名字" v-model="shippingInfo.lastName" :placeholder="`名字 ${order_pay_type !== 'COD' ? '(須與身分證相符)' : ''}`" required
            :ref="ref => refElement(ref, 'lastName')" maxlength="3"
          />
          <div class="phone-input">
            <selector 
              :options="['+886', '+852', '+853']" 
              v-model="shippingInfo.user_phone_area"
              hideLabel
            />
            <cf-input
              label="電話號碼"
              :ref="ref => refElement(ref, 'user_phone')"
              v-model="shippingInfo.user_phone"
              placeholder="電話號碼"
              required
              :rule="shippingInfo.user_phone_area === '+886' ? 'phone' : null"
              invalidExtra="請輸入9開頭的手機號碼 ex. 912345678"
            />
          </div>
          <template v-if="order_port_type === 'HOME'">
            <selector 
              :ref="ref => refElement(ref, 'user_city')" 
              label="城市" 
              :options="citysItems" 
              v-model="shippingInfo.user_city" 
              invalidExtra="請選擇縣市"
            />
            <selector
              :ref="ref => refElement(ref, 'user_urban_area')"
              label="鄉鎮市區"
              :options="countryItems"
              v-model="shippingInfo.user_urban_area"
              defaultItem="請先選擇縣市"
              invalidExtra="請選擇鄉鎮市區"
            />
            <cf-input
              label="詳細地址"
              v-model="shippingInfo.user_address"
              customClass="address"
              placeholder="詳細地址 (例：松濤路12巷5號12樓A室)"
              required
              invalidExtra="無法找到與出貨地址匹配的郵遞區號，請確認地址是否正確"
              :ref="ref => refElement(ref, 'user_address')"
            ></cf-input>
          </template>
          <template v-if="order_port_type === 'INTERNATIONAL'">
            <selector 
              :ref="ref => refElement(ref, 'user_city')" 
              label="國際物流分區" 
              :options="['香港', '澳門']" 
              v-model="nationalAreaInfo.user_area" 
              invalidExtra="請選擇國際物流分區"
            />
            <selector 
              :ref="ref => refElement(ref, 'user_city')" 
              label="行政區" 
              :options="nationalCity" 
              v-model="nationalAreaInfo.user_city" 
              defaultItem="請先選擇國際物流分區"
              invalidExtra="請選擇行政區"
            />
            <selector
              v-if="nationalAreaInfo.user_area === '香港'"
              :ref="ref => refElement(ref, 'user_urban_area')"
              label="地區"
              :options="nationalTown"
              v-model="nationalAreaInfo.user_urban_area"
              defaultItem="請先選擇行政區"
              invalidExtra="請選擇地區"
            />
            <cf-input
              label="詳細地址"
              v-model="nationalAreaInfo.user_address"
              customClass="address"
              placeholder="詳細地址 (例：松濤路12巷5號12樓A室)"
              required
              invalidExtra="無法找到與出貨地址匹配的郵遞區號，請確認地址是否正確"
              :ref="ref => refElement(ref, 'user_address')"
            ></cf-input>
          </template>
          <template v-if="order_port_type === 'CVS' && PaymentStore.cvsInfo">
            <cf-input 
              customClass="cvs"
              :modelValue="PaymentStore.cvsInfo.LogisticsSubType==='UNIMARTC2C' ? '7-11 統一超商' : '全家 Family Mart'"
              placeholder="超商" readonly required
            />
            <cf-input customClass="cvs" v-model="PaymentStore.cvsInfo.CVSStoreName" placeholder="取貨門市" readonly required />
            <cf-input
              v-model="PaymentStore.cvsInfo.CVSAddress"
              customClass="cvs address"
              placeholder="門市地址" readonly required
            />
          </template>
          <cf-input
            label="電子信箱"
            v-model="shippingInfo.user_email"
            :placeholder="`電子信箱 ${order_pay_type==='CREDIT' || registerMember ? '(必填)' : '(非必填)'}`"
            :required="order_pay_type==='CREDIT'"
            rule="email"
            invalidExtra="請輸入有效的 Email 格式"
            :ref="ref => refElement(ref, 'user_email')"
          />
          <cf-input 
            v-if="!LoginStore.currentUser && registerMember"
            :ref="ref => refElement(ref, 'memberPassword')"
            label="密碼"
            v-model="memberPassword"
            placeholder="會員密碼（直接註冊用）"
            type="password"
            rule="password"
            invalidExtra="請輸入6位數的英文＋數字組合"
          />
          <cf-input 
            v-if="!LoginStore.currentUser && registerMember"
            :ref="ref => refElement(ref, 'checkPassword')"
            label="密碼"
            v-model="checkPassword"
            placeholder="確認密碼"
            type="password"
            rule="password"
            invalidExtra="確認密碼與密碼不相符"
          />
        </form>
        <cf-checkbox v-if="!LoginStore.currentUser" v-model="registerMember" :checked="true">
          註冊成為 Chris Farrell Taiwan 會員 ＊ (註冊前請詳閱<a target="_blank" href="/help/privacy">隱私權政策)</a>
        </cf-checkbox>
        <cf-checkbox v-if="LoginStore.currentUser" v-model="rememberShippingInfo" :checked="true">
          記住出貨資訊 (下次訂購時候自動帶入出貨資訊，也可於會員專區修改)
        </cf-checkbox>
        <!-- <cf-checkbox v-model="acceptEDM">我同意接收 Chris Farrell Taiwan 的電子報通知＊</cf-checkbox> -->
        <div class="button-group">
          <button @click="lastStep">上一步</button>
          <button :class="{'loading-button': true, 'loading': submitLoading}" @click="handleSubmit" :disabled="submitLoading">前往結帳</button>
        </div>
      </main>
    </section>

    <div class="ecpay-map-modal" v-if="ecpayMapHtml">
      <main>
        <section ref="ecpayMapREF" class="map">
          <form action="" method="POST"></form>
        </section>
      </main>
    </div>
  </div>
</template>
