export function formulaExpressionCalc(expression, xValue, inner=false) {
  let formatExpression = expression.replace(/x/g, xValue)
  if (formatExpression.includes('(')) {
    formatExpression = formatExpression.replace(/\(([^()]*)\)/g, (_, innerExpression) => `${formulaExpressionCalc(innerExpression, xValue, true)}`)
  }

  let result = xValue
  let parts = formatExpression.split(' ')
  if (inner) {
    result = parts[0]
    parts = parts.slice(1)
  }
  
  for (let i = 0; i < parts.length; i+=2) {
    const operator = parts[i]
    const operand = Number(parts[i+1])
    switch (operator) {
      case '+': result += operand; break;
      case '-': result -= operand; break;
      case '*': result *= operand; break;
      case '/': result /= operand; break;
      case '//': result = Math.floor(result / operand); break;
    }
  }
  return result
}
