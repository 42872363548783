import { defineStore } from "pinia";
import { timestampParse, clearData } from '@/utils'
import database from '@/utils/database'
import { useProductStore } from "./product";
import { useLoginStore } from "./login";
import moment from 'moment'
import { fetchApi } from "@/utils";

const AGE_RANGES = [[0, 20], [21, 30], [31, 40], [41, 50], [51, 60], [61, 200]]
const GENDER_DICT = { MALE: '生理男', FEMALE: '生理女', OTHERS: '其他' }
const nowMoment = new moment()
function range_age(timestamp) {
  const age = moment.duration(nowMoment.diff(timestampParse(timestamp))).years()
  for (let i = 0; i < AGE_RANGES.length; i++) {
    if (age >= AGE_RANGES[i][0] && age <= AGE_RANGES[i][1]) {
      return i
    }
    continue
  }
}

export const useCommentStore = defineStore({
  id: 'Comment',
  state: () => ({
    commentDict: {},
    commentList: [],
    focusProductId: null,
    focusPage: 1,
    filter: null,
    sorter: null
  }),
  getters: {
    focusCommentList() {
      if (this.commentDict[this.focusProductId]) {
        let newCommentList = JSON.parse(JSON.stringify(this.commentDict[this.focusProductId]))
        if (this.filter) newCommentList = newCommentList.filter(c => c[this.filter[0] && c[this.filter[0]] === this.filter[1]])
        if (this.sorter) newCommentList.sort(this.sorter)
        return newCommentList.map(c => ({
          ...c,
          user_name: c.user_name 
            ? c.comment_only_show_firstname === '1' ? c.user_name[0]+'〇〇' : c.user_name[0]+'〇'+c.user_name[2]
            : c.comment_user_name,
          user_age_group: c.user_id ? range_age(c.user_birthday) : null,
          user_gender: GENDER_DICT[c.user_gender],
          comment_gender: c.user_gender || c.comment_gender,
        }))
      }
      return []
    },
    commentIdDict() {
      if (this.commentList.length) {
        return this.commentList.reduce((acc, comment) => ({
          ...acc,
          [comment.comment_id]: ({ ...comment })
        }), {})
      }
      return {}
    },
    filterByProduct() {
      if (this.commentList.length) {
        return this.commentList.reduce((acc, comment) => ({
          ...acc,
          [comment.product_id]: [...(acc[comment.product_id] || []), comment]
        }), {})
      }
      return {}
    }
  },
  actions: {
    async queryCommentOfProduct(product_id) {
      const res = await database.table('comment')
        .join('user', 'user_id')
        .where('comment.product_id', '=', product_id)
        .get()
      this.commentDict[product_id] = res.data.map(e => ({
        ...e,
        comment_images: e.comment_images ? e.comment_images.split(',') : null
      }))
    },
    async queryComment() {
      const res = await database.table('comment').join('user', 'user_id').orderBy('comment.comment_stars', 'desc').get()
      this.commentList = res.data.filter(c => c.comment_published === '1').map(e => ({
        ...e,
        comment_images: e.comment_images ? e.comment_images.split(',') : null
      }))
    },
    async createComment(product_id, comment) {
      const LoginStore = useLoginStore()
      const { comment_images, ...other } = comment
      const res = await database.table('comment').set({
        product_id,
        ...clearData({ ...other, comment_only_show_firstname: comment.comment_only_show_firstname ? '1' : '0' }),
        user_id: LoginStore.currentUser.user_id,
        comment_create_at: database.FieldValue.serverTimestamp()
      })
      if (res.id !== 0) {
        const API = '/api/upload/comment/'
        const body = comment_images.reduce((acc, image) => {
          console.log(acc);
          acc.append('file[]', image)
          return acc
        }, new FormData())
        body.append('commentId', res.id)
        const uploadRes = await fetch(API, { body, method: 'POST' })
        const uploadJson = await uploadRes.json()
        await database.table('comment').where('comment_id', '=', res.id).update({
          comment_images: uploadJson.fileNames.join(',')
        })
        this.commentDict[product_id].push({
        comment_id: res.id,
          key_id: res.id,
          ...res.data,
          ...LoginStore.currentUser, 
          comment_images: uploadJson.fileNames,
        })
      }
      return res
    }
  }
})
