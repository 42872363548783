import { defineStore } from "pinia";
import { auth } from './firebase'
import { FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth"
import { fetchApi, timestampParse, setCookie, getCookieByKey, removeCookieByKey } from '@/utils'
import { pixelEvent } from '@/utils/pixel'
import database from '@/utils/database'
import { usePaymentStore } from "./payment";
import { useEmailStore } from "./email";
import moment from "moment"
import { useCouponStore } from "./coupon";
import { gaEvent } from '@/utils/event'
import { accu_login, accu_register, accu_line_bind, accu_crm_bind } from '@/utils/accuhit'

const provider = new FacebookAuthProvider()
provider.addScope('user_birthday');
provider.setCustomParameters({
  'display': 'popup'
});

export const useLoginStore = defineStore({
  id: 'Login',
  state: () => ({
    currentUser: null,
    voucherList: []
  }),
  getters: {
    ownCouponIds() {
      if (this.currentUser?.user_own_coupons?.length) return this.currentUser.user_own_coupons.map(c => c.coupon_id)
      return []
    },
    activeVoucherAmount() {
      if (this.voucherList.length) {
        const nowTimestamp = moment().unix()
        return this.voucherList.reduce((acc, v) => {
          if (v.voucher_create_at+v.voucher_period > nowTimestamp && v.voucher_value > v.voucher_used) {
            acc += v.voucher_value - v.voucher_used
          }
          return acc
        }, 0)
      }
      return 0
    }
  },
  actions: {
    async loginWithEmail(loginInfo) {
      const { account, password } = loginInfo
      try {
        const userCredential = await signInWithEmailAndPassword(auth, account, password)
        console.log(userCredential.user);
        const userInfo = await database.table('user').where('user_uid', '=', userCredential.user.uid).get()
        gaEvent('login', {
          method: 'email',
        })
        console.log(userInfo.data[0]);
        this.currentUser = {
          ...userInfo.data[0],
          email: userCredential.user.email,
          user_wishlist: userInfo.data[0].user_wishlist ? userInfo.data[0].user_wishlist.split(',') : [],
          last_login_at: timestampParse(userInfo.data[0].user_last_login_at).format('YYYY / MM / DD'),
          user_own_coupons: JSON.parse(userInfo.data[0].user_own_coupons) || [],
          user_coupons_used: userInfo.data[0].user_coupons_used ? userInfo.data[0].user_coupons_used.split(',') : []
        }

        //*Accuhit 登入綁定
        const userToken = getCookieByKey('cf:accu_user_token')
        if (userToken) accu_line_bind(userCredential.user.uid, userToken)
        else accu_login(userCredential.user.uid) 
        
        this.queryVouchers()
        const CouponStore = useCouponStore()
        CouponStore.queryCoupon()
        setCookie('cf:login', new moment().unix())
        return { status: 200 } 
      } catch (error) {
        console.log(error.code);
        switch (error.code) {
          case 'auth/invalid-email': return { status: 400, msg: '輸入帳號格式錯誤' }
          case 'auth/user-not-found': return { status: 401, msg: '找不到此信箱註冊的帳戶' }
          case 'auth/wrong-password': return { status: 402, msg: '帳號密碼有誤，請重新確認登入資訊' }
          default: return { status: 500, msg: '未知錯誤請聯繫客服人員' }
        }
      }
    },
    async loginWithFB() {
      try {
        const result = await signInWithPopup(auth, provider)
        console.log(result);
        const { user } = result
        if ( user) {
          const saveUserResult = await this.saveUser(user)
          if (saveUserResult.state === 200) return { state: 200 } //已註冊且資料齊全
          else {
            if (saveUserResult.state === 404) { //未註冊
              const setRes = await database.table('user').set({
                user_uid: user.uid,
                user_email: user.email,
                user_login_type: 'FACEBOOK',
                user_create_at: database.FieldValue.serverTimestamp()
              })
            }

            this.currentUser = {
              user_id: setRes.id,
              user_uid: user.uid,
              email: user.email,
              user_wishlist: [],
              user_own_coupons: [],
              user_coupons_used: []
            }
            const CouponStore = useCouponStore()
            CouponStore.queryCoupon()
            return { state: 205, user_id: setRes.id } //已註冊但需補齊資料
          }
        }
        
        this.currentUser = {  }
      } catch (error) {
        if (error.code === 'auth/popup-closed-by-user') {
          return { state: 405 }
        } else {
          window.location = '/login?login=fb&state=404'
        }
        const credential = FacebookAuthProvider.credentialFromError(error);
        console.log('error credential', credential);
      }
      
    },
    async checkAccount(email) {
      const res = await database.table('user').where('user_email', '=', email).get()
      return Boolean(res.data.length)
    },
    async queryVouchers() {
      const lastActiveExp = moment().add(-1, 'y').unix()
      console.log(lastActiveExp);
      const voucherRes = await database.table('voucher')
        .where('user_id', '=', this.currentUser.user_id)
        .where('voucher_create_at', '>', lastActiveExp)
        .get()
      this.voucherList = voucherRes.data.map(v=>({
        ...v, 
        voucher_value: parseInt(v.voucher_value),
        voucher_used: parseInt(v.voucher_used),
        voucher_period: parseInt(v.voucher_period),
        voucher_create_at: parseInt(v.voucher_create_at)
      }))
    },
    async voucherCost(voucherAmount) {
      const res = await fetchApi('/api/voucher/', { voucherAmount: voucherAmount, userId: this.currentUser.user_id })
      return res
    },
    async queryUserWithId(user_id) {
      const userInfo = await database.table('user').where('user_id', '=', user_id).get()
      console.log(userInfo);
      if (userInfo.data[0].user_line_id) {
        const line_id = userInfo.data[0].user_line_id

        setTimeout(() => {
          //*Accuhit Line 登入綁定
          accu_line_bind(line_id, line_id)
        }, 2000);
      }
      this.currentUser = {
        ...userInfo.data[0],
        email: userInfo.data[0].user_email,
        last_login_at: timestampParse(userInfo.data[0].user_last_login_at).format('YYYY / MM / DD'),
        user_wishlist: userInfo.data[0].user_wishlist ? userInfo.data[0].user_wishlist.split(',') : [],
        user_own_coupons: JSON.parse(userInfo.data[0].user_own_coupons) || [],
        user_coupons_used: userInfo.data[0].user_coupons_used ? userInfo.data[0].user_coupons_used.split(',') : []
      } 
    },
    async queryUserWithLineId(line_id) {
      const userInfo = await database.table('user').where('user_line_id', '=', line_id).get()
      console.log(userInfo);

      //*Accuhit Line 登入綁定
      accu_line_bind(line_id, line_id)


      this.currentUser = {
        ...userInfo.data[0],
        user_email: userInfo.data[0].user_email,
        last_login_at: timestampParse(userInfo.data[0].user_last_login_at).format('YYYY / MM / DD'),
        user_wishlist: userInfo.data[0].user_wishlist ? userInfo.data[0].user_wishlist.split(',') : [],
        user_own_coupons: JSON.parse(userInfo.data[0].user_own_coupons) || [],
        user_coupons_used: userInfo.data[0].user_coupons_used ? userInfo.data[0].user_coupons_used.split(',') : []
      }
    },
    async saveUser(userCredential) {
      //*Accuhit 登入綁定
      const userToken = getCookieByKey('cf:accu_user_token')
      if (userToken) accu_line_bind(userCredential.uid, userToken)
      else accu_login(userCredential.uid) 

      const userInfo = await database.table('user').where('user_uid', '=', userCredential.uid).get()
      console.log(userInfo);
      if (userInfo.data[0]) {
        if (userInfo.data[0].user_name) {
          this.currentUser = {
            ...userInfo.data[0],
            email: userCredential.email,
            last_login_at: timestampParse(parseInt(userCredential.metadata.lastLoginAt)).format('YYYY / MM / DD'),
            user_wishlist: userInfo.data[0].user_wishlist ? userInfo.data[0].user_wishlist.split(',') : [],
            user_own_coupons: JSON.parse(userInfo.data[0].user_own_coupons) || [],
            user_coupons_used: userInfo.data[0].user_coupons_used ? userInfo.data[0].user_coupons_used.split(',') : []
          }
          return { state: 200 }
        }
        this.currentUser = {
          ...userInfo.data[0],
          last_login_at: timestampParse(parseInt(userCredential.metadata.lastLoginAt)).format('YYYY / MM / DD'),
          user_wishlist: [],
          user_own_coupons: [],
          user_coupons_used: []
        }
        return { state: 205, user_id: userInfo.data[0].user_id }
      }
      return { state: 404 }
    },
    async updateUser(payload) {
      const res = await database.table('user').where('user_id', '=', this.currentUser.user_id).update({ ...payload })
      this.currentUser = { ...this.currentUser, ...payload, user_birthday: payload.user_birthday }
      return res
    },
    async addWishlist(id) {
      const newWishlist = [...this.currentUser.user_wishlist, id]
      await database.table('user').where('user_uid', '=', this.currentUser.user_uid).update({ user_wishlist: newWishlist.join(',') })
      this.currentUser.user_wishlist = newWishlist
    },
    async removeFromWishlist(id) {
      const newWishlist = [...this.currentUser.user_wishlist.filter(pid => pid !== id)]
      await database.table('user').where('user_uid', '=', this.currentUser.user_uid).update({ user_wishlist: newWishlist.join(',') })
      this.currentUser.user_wishlist = newWishlist
    },
    async removeFromWishlist(id) {
      const newWishlist = this.currentUser.user_wishlist.filter(e => e!==id)
      await database.table('user').where('user_uid', '=', this.currentUser.user_uid).update({ user_wishlist: newWishlist.join(',') })
      this.currentUser.user_wishlist = newWishlist
    },
    async distributeRegisterCoupon(user_id) {
      const old_user_coupon = this.currentUser?.user_own_coupons || []
      const registerCoupon = await database.table('coupon').where('coupon_super', 'in', ['WELCOME_1150', 'PUBLIC_FIRST_200']).get()
      const updateRes = await database.table('user').where('user_id', '=', user_id).update({
        user_own_coupons: JSON.stringify([...old_user_coupon, ...registerCoupon.data.map(c => ({
          coupon_id: c.coupon_id, 
          coupon_get_at: database.FieldValue.serverTimestamp()
        }))])
      })
      this.currentUser.user_own_coupons = [...old_user_coupon, ...registerCoupon.data.map(c => ({
        coupon_id: c.coupon_id, 
        coupon_get_at: new moment().unix()
      }))]
    },
    async registerUser(userInfo) {
      const EmailStore = useEmailStore()
      try {
        const { user_email, user_phone, user_birthday, password, firstName, lastName, user_city, user_urban_area, user_address, method } = userInfo
        const userCredential = await createUserWithEmailAndPassword(auth, user_email, password)
        const user = userCredential.user;
        const user_uid = user.uid
        const user_name = firstName + lastName

        //*Accuhit 註冊綁定
        accu_register({
          userId: user_uid,
          user_name, 
          user_email, 
          user_phone,
          user_address: user_city && user_urban_area && user_address ? `${user_city}${user_urban_area}${user_address}` : '',
        })
        const userToken = getCookieByKey('cf:accu_user_token')
        if (userToken) accu_line_bind(user.uid, userToken)
        
        //*Accuhit CRM 綁定
        const res = await accu_crm_bind(user_uid)
        console.log(res);

        const registerInfo = {
          user_uid, 
          user_name, 
          user_email, 
          user_phone, 
          // user_birthday,
          user_city,
          user_urban_area,
          user_address,
          user_create_at: database.FieldValue.serverTimestamp()
        }
        const result = await database.table('user').set({...registerInfo})
        this.currentUser = {
          user_id: result.id,
          user_uid, 
          user_name, 
          user_email, 
          user_phone, 
          // user_birthday,
          user_city,
          user_urban_area,
          user_address,
          user_create_at: database.FieldValue.serverTimestamp()
        }
        this.distributeRegisterCoupon(result.id)
        const CouponStore = useCouponStore()
        CouponStore.queryCoupon()

        this.genInviteCode({ user_name, user_id: result.id })
        gaEvent('sign_up', {
          method: method || 'email'
        })
        pixelEvent('CompleteRegistration', {
          user: {
            user_email: user_email,
            user_phone: `0${user_phone}`
          }
        })
        setCookie('cf:login', new moment().unix())
        return { state: 200, ...registerInfo, user_id: result.id }
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage)
        return { state: 400, code: errorCode }
      }
    },
    async genInviteCode(userInfo) {
      //生成推薦碼
      const WGResult = await fetch(`https://char.iis.sinica.edu.tw/API/pinyin_SQL.aspx?str=${userInfo.user_name}&choose=5`)
      console.log(WGResult);
      let inviteCode;
      if (WGResult.status === 200) {
        const wgCode = await WGResult.text()
        const wgName = wgCode.replace(/(<!--[\w\W]+-->)/g, '').replace(/^\W{2}|\W$|`/g, '').replace(/\s{2}/g, '_')
          .trim()  
          .toUpperCase()
        inviteCode = `${wgName}${'000000000'.slice(0, 9-userInfo.user_id.length)}${userInfo.user_id}`
        console.log(inviteCode);     
      } else {//若第三方 API 失效則使用 md5 進行推薦碼生成
        const inviteCodeRes = await fetchApi('/api/genInviteCode/', { id: userInfo.user_id })
        console.log(inviteCodeRes);
        inviteCode = inviteCodeRes.code
      }
      await database.table('user').where('user_id', '=', userInfo.user_id).update({ user_invite_code: inviteCode })
      return { state: 200 }
    },
    async bindUser(code) {
      const userFromCode = await database.table('user').where('user_invite_code', '=', code).get()
      if (userFromCode.exists) {
        if (userFromCode.data[0].user_id === this.currentUser.user_id) {
          return { state: 500 }
        }
        const inviteCoupon = await database.table('coupon').where('coupon_super', '=', 'INVITE').get()
        const updateRes = await database.table('user').where('user_uid', '=', this.currentUser?.user_uid).update({
          user_binding: userFromCode.data[0].user_id,
          user_own_coupons: JSON.stringify([...this.currentUser.user_own_coupons, { 
            coupon_id: inviteCoupon.data[0].coupon_id, 
            coupon_get_at: database.FieldValue.serverTimestamp()
          }])
        })
        console.log(updateRes); 
        this.currentUser.user_binding =  userFromCode.data[0].user_id
        return { state: 200 }
      }
      return { state: 404 }
    },
    async bindUserAtResgister(code, user_id) {
      console.log(code, user_id);
      const userFromCode = await database.table('user').where('user_invite_code', '=', code).get()
      console.log(userFromCode);
      if (userFromCode.exists) {
        const inviteCoupon = await database.table('coupon').where('coupon_super', '=', 'INVITE').get()
        const old_user_coupon = this.currentUser?.user_own_coupons || []
        const updateRes = await database.table('user').where('user_id', '=', user_id).update({
          user_binding: userFromCode.data[0].user_id,
          user_own_coupons: JSON.stringify([...old_user_coupon, { 
            coupon_id: inviteCoupon.data[0].coupon_id, 
            coupon_get_at: database.FieldValue.serverTimestamp()
          }])
        })
        console.log(updateRes); 
        return { state: 200 }
      }
      return { state: 404 }
    },
    async changePassword(email) {
      try {
        const userEmail = this.currentUser?.user_email || email
        const result = await sendPasswordResetEmail(auth, userEmail)
        return { state: true }
      } catch (error) {
        console.log(error.code);
        return {
          state: false,
          error
        }
      }
      
    },
    async logout() {
      const PaymentStore = usePaymentStore()
      try {
        if (this.currentUser.user_login_type === 'LINE') await fetchApi('/api/line/logout/');
        else await signOut(auth)
        this.currentUser = null
        PaymentStore.cleanCart()
        removeCookieByKey('cf:login')
        return { status: 200 }
      } catch (error) {
        console.log(error.code);
      }
    }
  }
})
