<script setup>
import { computed, inject, onBeforeMount, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { timestampParse } from '@/utils'
import { useEventStore } from "@/store/event";
import { useProductStore } from "@/store/product";
import { useLayoutStore } from "../../store/layout";
import { useCommentStore } from "../../store/comment";
import Icon from "@/components/Icon"

const ScreenState = inject('ScreenProvider')
const EventStore = useEventStore()
const ProductStore = useProductStore()
const LayoutStore = useLayoutStore()
const CommentStore = useCommentStore()
const Route = useRoute()
const currentEventUrl = ref(null)
onBeforeMount(() => {
  if (!EventStore.eventList.length) EventStore.queryEventList()
  if (!ProductStore.productList.length) ProductStore.queryProduct()
  if (!CommentStore.commentList.length) CommentStore.queryComment()
})
onMounted(() => {
  const { url } = Route.params
  currentEventUrl.value = url
})
watch(Route.params.url, (url) => {
  currentEventUrl.value = url
})
const currentEvent = computed(() => {
  if (EventStore.eventList.length && currentEventUrl.value) return EventStore.eventUrlDict[currentEventUrl.value] || null
  return
})
</script>

<template>
  <div class="view-event-detail-container">
    <header>
      <div v-if="currentEvent">
        <ul>
          <li><router-link to="/event">優惠活動</router-link></li>
          <li>/</li>
          <li><router-link :to="`/event?filter=${currentEvent.event_state}`">{{currentEvent.event_state === 'past' ? '已結束' : '進行中'}}的活動</router-link></li>
          <li>/</li>
          <li>{{ currentEvent.event_name }}</li>
        </ul>
        <p>
          {{ timestampParse(currentEvent.event_date_start).format('YYYY.MM.DD') }} ~ {{ timestampParse(currentEvent.event_date_end).format('MM.DD') }}
        </p>
        <h1>
          {{ currentEvent.event_state === 'past' && '[已結束]' || ''}}{{ currentEvent.event_state === 'unactive' && '[即將開始]' || ''}}
          {{ currentEvent.event_name }}</h1>
        <span>{{ currentEvent.event_summary }}</span>
      </div>
    </header>

    <main v-if="currentEvent">
      <section class="event-type-content" v-if="currentEvent.event_type === 'CONTENT'">
        <div class="banner">
          <img :src="`/img${currentEvent.event_banner}`" :alt="currentEvent.event_name">
        </div>
        <div class="content" v-html="currentEvent.event_content"></div>
      </section>

      <section
        class="event-type-product"
        v-if="currentEvent.event_type === 'PRODUCT' && ProductStore.productList.length"
      >
        <div class="product-wrapper">
          <div class="banner">
            <img :src="`/img${currentEvent.event_banner}`" :alt="currentEvent.event_name">
          </div>

          <div
            class="product"
            v-for="id in currentEvent.event_linked_product_id[0]==='ALL' ? ProductStore.productList.filter(p => p.product_is_launched==='1').map(p => p.product_id) : currentEvent.event_linked_product_id"
            :key="`event-${currentEvent.event_id}-product-${id}`"
          >
            <router-link
              class="product-image-wrapper"
              :to="`/product/${ProductStore.productDict[id].product_name_en.replace(/\s/g, '-').toLowerCase()}`"
              target="_blank"
            >
              <div class="mask" v-if="ScreenState.mode === 'COMPUTER'">
                查看詳情
              </div>
              <div class="skin-tag" v-if="ProductStore.productDict[id].product_function">
                {{ ProductStore.productDict[id].product_function }}
              </div>
              <img
                :src="ProductStore.productDict[id].product_type === 'PRODUCT'
                  ?`/img/product/${ProductStore.productDict[id].product_uid}/${LayoutStore.layoutDict['PRODUCT_AVATAR_NAME']?.layout_content || 'avatar'}.webp`
                  : `/img/product/${ProductStore.productDict[id].product_images[0]}`
                "
                :alt="`${ProductStore.productDict[id].product_name_en} ${ProductStore.productDict[id].product_name_zh}`"
              />
            </router-link>
            <div class="product-info">
              <span class="tag" v-if="currentEvent.event_link_product_tag">
                {{ currentEvent.event_link_product_tag }}
              </span>
              <h2>{{ ProductStore.productDict[id].product_name_zh }}<br v-if="ScreenState.mode === 'MOBILE'" />
                {{ ProductStore.productDict[id].product_size[0] }}{{ ProductStore.productDict[id].product_type === 'PRODUCT' ? 'ml' : '' }}
              </h2>
              <span class="product-price">
                <p :class="{ 'orginal-price': true}">NT${{ ProductStore.productDict[id].product_price.ORIGIN }}</p>
                <p>NT${{ ProductStore.productDict[id].product_price.MEMBER }}</p>
              </span>
              <div class="score-info" v-if="CommentStore.filterByProduct[id]">
                <div class="score">
                  <div class="background">
                    <icon icon="star-fill" />
                    <icon icon="star-fill" />
                    <icon icon="star-fill" />
                    <icon icon="star-fill" />
                    <icon icon="star-fill" />
                  </div>
                  <div class="fill" :style="{ width: `${CommentStore.filterByProduct[id].reduce((acc, c) => acc + parseInt(c.comment_stars), 0)/CommentStore.filterByProduct[id].length/5*100}%` }">
                    <icon icon="star-fill" />
                    <icon icon="star-fill" />
                    <icon icon="star-fill" />
                    <icon icon="star-fill" />
                    <icon icon="star-fill" />
                  </div>
                </div>

                <p>{{ Math.round(CommentStore.filterByProduct[id].reduce((acc, c) => acc + parseInt(c.comment_stars), 0)/CommentStore.filterByProduct[id].length*100)/100 }} ({{ CommentStore.filterByProduct[id].length }})</p>
              </div>
              <router-link
                class="product-link"
                :to="`/product/${ProductStore.productDict[id].product_name_en.replace(/\s/g, '-').toLowerCase()}`"
                target="_blank"
              >
                <button>查看詳情</button>
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
