<script setup>
import { ref, computed, useSlots } from "@vue/runtime-core"

const Props = defineProps({
  modelValue: String,
  placeholder: {
    default: '',
    type: String
  },
  label: String,
  addonBefore: String,
  addonAfter: String,
  maxlength: [String, Number],
  extra: String,
  required: Boolean,
  customClass: String,
  readonly: Boolean,
  hideLabel: Boolean,
  cols: [String, Number],
  rows: [String, Number]
})
const Slots = useSlots()
const Emits = defineEmits(['update:modelValue', 'focus', 'blur'])
const handleChange = (e) => {
  Emits('update:modelValue', e.target.value)
}

const hasFirstFocus = ref(false)
const isFocusing = ref(false)
function handleFocus() {
  Emits('focus')
  isFocusing.value = true
}
function handleBlur() {
  if (!hasFirstFocus.value) hasFirstFocus.value = true
  Emits('blur')
  isFocusing.value = false
}
const classes = computed(() => ({
  'cf-input': true,
  'cf-textarea': true,
  'focusing': isFocusing.value,
  'invalid': !!(Props.required && !Props.modelValue && hasFirstFocus.value),
  [Props.customClass]: !!Props.customClass
}))
</script>

<template>
  <label :class="classes">
    <slot name="addonBefore">
      <span v-if="Props.addonBefore">{{ Props.addonBefore }}</span>
    </slot>
    <div
      :class="{ 'placeholder': true, 'labelize': Props.modelValue || Props.addonBefore || Slots.addonBefore }"
      v-if="!hideLabel"
    >
      <p>{{ Props.label || Props.placeholder }}</p>
    </div>
    <textarea
      :value="Props.modelValue"
      @input="handleChange"
      @focus="handleFocus"
      @blur="handleBlur"
      :cols="Props.cols || null"
      :rows="Props.rows || null"
      :readonly="Props.readonly"
      :maxlength="Props.maxlength || null"
      :placeholder="!hideLabel ? null : Props.placeholder || '輸入' "
    />
    <slot name="addonAfter">
      <span v-if="Props.addonAfter">{{ Props.addonAfter }}</span>
    </slot>
    <slot></slot>
  </label>
  <span class="cf-input-extra" v-if="Props.extra || Slots.extra">
    <slot name="extra">{{ Props.extra }}</slot>
  </span>
</template>
