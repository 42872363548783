import { defineStore } from "pinia";
import { timestampParse } from '@/utils'
import database from '@/utils/database'

export const useIngredientStore = defineStore({
  id: 'Ingredient',
  state: () => ({
    ingredientList: [],
  }),
  getters: {
    ingredientDict() {
      return this.ingredientList.reduce((acc, i) => {
        acc[i.ingredient_id] = { ...i }
        return acc
      }, {})
    }
  },
  actions: {
    async queryIngredient() {
      const res = await database.table('ingredient').get()
      this.ingredientList = res.data.map(e => ({
        ...e,
      }))
    }
  }
})
